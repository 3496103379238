import React, {
  useState,
  useEffect,
} from 'react';
import {
  Col,
  Row,
  Button,
  Modal,
  InputGroup,
  FormControl,
  Image,
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import {
  _URL_INSTRUMENT,
  PostHttp,
  DeleteHttp,
  PutHttp,
  _URL_CATEGORY,
  GetHttpParams,
  SendFile,
  _URL_IMAGE_REPOSITORY,
  _URL_INSTRUMENT_LIST,
  _URL_INSTRUMENT_INHAND,  
} from '../assets/js/Connectorv2';
import {
  ExportComponent
} from "../component/components.js"
import * as image from "../assets/images/default.png";
import * as templates from "../assets/js/UITemplates";
import {ErrorDialog} from '../component/dialog';

import { InstrumentEditor ,ButtonPanelInstrumentAdd} from './catalog/instrumentEditor';
import { CatalogItem,ButtonPanelInstrument,ButtonPanelCategory } from './catalog/mainCatalogComponent';
import { PageLoadMain } from '../component/loader.js';

export default function Instrument() {

  return <>
      <CategoryBlock />
      </>
    
}

export function CategoryBlock() {

  let [response, setResponse] = useState({
    categories: {
      content: []
    }
  });
  const [path, setPath] = useState([-1]);
  let [page, setPage] = useState(0);
  let [size, setSize] = useState(10);
  const [showLoader, setShowLoader] = useState(true);
  const [editInstrument, setEditInstrument] = useState(0);
  const [editCategory, setEditCategory] = useState();
  const [showCategoryCreator, setShowCategoryCreator] = useState(false);
  let [content, setContent] = useState(0);
  const [showMessage,setShowMessage] = useState("");

  useEffect(() => {
    GetNextCategory(page, -1).then(e => {
      setResponse(e);
      setShowLoader(false);
      
    })

  }, [page, path])

  

  let handlePageableMore = () => {
    if (response.categories.totalPages > 1) {
      size+=10;
      GetHttpParams(_URL_CATEGORY+"/list",{size:size,page:page,parent_category:path[path.length - 1]})
      .then((e)=> {
        
          setSize(size);
          setResponse(e);
      })
    }
  }
  // Выполняется после добавления и удаления категории
  function PostCategoryAction() {

    GetNextCategory(0, path[path.length - 1]).then(e => {
      setResponse(e);
      setSize(10)
    })
    setShowCategoryCreator(false);
    setPage(0);

  }

  

  let handleEditInstrument = (id) => {
    setEditInstrument(id)
    setContent(2);
  }

  function backCategoryClick() {
    path.pop();
    setPath(path);
    setPage(0);
    setSize(10)
    GetNextCategory(0, path[path.length - 1]).then(e => {
      setResponse(e)
      
    })
  }

  function handleNextCategory (item){
    if (item.onlyInstrument) {
      path.push(item.id);
      setPath(path);
      setContent(1);

    } else {
      setPage(0)
      GetNextCategory(0, item.id).then((r => setResponse(r)));
      path.push(item.id);
      setPath(path);

    }
  }
  function handleDelete(item){
    DeleteHttp(_URL_CATEGORY, {category: item.id})
    .then(() =>PostCategoryAction()).catch(error=> setShowMessage(error.data.reason));
  }
  const category =<div className="d-flex mt-1">
          <div className="w-100">
            {showLoader
              ? <div className='justify-content-md-center mt-5'>
                <PageLoadMain />
              </div>
              : ''
            }
            
            {response.categories.content.map(k=>{
              return(<CatalogItem item={k} onNextCategory={()=>handleNextCategory(k)} setEditCategory={()=>setEditCategory(k)} handleDelete={()=>handleDelete(k)}/>)
            })}
            
            {showLoader ? '' :
              <div className='text-center p-4'>
                {(page + 1) !== response.categories.totalPages & response.categories.totalPages !== 0 ? <Button variant='outline-primary' onClick={handlePageableMore}>Показать еще </Button> : ''}
              </div>}

          </div>
          <ButtonPanelCategory
            path={path}
            response={response}
            showCategoryCreator={setShowCategoryCreator}
            setContent={setContent}
            onBackCategoryClick={() => backCategoryClick()}
            onInventory={() => setContent(4)}
          />
        </div>


  let render;
  switch (content) {
    case 0:
      render = <>
        <div className="dynamic-content dynamic-content-md">{category}</div>
        
      </>

      break;
    case 1:
      render = <InstrumentBlock
        path={path}
        setPath={setPath}
        setResponse={setResponse}
        setContent={setContent}
        handleEditInstrument={handleEditInstrument}
        showMessage={(m)=>setShowMessage(m)}
      />
      break;
    case 2:
      render = <InstrumentEditor
        id={editInstrument}
        onClose={() => setContent(1)}
        showMessage={(m)=>setShowMessage(m)}
      />;
      break;
    case 3:
      render = <InstrumentAdd parentCategory={path[path.length - 1]} onHide={()=>setContent(1)} showMessage={(e)=>setShowMessage(e)}/>;
      break;
    case 4:
      render = <>
      <div className='dynamic-content dynamic-content-md'><ExportComponent onExit={() => setContent(0)} type="inventory" title='Инвентаризация' /></div>
      </>
      break;
    default:
      break;
  }
  return (<>
    {editCategory !== undefined ?
      <ShowCategoryEditor category={editCategory} onHide={() => {
        setEditCategory(undefined);
        GetNextCategory(page, path[path.length]).then(e => {
          setResponse(e);
          setSize(10)
        })
      }} /> : ''}
    <ShowCategoryCreator 
      parent={path}
      show={showCategoryCreator}
      setShow={setShowCategoryCreator} 
      postCategoryCreate={PostCategoryAction} />
      {render}
      <ErrorDialog title="Ошибка" show={showMessage} onHide={()=>setShowMessage('')} onCancel={()=>setShowMessage('')}>
      <div>{showMessage}</div>
    </ErrorDialog>  
  </>)
}



async function GetNextCategory(page, parentCategory) {
  let result;
  await GetHttpParams(_URL_CATEGORY + "/list", {
    parent_category: parentCategory,
    page: page
  })
    .then((cat) => {
      result = cat;
      
    })
  return result;
}

async function GetInstrument(page, parentCategory) {
  let result;
  await GetHttpParams(_URL_INSTRUMENT_LIST, {
    parent_category: parentCategory,
    page: page
  })
    .then((cat) => {
      result = cat;

    })
  return result;
}

function InstrumentBlock(props) {
  let [response, setResponse] = useState({
    content: []
  });
  let [page, setPage] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const setPath = props.setPath;
  const path = props.path;
  
  useEffect(() => {
    GetInstrument(0, path[path.length - 1]).then(e => {
      setResponse(e)
      setShowLoader(false)
    })
  }, [path]);
  
  const handleDelete =(id)=> {
    DeleteHttp(_URL_INSTRUMENT, {
      instrument: id
    }).then(() =>
        GetInstrument(0, path[path.length - 1]).then(e => {
          setResponse(e)
        })
      ).catch(error=>{
        props.showMessage("test")
      })
  }
  function onCopyInstrument(id) {
    GetHttpParams(_URL_INSTRUMENT +`/${id}/copy`)
      .then(() => {
        setResponse({ content: [] })
        setShowLoader(true);
        GetInstrument(0, path[path.length - 1]).then(e => {
          setResponse(e)
          setShowLoader(false)
        })
      })
  }
  let blocks = response.content.map((v, k) => {
    let img;
    for (let i = 0; i < v.images.length; i++) {
      if (v.images[i].defaultFile) {
        img = v.images[i];
        break;
      }
    }
    const counters = v.instrumentCounters;

    let name;
    if (v.name.length > 29) {
      name = v.name.slice(0, 26) + "...";
    } else {
      name = v.name;
    }
    const tooltip = <Tooltip>
      Спишите весь инструмент, что бы удалить.
    </Tooltip>
    return (<>
     
      <div className="instrument-block p-2 mb-2 border shadow rounded" style={{ backgroundColor: templates.block_background_color }} >
        <div className='d-flex'>
          <div className="my-auto mx-2">
            <Image rounded src={img !== undefined ?
              _URL_IMAGE_REPOSITORY + "?uuid=" + img.uuid + 
              "&type=instrument"
              : image.default} width='140' height='96'></Image>
          </div>
          <div className="my-auto mx-2">
            <div><h3 onClick={() => props.handleEditInstrument(v.id)} style={{ cursor: 'pointer' }} >{`${name} ${v.model} ${v.vendor}`}</h3></div>
            <div>Доступно: {counters !== null ? counters.avalable : 'нет на складе'}</div>
            <div>Всего: {v.totalСount !== 0 ? v.totalСount : 'нет на складе'}</div>
          </div>
          <div className=" ms-auto text-end border p-1">
            {v.totalСount == 0
              ? <Button size="sm" className="ms-auto" variant="outline-danger" onClick={() => handleDelete(v.id)}><i className="bi bi-x-square me-2"></i>Удалить</Button>
              : <>
                <OverlayTrigger placement="top"
                  overlay={tooltip}>
                  <Button size="sm" className="ms-auto " variant="outline-secondary" >
                    <i className="bi bi-x-square me-2"></i>Удалить</Button>
                </OverlayTrigger></>
            }
            <div className="mt-1">
              <Button size="sm" variant="outline-success" onClick={() => onCopyInstrument(v.id)}>Копировать</Button>
            </div>
          </div>
        </div>
      </div>
    </>)
  })

  let handlePageableMore = () => {
    page++;
    if (response.totalPages > 1) {
      GetInstrument(page, path[path.length - 1]).then(e => {
        let newCont = response.content.concat(e.content);
        e.content = newCont;
        setPage(page);
        setResponse(e);

      })
    }
  }

  let backCategoryClick = () => {
    path.pop();
    setPath(path);
    props.setContent(0);
    GetNextCategory(0, path[path.length - 1])
      .then(e => props.setResponse(e))
  }
  const content = <div className="mt-2 ms-1 d-flex w-100">
        <div className="me-2 flex-fill">
          {blocks}
          {showLoader ? '' :
            <div className='text-center'>
              {(page + 1) !== response.totalPages && response.totalPages !== 0 ? <Button variant='outline-primary' onClick={handlePageableMore}>Показать еще</Button> : ''}
            </div>}
        </div>
        <div className=" me-2">
          <ButtonPanelInstrument onShowAddInstrument={() => props.setContent(3)} path={path} setPath={props.setPath} backCategoryClick={backCategoryClick} />
        </div>
      </div>

  if(showLoader){
    return <PageLoadMain/>
  }else {
    return <div className="dynamic-content dynamic-content-md">{content}</div>
  }

}

function ShowCategoryEditor(props) {
  const [category, setCategory] = useState();

  useEffect(() => {
    GetHttpParams(_URL_CATEGORY +"/"+ props.category.id, {}).then((cat) => {
      setCategory(cat);
    })
  }, [])

  let handleChange = (event) => {
    if (event.target.id === "name") {
      category.name = event.target.value;

    }
  }

  let handleSave = () => {
    PostHttp({
      name: category.name,
      id: category.id
    }, _URL_CATEGORY).then(() => props.onHide());
  }
  let handleSendImage = (event) => {
    let file = event.target.files[0];
    SendFile(file, "category", category.id).then(() => {
      GetHttpParams(_URL_CATEGORY  +"/"+ props.category.id, {}).then((cat) => {
        setCategory(cat);
        document.getElementById("preview").src = _URL_IMAGE_REPOSITORY + "?uuid=" + cat.images[0].uuid + 
          "&type=category"
      })
    })
  }

  const content = <>
    {category !== undefined ?
      <div className="d-flex">

        <div className="m-2">
          <Image width="200px" id="preview" src={
            category.images.length > 0 ? _URL_IMAGE_REPOSITORY + "?uuid=" + category.images[0].uuid + 
              "&type=category"
              : image.default
          }></Image>
        </div>
        <Col>
          <InputGroup className="mb-3">
            <InputGroup.Text >Название</InputGroup.Text>
            <FormControl
              id="name"
              onInput={handleChange}
              defaultValue={category.name}
            />
          </InputGroup>
          <Form>
            <Form.Control type="file"
              id="custom-file"
              label="Загрузить фотографию"
              custom
              onChange={handleSendImage}
              accept=".jpg, .jpeg, .png"
            />
          </Form>
        </Col>
      </div>
      : ''}
  </>

  return (
    <Modal show={props.category !== null} onHide={() => { props.onHide() }} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Редактирование категории инструмента</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide()}>
          {templates.button_cancel}
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {templates.button_save}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ShowCategoryCreator(props) {
  const parent_category = props.parent[props.parent.length - 1];
  const setShow = props.setShow;
  const show = props.show;
  let category = {
    category: parent_category,
    name: ''
  };

  let handleChange = (event) => {
    if (event.target.id === "name") {
      category.name = event.target.value;

    }
  }
  let handleSave = () => {
    PutHttp({
      name: category.name,
      parentInstrumentCategory: {id:category.category}
    }, _URL_CATEGORY).then(() => props.postCategoryCreate());
  }

  return (
    <Modal show={show} onHide={() => { setShow(false) }} >
      <Modal.Header closeButton>
        <Modal.Title>Добавление категории</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <InputGroup className="mb-3">

              <InputGroup.Text >Название</InputGroup.Text>

              <FormControl
                id="name"
                onInput={handleChange}
                defaultValue={category.name}
              />
            </InputGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          {templates.button_cancel}
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {templates.button_save}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export function InstrumentAdd({onHide,parentCategory,showMessage}) {

  let [instrument, setInstrument] = useState({
    name: '',
    model: '',
    images: [],
    vendor: '',
    type: '',
    serial: '',
    invent: '',
    status: '',
    cost: '0',
    warrantyString: null,
    purchaseString: null,
    instrumentCategory: {id:-1}
  });
  let [type, setType] = useState();

  function addInstrument(instrument) {
    delete instrument.images;
    instrument.instrumentCategory.id = parentCategory;
    PostHttp(instrument, _URL_INSTRUMENT).then(() => onHide())
    .catch(error=> showMessage(error.data.reason));
  }

  useEffect(() => {
    GetHttpParams(_URL_INSTRUMENT + '/types').then(e => {
      setType(e);
    })
  }, [])

  let onSave = () => {
    if (instrument.name.length > 0) {
      addInstrument(instrument);
    } else {
      alert("Наименование не может быть пустым")
    }
  }

  let handleChange = (event) => {
    switch (event.target.id) {
      case 'name':
        instrument.name = event.target.value;
        break;
      case 'model':
        instrument.model = event.target.value;
        break;
      case 'type':
        instrument.type = event.target.value;
        break;
      case 'vendor':
        instrument.vendor = event.target.value;
        break;
      case 'serial':
        instrument.serial = event.target.value;
        break;
      case 'invent':
        instrument.invent = event.target.value;
        break;
      case 'status':
        instrument.status = event.target.value;
        break;
      case 'cost':
        instrument.cost = event.target.value;
        break;
      case 'warranty':
        instrument.warrantyString = event.target.value;
        break;
      case 'purchase':
        instrument.purchaseString = event.target.value;
        break;
      default:
        break;

    }
    setInstrument(instrument);
  }
  let content;

  content = <>
    <div className="d-flex mt-3">
      <div className='border styled-block p-3'>
        <Row>
          <Col>
            <Row>
              <Form.Group as={Col} >
                <Form.Label>Наименование</Form.Label>
                <Form.Control id="name" type="text" onInput={handleChange}

                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} >
                <Form.Label>Производитель</Form.Label>
                <Form.Control id="vendor" type="text" onInput={handleChange}


                />
              </Form.Group>
              <Form.Group as={Col} >
                <Form.Label>Модель</Form.Label>
                <Form.Control id="model" type="text" onInput={handleChange}


                />
              </Form.Group>
              <Form.Group as={Col} >
                <Form.Label>Тип</Form.Label>
                <Form.Control id="type" type="text" onInput={handleChange}

                  list='combo-options'
                />
                <datalist id="combo-options">
                  {type !== undefined ? type.map(v => {
                    return (<option>{v}</option>)
                  }) : ''}
                </datalist>
              </Form.Group>

            </Row>

            <Row>
              <Form.Group as={Col} >
                <Form.Label>Серийный №</Form.Label>
                <Form.Control id="serial" type="text" onInput={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} >
                <Form.Label>Инвентарный</Form.Label>
                <Form.Control id="invent" type="text" onInput={handleChange}
                />
              </Form.Group>
            </Row>

          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Form.Group as={Col} >
                <Form.Label>Гарантия до</Form.Label>
                <Form.Control id="warranty" type="date" onInput={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} >
                <Form.Label>Дата приобретения</Form.Label>
                <Form.Control id="purchase" type="date" onInput={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} >
                <Form.Label>Состояние</Form.Label>
                <Form.Control id="status" type="text" onInput={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} >
                <Form.Label>Стоимость</Form.Label>
                <Form.Control
                  id="cost"
                  type="number"
                  step={100}
                  onInput={handleChange}

                />
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="ms-2">
        <ButtonPanelInstrumentAdd setContent={()=>onHide()} onSave={onSave} />
      </div>
    </div>

  </>


  return (
    // <StasticView/>
    <>
    <div className="dynamic-content dynamic-content-md ">{content}</div>
      
    </>
  )
}



