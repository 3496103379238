import {  Placeholder,Row,Container } from "react-bootstrap";
import React from 'react';

export function PageLoad(){

    return(<>
    
        <Placeholder xs={6} />
        <Placeholder className="w-75" /> <Placeholder style={{ width: '25%' }} />
    </>)
}

export function PageLoadMain(){

    return(<>
          <Container fluid className="p-3">
            <Row className="align-items-center vh-100">
                <div className="text-center">
                    <div className="loader"></div>                
                    <div><h5>Загружаем интерфейс</h5></div>
                </div>
            </Row>                  
        </Container>
    </>)
}