import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { InputGroup,
    Nav,
    Modal,
    Button,
    FormControl,
    Form,
     } from 'react-bootstrap';
import { contex,
    GetHttpParams,
    PostHttp,
    _URL_ACCOUNT, 
    _URL_INSTRUMENT_CONTAINER,
    _URL_PERMISSION} from '../assets/js/Connectorv2';

    export function MainMenu(props) {

    const [activeMenu,setActiveMenu] =useState('/main')
  
    function onItemPressed(event,path){
      props.handleActiveMenu(event);
      setActiveMenu(path);
      
    }
    
  
    function addMenuItem(path, name, icon_tag, handleAction) {
      return (<Nav.Item className='w-100'>
        <Link to={path} className={activeMenu === path ? 'border-start border-4 border-warning menu-item d-lg-flex d-none text-nowrap': 'menu-item d-lg-flex d-none text-nowrap'}  onClick={handleAction}>
          {icon_tag}
          <span className="ms-2">{name}</span></Link>
        <Link to={path} className='menu-item d-lg-none'>
          {icon_tag}
          <div></div></Link>
      </Nav.Item>)
    }
  
    return(<>
              <div className='main-menu-sm d-lg-none d-sm-flex position-fixed flex-column'>
                  <Nav className="h-75 ">
                      {addMenuItem(contex + "/main", 'Выдача инструмента', <i className="bi bi-receipt mr-1"></i>,(event)=> onItemPressed(event,"/main"))}
                      {addMenuItem(contex + "/instrument", 'Инструмент', <i className="bi bi-wrench mr-1"></i>, (event)=> onItemPressed(event,"/instrument"))}
                      {addMenuItem(contex + "/recipient", 'Получатели', <i className="bi bi-person mr-1 "></i>, (event)=> onItemPressed(event,"/recipient"))}
                      {addMenuItem(contex + "/arrival", 'Поступление', <i className="bi bi-box-arrow-in-left mr-1"></i>, (event)=> onItemPressed(event,"/arrival"))}
                      {addMenuItem(contex + "/transfer", 'Перемещениe', <i className="bi bi-arrow-left-right mr-1"></i>, (event)=> onItemPressed(event,"/transfer"))}
                      {addMenuItem(contex + "/writeoff", 'Списание', <i className="bi bi-box-arrow-left mr-1"></i>, (event)=> onItemPressed(event,"/writeoff"))}
                      {addMenuItem(contex + "/report", 'Отчеты', <i className="bi bi-journal-text mr-1"></i>, (event)=> onItemPressed(event,"/report"))}
                      {/* {addMenuItem(contex + "/signin", 'Выход', <i className="bi bi-power mr-1"></i>, (event)=> onItemPressed(event,"/signin"))} */}
                  </Nav>
                  
              </div>
              <div className='main-menu d-lg-flex d-none position-fixed flex-column ' >
                  <Nav className=" ">
                      {addMenuItem(contex + "/main", 'Выдача инструмента', <i className="bi bi-receipt mr-1"></i>,(event)=> onItemPressed(event,"/main"))}
                      {addMenuItem(contex + "/instrument", 'Инструмент', <i className="bi bi-wrench mr-1"></i>, (event)=> onItemPressed(event,"/instrument"))}
                      {addMenuItem(contex + "/recipient", 'Получатели', <i className="bi bi-person mr-1 "></i>, (event)=> onItemPressed(event,"/recipient"))}
                      {addMenuItem(contex + "/arrival", 'Поступление', <i className="bi bi-box-arrow-in-left mr-1"></i>, (event)=> onItemPressed(event,"/arrival"))}
                      {addMenuItem(contex + "/transfer", 'Перемещениe', <i className="bi bi-arrow-left-right mr-1"></i>,  (event)=> onItemPressed(event,"/transfer"))}
                      {addMenuItem(contex + "/writeoff", 'Списание', <i className="bi bi-box-arrow-left mr-1"></i>, (event)=> onItemPressed(event,"/writeoff"))}
                      {addMenuItem(contex + "/report", 'Отчеты', <i className="bi bi-journal-text mr-1"></i>, (event)=> onItemPressed(event,"/report"))}
                      {/* {addMenuItem(contex + "/signin", 'Выход', <i className="bi bi-power mr-1"></i>, (event)=> onItemPressed(event,"/signin"))} */}
                      
                  </Nav>
                  <div className='text-white align-items-end mt-auto text-center p-1' >
                      <a href="/assets/Инструкция_по_быстрому_запуску_01.09.2023.pdf" className='text-light' target='_blank'>Скачать инструкцию</a>
                  </div>
                  <div className='text-white align-items-end mb-5 text-center p-1' >
                      <a href="https://skladsystem.ru/donat" className='text-light' target='_blank'>Донат</a>
                  </div>        
              </div>
              
          
    </>)
  }


export function SelectCustomer(props)  {
    const [customersList,setCustomersList] = useState([]);
    const [selected,setSelected] = useState("-1");
    const navigate = useNavigate();
  useEffect( ()=>{
    GetHttpParams(_URL_ACCOUNT + '/customers')
      .then((c) => {
        setCustomersList(c);
        
      })
  },[])
    
  

  const handlecommit= () =>{
    if(selected!=="-1"){
      PostHttp({
        token: selected
      }, _URL_ACCOUNT + "/customer")
      .then( ()=>navigate("/signin") )
    }
  }
      
      return (
        <Modal show={props.show} onHide={props.handleHide}>
                  <Modal.Header closeButton>
                      <Modal.Title>Выбор организации</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <InputGroup className="mb-3">
                              <InputGroup.Text id="customer">Организация</InputGroup.Text>
                          <FormControl
                              as="select"
                              placeholder="customer"
                              aria-label="customer"
                              aria-describedby="customer"
                              onChange={(e)=>setSelected(e.target.value)}
                              
                          >
                              {customersList.map( (e)=><option value={e.token}>{e.name}</option> )}
                          </FormControl>
                      </InputGroup>
                  </Modal.Body>
                  <Modal.Footer>
                      <Button variant="primary" onClick={handlecommit}>
                          Выбрать
                      </Button>
                  </Modal.Footer>
        </Modal>
      )
  }

  export function WirehouseSelection(props){
      const [instrumnetContainers,setInstrumnetContainers] = useState([]);
      const [container,setContainer] =useState("0");
      const [show,setShow] = useState(props.show);
      const [permission,setPermission] = useState({permissionName:'',permissionRole:[]});
      
      function fetch(){
        GetHttpParams(_URL_INSTRUMENT_CONTAINER+"/list")
        .then( (e)=>{
          setInstrumnetContainers(e);
          GetHttpParams(_URL_PERMISSION)
          .then((p)=>setPermission(p));
        })
      }
      useEffect( ()=>fetch(),[]);

      
      const handlecommit = ()=>{
        if(container!==0){
          GetHttpParams(_URL_ACCOUNT + '/container/set', {
            container: container
          })
          .then(()=>{
            document.location.reload();
          })
        }
      }
    return (
    <>
        <div onClick={()=>setShow(true)} style={{cursor:'pointer'}}>{props.account.instrumentContainer.name}</div>
        <Modal show={show} onHide={()=>setShow(false)}>
                  <Modal.Header closeButton>
                      <Modal.Title>Выбор склада</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {permission.permissionRole.find(f=>f === "ALLOW_SET_INSTRUMENT_CONTAINER") !== undefined ? 
                      <InputGroup className="mb-3">
                              <InputGroup.Text id="customer">Склад</InputGroup.Text>
                          <Form.Select
                              placeholder="Sыбирите склад"
                              onChange={(e)=>setContainer(e.target.value)}
                          >
                            <option selected value="0">Выберите склад</option>
                            {instrumnetContainers.map( (e,key)=><option key={key} value={e.id}>{e.name}</option> )}
                          </Form.Select>
                      </InputGroup> : <div>Только администратор может менять склад</div>}
                  </Modal.Body>
                  <Modal.Footer>
                      <Button variant="primary" onClick={handlecommit} disabled={container==="0"}>
                          Выбрать
                      </Button>
                  </Modal.Footer>
        </Modal>
    </>)
  }