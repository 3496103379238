import React, { useState, useEffect } from "react";

import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Nav, Image,Modal, Table
} from "react-bootstrap";
import {
  phoneFormatter,
  ConfirmDialog,
  ExportComponent,
  UploadFileForm,
  AccountAvatar
} from "../component/components.js";
import * as templates from "../assets/js/UITemplates";
import {
  GetHttpParams,
  PostHttp,
  PutHttp,
  _URL_RECIPIENT, _URL_IMAGE_REPOSITORY,
  _URL_ISSUE
} from "../assets/js/Connectorv2";
import { BasicTable } from "../component/table.js";

const id_max_len = 30;

class Recipient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddingRecipient: false,
      recipients: { content: [] },
      editRecipient: { id: null, readOnly: false }
    };
  }

  handleCloseAddingDialog = () => {
    this.setState({ showAddingRecipient: false });
  };
  handleCloseEdit() {
    this.fetch([0, ""]);
    this.setState({ editRecipient: { id: null, readOnly: false } });
  };
  handleEditRecipient = event => {
    if (event.target.nodeName === "I") {
      event.stopPropagation();
      const id = event.target.id;
      this.setState({ editRecipient: { id: id, readOnly: false } });
    }
  };

  handleViewRecipient = id => {
    this.setState({ editRecipient: { id: id, readOnly: true } });
  };

  handleShowAddingRecipient = () => {
    this.setState({ showAddingRecipient: true });
  };

  fetch(onPageParams) {
    GetHttpParams(_URL_RECIPIENT + "/list", { page: onPageParams[0], sort: onPageParams[1] }).then(r =>
      this.setState({ recipients: r })
    );
  }
  render() {
    let content;
    const tableFields = [{ name: "#", sort: "id" }, { '': '' }, { name: "ФИО", sort: "surname" },
    { name: "ID получателя", sort: "identificator_id" }, { name: "Телефон", sort: "phone" },
    { name: "Должность", sort: "function" }]

    if (this.state.showAddingRecipient) {
      content = (
        <RecipienAdding
          onClose={this.handleCloseAddingDialog}
          onUpdateTable={() => this.fetch([0, ''])}
        />
      );
    } else if (this.state.editRecipient.id !== null) {
      content = (
        <EditRecipient
          onClose={() => this.handleCloseEdit()}
          recipient={this.state.editRecipient}
          readOnly={this.state.editRecipient.readOnly}
        />
      );
    } else {
      content =
        <>
          <div >
            <div className="d-flex border p-2 mb-3 shadow">
              <div className="ms-auto">
                <Button size="sm" onClick={this.handleShowAddingRecipient}>
                  <i className="bi bi-plus-circle me-1"></i>
                  {templates.button_create}
                </Button>
              </div>
            </div>
            <div className="shadow p-1 border">
              <BasicTable fields={tableFields} page={this.state.recipients} onsort={(p) => this.fetch(p)}>
                {this.state.recipients.content.map((v, k) => {
                  let ident = "Нет";
                  if (v.identificator !== null) {
                    ident = v.identificator.code;
                    if (ident.length > id_max_len) {
                      ident = ident.substring(0, id_max_len) + "...";
                    }
                  }
                  const image = v.images.map((e) => {
                    if (e.defaultFile) { return e }
                  });
                  return (
                    <tr onClick={() => this.handleViewRecipient(v.id)} style={{ cursor: "pointer" }}>
                      <td> {k + 1} </td>
                      <td className="p-1 mx-1"><Image src={image[0] !== undefined ? _URL_IMAGE_REPOSITORY + "?uuid=" + image[0].uuid + "&type=recipient"
                        : ""} width={20} /></td>
                      <td> {v.firstname + " " + v.surname} </td>
                      <td> {ident} </td>
                      <td className="d-md-none d-lg-table-cell">{v.phone}</td>
                      <td className="d-md-none d-lg-table-cell">{v.function}</td>
                    </tr>
                  );
                })}
              </BasicTable>
            </div>
          </div>
        </>

    }
    return (<>
      <div className="content-pane dynamic-content dynamic-content-md">{content}</div>

    </>);
  }
}

export default Recipient;

class RecipienAdding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recipient: {
        surname: "",
        firstname: "",
        secondname: "",
        phone: "",
        function: "",
        place: "",
        orgname: "",
        leader: "",
        leaderphone: "",
        identificator: { code: "" }
      },
      errorMessage: ''
    };
  }

  handleChange = event => {
    const target = event.target;
    const id = target.id;

    if (id === "surname") {
      this.state.recipient.surname = target.value;
    } else if (id === "firstname") {
      this.state.recipient.firstname = target.value;
    } else if (id === "secondname") {
      this.state.recipient.secondname = target.value;
    } else if (id === "phone") {
      target.value = phoneFormatter(target.value);
      this.state.recipient.phone = target.value;
    } else if (id === "function") {
      this.state.recipient.function = target.value;
      this.setState(state => ({ recipient: state.recipient }));
    } else if (id === "place") {
      this.state.recipient.place = target.value;
    } else if (id === "orgname") {
      this.state.recipient.orgname = target.value;
    } else if (id === "leader") {
      this.state.recipient.leader = target.value;
    } else if (id === "leaderPhone") {
      target.value = phoneFormatter(target.value);
      this.state.recipient.leaderphone = target.value;
    } else if (id === "identificator") {
      this.state.recipient.identificator = { code: target.value };
    }
  };

  handleCommit = () => {
    if (this.validateFields) {
      PutHttp(this.state.recipient, _URL_RECIPIENT).then(() => {
        this.props.onUpdateTable();
        this.props.onClose();
      }).catch(error => {
        this.setState({ errorMessage: error.data.reason })
      });
    }
  };
  validateFields = () => {
    return true;
  };

  render() {
    return (
      <>
        <div className="w-100 mt-3 ms-2" >
          {this.state.errorMessage !== '' ? <Alert dismissible variant="danger" onClose={() => this.setState({ errorMessage: '' })}>
            {this.state.errorMessage}
          </Alert> : ''}
          <div className="border rounded p-2 ">
            <div className="mb-2">
              <Form.Group as={Col}>
                <Form.Label> ID Получателя </Form.Label>
                <Form.Control
                  id="identificator"
                  type="text"
                  placeholder=""
                  onInput={this.handleChange}
                />
              </Form.Group>
            </div>
            <div className="border my-1 d-flex p-2">
              <Form.Group as={Col} className="me-1">
                <Form.Label> Фамилия </Form.Label>{" "}
                <Form.Control
                  id="surname"
                  type="text"
                  onInput={this.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} className="me-1">
                <Form.Label> Имя </Form.Label>
                <Form.Control
                  id="firstname"
                  type="text"
                  onInput={this.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} className="me-1">
                <Form.Label> Отчество </Form.Label>
                <Form.Control
                  id="secondname"
                  type="text"
                  onInput={this.handleChange}
                />
              </Form.Group>
            </div>
            <div className="border my-1 d-flex p-2">
              <Form.Group as={Col} className="me-1">
                <Form.Label> Телефон </Form.Label>
                <Form.Control
                  id="phone"
                  type="text"
                  placeholder="+7 111 111 11 11"
                  onInput={this.handleChange}
                />
              </Form.Group>{" "}
              <Form.Group as={Col} className="me-1">
                <Form.Label> Должность </Form.Label>
                <Form.Control
                  id="function"
                  type="text"
                  onInput={this.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} className="me-1">
                <Form.Label> Место </Form.Label>
                <Form.Control
                  id="place"
                  type="text"
                  onInput={this.handleChange}
                />
              </Form.Group>
            </div>
            <div className="border my-1 d-flex p-2">
              <Form.Group as={Col} className="me-1">
                <Form.Label> Компания </Form.Label>
                <Form.Control
                  id="orgname"
                  type="text"
                  onInput={this.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} className="me-1">
                <Form.Label> Руководитель </Form.Label>
                <Form.Control
                  id="leader"
                  type="text"
                  onInput={this.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} className="me-1">
                <Form.Label> Телефон руководителя </Form.Label>{" "}
                <Form.Control
                  id="leaderPhone"
                  type="text"
                  placeholder="+7 111 111 11 11"
                  onInput={this.handleChange}
                />
              </Form.Group>
            </div>
          </div>
          <div className="text-center mt-2">
            <Button
              variant="danger"
              className="me-2"
              onClick={this.props.onClose}
            >
              {" "}
              {templates.button_cancel}
            </Button>
            <Button onClick={this.handleCommit}>
              {" "}
              {templates.button_create}{" "}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export function EditRecipient(props) {
  const [recipient, setRecipient] = useState({
    identificator: { code: "" },
    images: []
  });
  const [showDismiss, setShowDismiss] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [switchTab, setSwitchTab] = useState("1");
  const [readOnly, setReadOnly] = useState(true);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [images, setImages] = useState([]);

  function fetch() {
    GetHttpParams(_URL_RECIPIENT, { recipient: props.recipient.id }).then(r => {
      setRecipient(r);
    }).catch(error => {
      setErrorMessage(error.response.data.reason);
    });
  }
  function fetchImages(id) {
    GetHttpParams(_URL_RECIPIENT + "/resourses", { recipient: id })
      .then(res => {
        setImages(res);
      });
  }
  useEffect(() => {
    fetch();
    fetchImages(props.recipient.id);
  }, [props.recipient.id]);

  let handleSave = () => {
    let save = Object.assign({}, recipient);
    delete save.images;
    PostHttp(save, _URL_RECIPIENT + "/save").then(r => {
      setMessage("Сохранено");
    }).catch(error => setErrorMessage(error.data.reason));
  };

  let handleInput = event => {
    const target = event.target;
    const id = target.id;

    if (id === "surname") {
      recipient.surname = target.value;
    } else if (id === "firstname") {
      recipient.firstname = target.value;
    } else if (id === "secondname") {
      recipient.secondname = target.value;
    } else if (id === "phone") {
      target.value = phoneFormatter(target.value);
      recipient.phone = target.value;
    } else if (id === "function") {
      recipient.function = target.value;
    } else if (id === "place") {
      recipient.place = target.value;
    } else if (id === "orgname") {
      recipient.orgname = target.value;
    } else if (id === "leader") {
      recipient.leader = target.value;
    } else if (id === "leaderPhone") {
      target.value = phoneFormatter(target.value);
      recipient.leaderphone = target.value;
    } else if (id === "identificator") {
      recipient.identificator = { code: target.value };
    }
    setRecipient(recipient);
  };
  let handleDismiss = () => {
    GetHttpParams(_URL_RECIPIENT + "/dismiss", { recipient: recipient.id }).then(() => {
      setShowDismiss(false);
      props.onClose();
    }).catch(error => { setShowDismiss(false); setErrorMessage(error.response.data.reason) });
  };

  let identificator;
  if (recipient.identificator !== null) {
    identificator = recipient.identificator.code;
  } else {
    identificator = "";
  }
  let content = (
    <>
      <Row></Row>
    </>
  );
  if (switchTab === "1") {
    content = (
      <>
        <div className="ms-1">

          <div className="d-flex">
            <div className="p-1 border styled-block w-50 me-1">
              <div className="text-center p-1 border">
                <div className="p-1 m-1 text-center" >
                  <AccountAvatar images={images} className="border rounded-circle p-1 mb-3" />
                  <div className='text-center'>
                    <UploadFileForm type="recipient" id={recipient.id} onUploaded={() => fetchImages(props.recipient.id)} />
                  </div>
                </div>
              </div>
              <div>

                <Form.Group as={Col}>
                  <Form.Label> Должность </Form.Label>
                  <Form.Control
                    id="function"
                    type="text"
                    defaultValue={recipient.function}
                    onInput={handleInput}
                    readOnly={readOnly}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label> Место </Form.Label>
                  <Form.Control
                    id="place"
                    type="text"
                    defaultValue={recipient.place}
                    onInput={handleInput}
                    readOnly={readOnly}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="p-1 styled-block  border w-50 me-1" >
              <div>
                <Form.Group as={Col}>
                  <Form.Label> ID Получателя </Form.Label>{" "}
                  <Form.Control
                    id="identificator"
                    type="text"
                    defaultValue={identificator}
                    onChange={handleInput}
                    readOnly={readOnly}
                  />
                </Form.Group>
              </div>
              <div className=" mb-1">
                <Form.Group as={Col}>
                  <Form.Label> Фамилия </Form.Label>{" "}
                  <Form.Control
                    id="surname"
                    type="text"
                    defaultValue={recipient.surname}
                    onInput={handleInput}
                    readOnly={readOnly}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label> Имя </Form.Label>
                  <Form.Control
                    id="firstname"
                    type="text"
                    defaultValue={recipient.firstname}
                    onInput={handleInput}
                    readOnly={readOnly}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Отчество</Form.Label>
                  <Form.Control
                    id="secondname"
                    defaultValue={recipient.secondname}
                    type="text"
                    onInput={handleInput}
                    readOnly={readOnly}
                  />
                </Form.Group>
                <div className="d-flex">
                  <Form.Group className="me-2">
                    <Form.Label> Телефон </Form.Label>
                    <Form.Control
                      id="phone"
                      type="text"
                      defaultValue={recipient.phone}
                      onInput={handleInput}
                      readOnly={readOnly}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label> Компания </Form.Label>
                    <Form.Control
                      id="orgname"
                      type="text"
                      defaultValue={recipient.orgname}
                      onInput={handleInput}
                      readOnly={readOnly}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex">
                <Form.Group className="me-2">
                  <Form.Label> Руководитель </Form.Label>
                  <Form.Control
                    id="leader"
                    defaultValue={recipient.leader}
                    type="text"
                    onInput={handleInput}
                    readOnly={readOnly}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label> Телефон руководителя </Form.Label>
                  <Form.Control
                    id="leaderPhone"
                    defaultValue={recipient.leaderphone}
                    type="text"
                    onInput={handleInput}
                    readOnly={readOnly}
                  />
                </Form.Group>
              </div>
            </div>

            <div>
              <div
                className="position-sticky border border-success rounded p-2 me-2"
                style={{
                  backgroundColor: templates.block_background_color,
                  width: "230px"
                }}
              >
                <div className="d-block">
                  <div className="p-1">

                    {readOnly ? "" : (
                      <Button
                        variant="danger"
                        className="mb-1 w-100"
                        onClick={() => setShowDismiss(true)}
                      >
                        {templates.button_dismiss}
                      </Button>
                    )}
                  </div>
                  <div className="p-1">
                    <Button
                      variant="warning"
                      className="w-100"
                      onClick={readOnly ? () => setReadOnly(false) : handleSave}
                    >

                      <i className="bi bi-pen mr-1"> </i>
                      {readOnly
                        ? templates.button_enableEdit
                        : templates.button_save}
                    </Button>
                  </div>
                  <div className="p-1">
                    <Button
                      variant="success"
                      className="w-100"
                      onClick={props.onClose}
                    >

                      <i className="bi bi-arrow-left-square mr-1"> </i>
                      {templates.button_close}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (switchTab === "2") {
    content = <InhandView recipient={recipient} onClose={props.onClose} />;
  } else if (switchTab === "3") {
    content = <History recipient={recipient} onClose={props.onClose} />;
  }
  const msg =
    templates.info_message_confirm_dismiss +
    recipient.firstname +
    recipient.surname +
    "?";

  return (
    <>
      <ConfirmDialog
        onHide={() => setShowDismiss(false)}
        show={showDismiss}
        title="Увольнение получателя"
        message={errorMsg === "" ? msg : errorMsg}
        onCancel={() => {
          setShowDismiss(false);
          setErrorMsg("");
        }}
        onCommit={handleDismiss}
      />
      {errorMessage !== '' ? <Alert dismissible variant="danger" onClose={() => setErrorMessage('')}>
        {errorMessage}
      </Alert> : ''}

      <div className="mt-2 ms-1 w-100" >
        <Nav
          className="mb-1" variant="tabs" defaultActiveKey="1" onSelect={eventKey => setSwitchTab(eventKey)}>
          <Nav.Item>
            <Nav.Link eventKey={1}> Основные </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={2}> На руках </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={3}> История выдачи</Nav.Link>
          </Nav.Item>
        </Nav>
        {content}
      </div>
    </>
  );
}
function History(props) {
  const [instrumentTransfer, setInstrumentTransfer] = useState({ content: [], pageActive: 0 });

  function fetch(pageParams) {
    GetHttpParams(_URL_RECIPIENT + "/issuehistory", {
      recipient: props.recipient.id,
      page: pageParams[0], sort: pageParams[1]
    }).then(res => setInstrumentTransfer(res));
  }
  const table = instrumentTransfer.content.map((val, key) => {
    let action = "Выдано";
    if (val.instrumentTransferAction === "return_inst") {
      action = "Возврат";
    }
    return (
      <tr key={key}>
        <td> {key + 1} </td>
        <td> {val.instrument.name} </td>
        <td> {val.instrument.vendor} </td>
        <td> {val.instrument.model} </td>
        <td> {val.count} </td>
        <td> {action} </td>
        <td> {new Date(val.timestamp).toLocaleDateString()} </td>
      </tr>
    );
  });

  const tableFields = [{ name: "#", sort: "id" }, { name: "Наименование", sort: "instrument_name" }, { name: "Производитель", sort: "instrument_vendor" },
  { name: "Модель", sort: "instrument_model" }, { name: "Количество", sort: "count" }, { name: "Операция", sort: "instrumentTransferAction" },
  { name: "Дата", sort: "timestamp" }]

  return (<div className="d-xl-flex w-100">
    <div className="flex-xl-fill me-1">
      <BasicTable fields={tableFields} page={instrumentTransfer} onsort={(e) => fetch(e)}>
        {table}
      </BasicTable>
    </div>
    <div>
      <div className="position-sticky border border-success rounded p-1"
        style={{ backgroundColor: templates.block_background_color, width: "170px" }}>
        <div className="d-block">
          <div className="p-1">
            <Button
              variant="success"
              className="w-100"
              onClick={props.onClose}>
              <i className="bi bi-arrow-left-square mr-1"> </i>
              {templates.button_close}{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>)

}


function InhandView(props) {
  const [instInHand, setInstInHand] = useState({ content: [] });
  const [showExport, setShowExport] = useState(false);
  const [showReturnModal,setShowReturnModal] = useState(false);

  const tableFields = [{ name: "#", sort: "id" }, { name: "Наименование", sort: "instrument_name" }, { name: "Производитель", sort: "instrument_vendor" },
  { name: "Модель", sort: "instrument_model" }, { name: "Количество", sort: "count" },
  { name: "Дата", sort: "timestamp" }]


  function fetch(pageParams) {
    GetHttpParams(_URL_RECIPIENT + "/inhand", {
      page: pageParams[0],sort:"id",
      recipient: props.recipient.id
    }).then(e => setInstInHand(e));
  }

  const table = instInHand.content.map((val, key) => {
    return (
      <tr key={key}>
        <td> {key + 1} </td>
        <td> {val.instrument.name} </td>
        <td> {val.instrument.vendor} </td>
        <td> {val.instrument.model} </td>
        <td> {val.count} </td>
        <td> {new Date(val.timestamp).toLocaleDateString()} </td>
      </tr>
    );
  });

function ModalReturn({onClosed}){

  const [issueRequest] = useState(instInHand.content.map(e=>{return({count:0,requestId:e.id,valid:true})}));
  
  const onChange =(event,val)=>{
    let aval = val.count-val.reserved;
    let inputValid = event.target.value<=aval & event.target.value >= 0;
    
    if(inputValid){
      event.target.classList.remove("is-invalid");
      
    }else {
      event.target.classList.add("is-invalid");
    }
    issueRequest.shift(e=>e.id===val.id);
    issueRequest.push({requestId:val.id,count:event.target.value,valid:inputValid});
    
  }
  const handleReturn =()=>{
    if(issueRequest.filter(e=>e.valid==false).length===0){
      PostHttp(issueRequest,_URL_ISSUE+`/${props.recipient.id}/return`)
      .then(()=>{setShowReturnModal(false);onClosed()})
    }
    
  }

 return( <Modal show={showReturnModal} onHide={()=>setShowReturnModal(false)} size="lg">
    <   Modal.Header closeButton>
          <Modal.Title>Возврат инструмента</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="text-center" striped hover size="sm">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Модель</th>
                <th>Количество</th>
                <th></th>
              </tr>
            </thead>
          {instInHand.content.map((val, key) => {
              return (
                <tr key={key}>
                  <td > <div >{val.instrument.name}</div> </td>
                  <td> <div >{val.instrument.model} </div></td>
                  <td style={{maxWidth:"50px"}}> {`${val.count}/${val.count-val.reserved}`} </td>      
                  <td>
                    <div className="mt-1" style={{maxWidth:"120px"}}>
                      <Form.Control  size="sm" type="number" max={val.count-val.reserved} min={0} onChange={(e)=>onChange(e,val)}></Form.Control>
                    </div></td>
                </tr>
              );
            })}
          </Table>          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowReturnModal(false)}>
            Закрыть
        </Button>
        <Button onClick={handleReturn}>Вернуть</Button>
        </Modal.Footer>
      </Modal>)
}

  if(showExport){
    return(<div >
      <ExportComponent
        id={props.recipient.id}
        type="inhands"
        title={"Инструмент на руках у " + props.recipient.firstname + " " + props.recipient.secondname}
        onExit={() => setShowExport(false)}
      />
    </div>)
  }else {
    return(<div className="mx-2">
            <ModalReturn onClosed={()=>fetch([0,""])}/>
              <Row>
              <Col>
                <BasicTable fields={tableFields} page={instInHand} onsort={(e) => fetch(e)}>
                  {table}
                </BasicTable>
              </Col>
              <Col sm={2}>
                <div className="border border-success rounded p-2"
                  style={{ backgroundColor: templates.block_background_color}}>
                  <div className="">
                    <div className="p-1">
                      <Button
                        variant="success"
                        className="w-100"
                        onClick={() => setShowExport(true)}>
                        <i className="bi bi-arrow-left-square mr-1"> </i>
                        {templates.button_save}
                      </Button>
                    </div>
                    <div className="p-1">
                      <Button
                        variant="success"
                        className="w-100"
                        onClick={props.onClose}>
                        <i className="bi bi-arrow-left-square mr-1"> </i>
                        {templates.button_close}
                      </Button>
                    </div>
                    <div className="p-1">
                      <Button
                        variant="success"
                        className="w-100"
                        onClick={()=>setShowReturnModal(true)}>
                        <i className="bi bi-arrow-left-right mr-1"> </i>
                        Вернуть
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              </Row>
            </div>)
  }

  
}
