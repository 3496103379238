import React, { useEffect, useState } from 'react';
import {Table,Pagination} from "react-bootstrap";

/**
 *  Принимает масив обьектов с полями name - имя загорловка таблицы и 
 *  sort - поле в бекенде по которому сортируется столбец.
 *  page - Page<T> обертка страницы
 *  onSort - функция получения данных таблицы
 *  пример <BasicTable  fields={fieldArray} page={pagination} onsort={(e)=>fetchData(e)}>
 * @param {*} props 
 * @returns 
 */

export function BasicTable(props) {
    
    let [direction,setDirection] = useState("desc");
    let [sortby,setSortby] = useState('');
    let [currentPage,setCurrentPage ] = useState(0);
    

    const f = props.fields.map(e => { return (<th style={{cursor:'pointer'}} onClick={(event)=>props.onsort(onSorting(currentPage,e.sort))} >{e.name}</th>) });

    useEffect(()=>{props.onsort(onSorting(currentPage,sortby))},[]);

    function onSorting(page,sortBy){
        setCurrentPage(page);
        const ea = sortby === sortBy  ? direction === 'asc' ? 'desc' : 'asc' : 'desc';
        setDirection(ea);
        setSortby(sortBy);
        let sortDir = sortBy.length === 0 ? '' : sortBy+","+ea ;
        
        return [page,sortDir];
    }

    function onPage(page,sortBy){
        setCurrentPage(page);
        let sortDir = sortBy.length === 0 ? '' : sortBy+","+direction ;
        return [page,sortDir];
    }
    
    return (<>
        <Table striped bordered hover size="sm" className='text-center'>

            <thead>
                <tr>
                {f}      
                </tr>
            </thead>
            <tbody>
                {props.children}
            </tbody>
        </Table>
         <PaginationComponent  page={props.page} onpage={(p)=> props.onsort(onPage(p,sortby))} /> 
        </>)
}

 function PaginationComponent(props) {

	let [pageNumber,setPageNumber] = useState(0);

	let content;

		let pagination_item = [];
		for (let a = pageNumber-2; a < pageNumber+3; a++) {
			if(props.page.totalPages > a){
				pagination_item[a] = <Pagination.Item id={a} active={pageNumber===a}  onClick={()=>{setPageNumber(a); props.onpage(a);} }>{a + 1}</Pagination.Item>
			}
		}
		let next = pageNumber + 1 < props.page.totalPages ? pageNumber + 1 : pageNumber ;
		let priveous = pageNumber -1 >= 0 ? pageNumber -1 : 0 ;
		content = <Pagination className="justify-content-center" size={props.size !== undefined ? props.size : ''}>
			<Pagination.First onClick={() => {setPageNumber(0); props.onpage(0);}}/>
			<Pagination.Prev onClick={() => {setPageNumber(priveous); props.onpage(priveous);} } />
			{pagination_item}
			<Pagination.Next onClick={() => {setPageNumber(next); props.onpage(next);} }/>
			<Pagination.Last onClick={() => {setPageNumber(props.page.totalPages - 1); props.onpage(props.page.totalPages - 1);} }/>
		</Pagination>
	
    

	return (
		<>
			{content}
		</>

	)

}