import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Button,
  Col,
  Form, Tabs, Tab,
  TabContent
} from 'react-bootstrap'
import { Catalog, RequestInputCount, ConfirmDialog } from "../component/components"
import * as templates from "../assets/js/UITemplates";
import {
  GetHttpParams,
  _URL_IC_REQUEST,
  _URL_INSTRUMENT_CONTAINER,
  PostHttp,
  _URL_ISSUE,
  DeleteHttp, GetHttp
} from '../assets/js/Connectorv2';
import { PageLoadMain } from '../component/loader';

function renameState(rawNameState) {

  if (rawNameState === "COMPLETED") {
    return <td>Завершено
      <i className="bi bi-check-circle text-success"></i>
    </td>;

  } else if (rawNameState === "WAIT_ACCEPT") {
    return <td>Отжидает
      <i className="bi bi-alarm"></i>
    </td>;
  } else if (rawNameState === "ACCEPTED") {
    return <td>Принят
      <i className="bi bi-check me-1 text-primary"></i>
    </td>;
  } else if (rawNameState === "INPROGRESS") {
    return <td>В процессе
      <i className="bi bi-hourglass text-warring"></i>
    </td>;
  } else if (rawNameState === "REJECTED") {
    return <td>Отклонен
      <i className="bi bi-x-octagon ms-2 text-danger "></i>
    </td>;
  }
  return rawNameState;
}
export function Transfer() {

  return (

    <div className="dynamic-content dynamic-content-md">
      <Tabs defaultActiveKey="income" mountOnEnter unmountOnExit>
        <Tab eventKey="income" title="Входящие" >
          <TabContent>
            <Incoming />
          </TabContent>
        </Tab>
        <Tab eventKey="outcome" title="Исходящие" >
          <TabContent>
            <Outcoming />
          </TabContent>

        </Tab>
      </Tabs>
    </div>
  )
}

function Incoming() {
  const [inICtransferRequest, setInICtransferRequest] = useState({ content: [] });

  const [showIncomeTransfer, setShowIncomeTransfer] = useState();

  function fetch() {
    GetHttpParams(_URL_IC_REQUEST, {
      page: 0,
      outbound: false
    }).then((ictr) => {
      setInICtransferRequest(ictr);

    })
  }

  useEffect(() => fetch(), [])

  if (showIncomeTransfer) {
    return (<IncomingTransfer onHide={() => {setShowIncomeTransfer();fetch()}} onCancel={() => { }} id={showIncomeTransfer} />)
  } else {

    return (
      <div>
        <Table striped bordered hover className="instrument-table" size="sm" > <thead>
          <tr style={{
            textAlign: "center",
            position: "sticky",
            top: "0",
            backgroundColor: "white"
          }}>
            <th>#</th>
            <th>Источник</th>
            <th>Коментарий</th>
            <th>Время</th>
            <th>Состояние</th>
            <th>Подробно</th>
          </tr>
        </thead>
          <tbody>
            {inICtransferRequest.content.map((v, k) => {
              return (
                <tr>
                  <td >{k + 1}</td>
                  <td>{v.src.name}</td>
                  <td>{v.comment}</td>
                  <td>{new Date(v.created).toLocaleDateString()}</td>
                  {renameState(v.status)}
                  <td>
                    <i className="bi bi-card-list" id={v.id} style={{
                      cursor: "pointer"
                    }} onClick={()=>setShowIncomeTransfer(v.id)}></i>
                  </td>
                </tr>)
            })}
          </tbody>
        </Table>
      </div>)
  }

}

function  Outcoming() {

  const [outICtransferRequest, setOutICtransferRequest] = useState();
  const [restore, setRestore] = useState("");
  const [viewTransfer,setViewTransfer] = useState();

  function fetchRestore() {
    GetHttp(_URL_IC_REQUEST + "/restore").then((res) => {
      if (res === "") {
        setRestore("");
        fetch();

      } else {
        setRestore(res);

      }
    })
  }

  function fetch() {
    GetHttpParams(_URL_IC_REQUEST, {
      page: 0,
      outbound: true
    }).then((ictr) => {
      setOutICtransferRequest(ictr);
    })
  }

  useEffect(() => fetchRestore(), [])

  const handleAddnewICGroup = () => {
    GetHttpParams(_URL_IC_REQUEST + '/new').then((e) => {
      fetchRestore();
    })
  }
  if (restore !== "") {
    return (<CreateOutcomingTransfer item={restore} onHide={() => { fetchRestore() }} />)
  }else if(viewTransfer){ 
    return(<ViewOutcomingTransfer onHide={()=>setViewTransfer()} id={viewTransfer}/>)
  }else {
    if (outICtransferRequest) {
      return (<div>
        < Table striped bordered hover className="instrument-table" size="sm" > <thead>
          <tr style={{
            textAlign: "center",
            position: "sticky",
            top: "0",
            backgroundColor: "white"
          }}>
            <th>#</th>
            <th>Назначение(получатель)</th>
            <th>Коментарий</th>
            <th>Время</th>
            <th>Состояние</th>
          </tr>

        </thead>
          <tbody>
            {outICtransferRequest.content.map((v, k) => {
              return (<tr>
                <td >{k + 1}</td>
                <td className='text-primary' onClick={()=>setViewTransfer(v.id)} style={{cursor:'pointer'}}>{v.dst.name}</td>
                <td>{v.comment}</td>
                <td>{new Date(v.created).toLocaleDateString()}</td>
                {renameState(v.status)}
              </tr>);

            })}

          </tbody>
        </Table>

        <div className="text-center">
          <Button onClick={handleAddnewICGroup}>{templates.button_create}</Button>
        </div>
      </div>
      )
    } else {
      return (<PageLoadMain />)
    }
  }
}

function CreateOutcomingTransfer({ item, onHide }) {


  const [icTransferGroup, setIcTransferGroup] = useState({ comment: "", transferRequest: [], dst: null, src: null });
  const [showCatalog, setShowCatalog] = useState(false);
  const [showRequestCount, setShowRequestCount] = useState(false);
  const [instrumentByCode, setInstrumentByCode] = useState('');
  const [iConteiners, setiConteiners] = useState({ content: [] });
  const [disBtTransfer, setDisBtTransfer] = useState(true);

  function fetchContainer() {
    GetHttpParams(_URL_IC_REQUEST + "/get", { icTransferGroup: item.id })
      .then(request => {
        GetHttpParams(_URL_IC_REQUEST + "/requests/all", { icTransferGroup: item.id })
          .then(res => {
            request.transferRequest = res;
            setIcTransferGroup(request);
          })

        setDisBtTransfer(request.dst === null);
      })
  }

  function fetchContainers() {
    GetHttpParams(_URL_INSTRUMENT_CONTAINER).then(ic => {
      setiConteiners(ic);
      fetchContainer();
    })
  };

  useEffect(() => {
    fetchContainers();
  }, [])

  const handleCodeInput = (event) => {
    if (event.keyCode === 13) {
      PostHttp({
        code: event.target.value,
        mode: 1
      }, _URL_ISSUE + '/findcode').then((r) => {
        if (r.init === "ii") {
          setInstrumentByCode(r.instrument);
          setShowRequestCount(true);
        }
      })
      event.target.value = "";
    }

  }
  const handleFindWithId = (id) => {
    PostHttp({
      requestId: id, mode: 1
    }, _URL_ISSUE + '/findid').then((r) => {
      if (r.init === "ii") {
        setInstrumentByCode(r.instrument);
        setShowRequestCount(true);
      }
    })
  }



  const handleChange = (event) => {
    let comment = icTransferGroup.comment;
    let dst_id;
    if (icTransferGroup.dst !== null) {
      dst_id = icTransferGroup.dst.id;
    }

    if (event.target.id === "comment") {
      comment = event.target.value;

    } else if (event.target.id === "dst") {
      if (event.target.value === "-1") {
        setDisBtTransfer(true);
      }
      dst_id = event.target.value;
    }
    PostHttp({
      id: icTransferGroup.id,
      comment: comment,
      dst: { id: dst_id }
    }, _URL_IC_REQUEST + '/update')
      .then((resp) => {
        fetchContainers();
        //setIcTransferGroup(resp);
        setDisBtTransfer(resp.dst == null);
      });
  }

  const handleRemoveRequest = (event) => {
    DeleteHttp(_URL_IC_REQUEST, { iCTransferRequest: event.target.id })
      .then(r => {
        setIcTransferGroup(r);
      });

  }


  const handleTransfer = () => {
    GetHttp(_URL_IC_REQUEST + `/${icTransferGroup.id}/transfer`)
      .then(() => onHide())

  }
  const handleCommitRequestCount = (count) => {
    const instrument = instrumentByCode;
    const instrumentCounters = instrument.instrumentCounters;
    if (instrumentCounters != null) {
      if (instrumentCounters.avalable >= count) {
        setShowRequestCount(true);
        PostHttp({
          count: count,
          iCTransferGroup: icTransferGroup,
          instrument: instrumentByCode
        }, _URL_IC_REQUEST)
          .then(r => { setIcTransferGroup(r); setShowRequestCount(false); })
          .catch(e => alert(e.response.data.message));
      }
    }

  }

  function handleCancelTransfer(id) {
    GetHttp(_URL_IC_REQUEST + `/${id}/cancel`)
      .then(() => onHide())
  }

  if (showCatalog) {
    return (<>
      < Catalog show={showCatalog}
        transferRequest={icTransferGroup.transferRequest}
        onHide={() => setShowCatalog(false)}
        find={handleFindWithId} />

      <RequestInputCount show={showRequestCount}
        onHide={() => setShowRequestCount(false)}
        string={"Наименование: " + instrumentByCode.name}
        string1={"Производитель: " + instrumentByCode.vendor}
        onCommit={handleCommitRequestCount} />
    </>)
  } else {
    return (
      <>
        <RequestInputCount show={showRequestCount}
          onHide={() => setShowRequestCount(false)}
          string={"Наименование: " + instrumentByCode.name}
          string1={"Производитель: " + instrumentByCode.vendor}
          onCommit={handleCommitRequestCount} />
        <Col>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Коментарий</Form.Label>
              <Form.Control id="comment" type="text" onBlur={handleChange} defaultValue={icTransferGroup.comment} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Получатель</Form.Label>
              <Form.Control id="dst" type="text" as="select" onChange={handleChange}>
                <option value="-1">Выбрать получателя</option>
                {iConteiners.content.map((v) => {

                  return (icTransferGroup.dst === null
                    ? <option value={v.id}>{v.name}</option>
                    : icTransferGroup.dst.id === v.id
                      ? <option selected={true} value={v.id}>{v.name}</option>
                      : <option value={v.id}>{v.name}</option>)
                })
                }
              </Form.Control>
            </Form.Group>
          </Row>
          <div className="d-flex mb-2">
            <Form.Group >
              <Form.Label>Штрих-код</Form.Label>
              <Form.Control placeholder="ID Инструмента" id="counterPartyText" type="text" onKeyUp={handleCodeInput} />
            </Form.Group>
            <Button className="ms-2" style={{
              height: '38px',
              marginTop: '31px'
            }} onClick={() => setShowCatalog(true)}>Каталог</Button>
          </div>
          <Table striped="striped" bordered="bordered" hover="hover" className="instrument-table" size="sm">

            <thead>
              <tr style={{
                textAlign: "center"
              }}>
                <th>#</th>
                <th>Название</th>
                <th>Модель</th>
                <th>Производитель</th>
                <th>Количество</th>
                <th>Удалить</th>

              </tr>
            </thead>
            <tbody>
              {
                icTransferGroup.transferRequest.map((v, k) => {
                  return (<tr key={k}>
                    <td>{k + 1}</td>
                    <td>{v.instrument.name}</td>
                    <td>{v.instrument.model}</td>
                    <td>{v.instrument.vendor}</td>
                    <td>{v.count}</td>
                    <td>
                      <Button id={v.id} onClick={handleRemoveRequest}>X</Button>
                    </td>
                  </tr>)
                })
              }
            </tbody>

          </Table>
          <div className="text-center">
            <Button className="me-1" onClick={() => handleCancelTransfer(item.id)}>{templates.button_cancel}</Button>
            <Button onClick={handleTransfer} disabled={disBtTransfer}>{templates.button_create}</Button>
          </div>

        </Col>
      </>)
  }

}

function ViewOutcomingTransfer({ id, onHide }) {


  const [icTransferGroup, setIcTransferGroup] = useState();
  const [iConteiners, setiConteiners] = useState();

  function fetch(){
    GetHttpParams(_URL_IC_REQUEST + "/get", { icTransferGroup: id })
    .then((rest)=>{
      setIcTransferGroup(rest);
      GetHttpParams(_URL_IC_REQUEST + "/requests/all", { icTransferGroup: id })
          .then(res1 => {            
            setiConteiners(res1)
          })
    })
  }

  useEffect(() => {
    fetch();
  }, [])

  if(icTransferGroup && iConteiners){
    return (
      <>      
        <Col>
          <Row className='mb-2'>
            <Form.Group as={Col}>
              <Form.Label>Коментарий</Form.Label>
              <Form.Control id="comment" type="text" defaultValue={icTransferGroup.comment} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Получатель</Form.Label>
              <Form.Control id="dst" type="text" as="select" >                
                <option>{icTransferGroup.dst.name}</option>
                
              </Form.Control>
            </Form.Group>
          </Row>
          
          <Table striped="striped" bordered="bordered" hover="hover" className="instrument-table" size="sm">

            <thead>
              <tr style={{
                textAlign: "center"
              }}>
                <th>#</th>
                <th>Название</th>
                <th>Модель</th>
                <th>Производитель</th>
                <th>Количество</th>
                <th>Статус</th>

              </tr>
            </thead>
            <tbody>
              {iConteiners.map((v, k) => {
                  return (<tr key={k}>
                    <td>{k + 1}</td>
                    <td>{v.instrument.name}</td>
                    <td>{v.instrument.model}</td>
                    <td>{v.instrument.vendor}</td>
                    <td>{v.count}</td>
                    {renameState(v.iCStatus)}
                  </tr>)
                })
              }
            </tbody>

          </Table>
          <div className="text-center">
            <Button className="me-1" onClick={onHide}>{templates.button_close}</Button>            
          </div>

        </Col>
      </>)}else {
        return(<PageLoadMain />)
      }
  
}

function IncomingTransfer({id,onHide}){

  const [icTransferGroup,setIcTransferGroup] = useState();

  const [icTransferRequest,setIcTransferRequest] = useState();
  const [showConfirmDialog,setShowConfirmDialog] = useState(false);

  function fetch(){
    GetHttpParams(_URL_IC_REQUEST + "/get", { icTransferGroup: id })
        .then(resp => {
          setIcTransferGroup(resp)
          GetHttpParams(_URL_IC_REQUEST + '/getrequests', { icTransferGroup: id, page: 0 })
            .then(e =>setIcTransferRequest(e))
        })
  }

  useEffect(()=>fetch(),[]);

  const handleRejectICRequest = (event) => {
    GetHttpParams(_URL_IC_REQUEST + '/reject', { icTransferRequest: event.target.id }).then((e) => {
      GetHttpParams(_URL_IC_REQUEST + '/getrequests', {
        icTransferGroup: id,
        page: 0
      }).then(e => setIcTransferRequest(e))
    })
  }

  const handleAcceptICrequestAll = () => {
    GetHttpParams(_URL_IC_REQUEST + '/acceptdoc', { icTransferGroup: icTransferGroup.id }).then(() => {
      setShowConfirmDialog(false);
      onHide();
  })}

  if(icTransferGroup && icTransferRequest){
  return(<>
  < ConfirmDialog title="Подтвердите действие" onHide={()=>setShowConfirmDialog(false)} show={showConfirmDialog} onCancel={()=>setShowConfirmDialog(false)} onCommit={handleAcceptICrequestAll} message="Весь не отклоненный инструмент будет принят. Продолжить?" />
      <div className="mt-2 ">
        <div>
          <Form.Group >
            <Form.Label>Коментарий</Form.Label>
            <Form.Control id="comment" type="text" disabled={true} defaultValue={icTransferGroup.comment} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Отправитель</Form.Label>
            <Form.Control id="dst" type="text" disabled={true} defaultValue={icTransferGroup.src.name}></Form.Control>
          </Form.Group>
        </div>
        <div>
          <Table striped="striped" bordered={true} hover={true} className="instrument-table mt-2" size="sm">
            <thead>
              <tr style={{
                textAlign: "center"
              }}>
                <th>#</th>
                <th>Название</th>
                <th>Модель</th>
                <th>Производитель</th>
                <th>Количество</th>
                <th>Статус</th>
                <th>Принять</th>
              </tr>
            </thead>
            <tbody>
              {
                icTransferRequest.content.map((v, k) => {
                  return (<tr key={k}>
                    <td>{k + 1}</td>
                    <td>{v.instrument.name}</td>
                    <td>{v.instrument.model}</td>
                    <td>{v.instrument.vendor}</td>
                    <td>{v.count}</td>
                    {renameState(v.iCStatus)}
                    <td>
                      <Button variant="danger" size="sm" id={v.id} onClick={handleRejectICRequest} disabled={v.iCStatus !== "WAIT_ACCEPT"}>
                        Отклонить
                      </Button>
                    </td>
                  </tr>)
                })
              }
            </tbody>
          </Table>
        </div>
        <div className="text-center">
          <Button className="me-1" onClick={()=>onHide()}>{templates.button_close}</Button>
          <Button variant="success" onClick={()=>setShowConfirmDialog(true)} disabled={icTransferGroup.status === "ACCEPTED"}>{templates.button_accept_transfer}</Button>
        </div>

      </div>
  </>)}else {
    return(<PageLoadMain />)
  }
}




export default Transfer;
