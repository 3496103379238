
import React, {useEffect, useState} from 'react';

import {
  InputGroup,
  Nav,
  FormControl,
  Alert,
  Container,
  Modal,
  Row,
  Col,
  Button,
  Form,
  Dropdown,
  Table,
  DropdownButton,
  ProgressBar,
  Spinner
} from 'react-bootstrap'
import {
    GetHttpParams,
    PostHttp,
    _URL_CUSTOMER,
    _URL_ACCOUNT_CUSTOMER,
    _URL_TARIFF,
    _URL_ACCOUNT,
    DeleteHttp,
    _URL_FILE_RESOURCE,
    _URL_INSTRUMENT_CONTAINER,
    PutHttp,
    _URL_PERMISSION,
    _URL_BASKET
  } from '../assets/js/Connectorv2'
  import {AccountAvatar, ConfirmDialog} from "../component/components.js"
  import * as templates from "../assets/js/UITemplates";
import { BasicTable } from '../component/table';
import { ErrorDialog } from '../component/dialog';
import { PageLoad } from '../component/loader';

export function AdminPanel(props) {

    const [tab, setTab] = useState('1');
      let content;
    
    switch (tab) {
      case "1":
        content = <TabCustomer />
        break;
      case "2":
        content = <TabInsrumentContainer/>
        break;
      case "3":
        content = <UserManager />
        break;
      default:
        content = <Spinner/>
        break;
    }
    
    return (<> 
      <div className="dynamic-content dynamic-content-md">
      <Nav variant="tabs" defaultActiveKey={1} onSelect={(e)=>setTab(e)}>
        <Nav.Item>
          <Nav.Link eventKey={1}>Главная</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={2}>Обьекты/Сотрудники</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={3}>Учетные записи</Nav.Link>
        </Nav.Item>
       
      </Nav>
        {content}        
      </div>
      
        </ >
    )
  }

  function TabCustomer() {

    const [customer, setCustomer] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [saved,setSaved] = useState(false);
    const [instrumentConteiners, setInstrumentConteiners] = useState(0);
    const [values,setValues] = useState({
      instrument_percetage:0,
      accounts_percentage:0,
      wirehouses_percentage:0,
      repoUsage_percentage:0
    })
  
    useEffect(() => {
          GetHttpParams(_URL_INSTRUMENT_CONTAINER+"/count").then(r => {
            setInstrumentConteiners(r)
          })
          GetHttpParams(_URL_ACCOUNT_CUSTOMER).then(c => {
              setCustomer(c);    
              calc(c);      
          })
          
    },[] )
    let handleChange = (event) => {
      const val = event.target.value;
      if (event.target.id === "name") {
        customer.name = val;
        setCustomer(customer);
  
      } else {
        if (event.target.id === "description") {
          customer.description = val;
          setCustomer(customer);
        }
      }
    }
  
    let handleSave = () => {
      PostHttp({
        name: customer.name,
        description: customer.description
      }, _URL_CUSTOMER + '/saveinfo')
      .then(()=>{setSaved(true);})
      .catch(error=>{setErrorMessage(error.data.reason)})
    }
    function calc(customer){
      setValues({
        instrument_percetage:((customer.totalInstrumentCount / customer.tariff.instrumentLimit) * 100),
        accounts_percentage:(customer.totalAccounts / customer.tariff.accountLimit) * 100,
        wirehouses_percentage:(customer.totalIC / customer.tariff.warehousesLimit) * 100,
        repoUsage_percentage:(customer.repositoryUtilizationBytes/ customer.tariff.repositoryLimit)*100
      });
      
    }
    return (<> 
    <ErrorDialog title = "Ошибка сохранения" message = {errorMessage}
    show = {errorMessage !== ''}
    onHide = {()=>setErrorMessage('')}
    onCancel = {()=>setErrorMessage('')}>
      <div>{errorMessage}</div>
      </ErrorDialog>
      {saved ? <Alert onClose={()=> setSaved(false)} dismissible={true} variant='success'>Сохранено</Alert>
        : ''
    }
      {customer === undefined ? 
      <PageLoad />
      : <>
      <div className="d-flex shadow p-3 border mt-1 mb-2 styled-block ">
        <div className="mx-2 flex-fill">
            <Form.Group className="me-2">
              <Form.Label>Название организации</Form.Label>
              <Form.Control type="text" id="name" defaultValue={customer.name} onChange={handleChange}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Примечание</Form.Label>
              <Form.Control as="textarea" rows={3} id="description" defaultValue={customer.description} onChange={handleChange}/>
            </Form.Group>
                  <div className="text-center mt-3">
                  <Button variant="outline-success" id={-1} onClick={handleSave}>{templates.button_save}</Button>
                </div>
      </div>
      <div className="d-flex ms-auto  border-start w-25 ">
        <div className="m-auto text-center border p-5 bg-gradient bg-secondary bg-opacity-25 shadow  rounded-3">
            <h4>Хранилище</h4>
            <h2 className={values.repoUsage_percentage > 80 ? "text-danger" : '' }>{(customer.repositoryUtilizationBytes/1024/1024/1024).toFixed(2)}/{(customer.tariff.repositoryLimit/1024/1024/1024).toFixed(2)} Гб</h2>
        </div>
      </div>
      </div>
  
  
    <div className="p-2 border shadow mt-4" style={{
        backgroundColor: '#f2f5fa',
        borderRadius: '4px'
      }}>
      <div>Кол-во инструмента</div>
      <ProgressBar variant={values.instrument_percetage > 80
          ? "danger"
          : "success"} now={values.instrument_percetage} label={`${customer.totalInstrumentCount}/${customer.tariff.instrumentLimit} `}/>
      <br/>
  
      <div>Склады</div>
      <ProgressBar variant={values.wirehouses_percentage > 80
          ? "danger"
          : "success"} now={values.wirehouses_percentage} label={`${instrumentConteiners.count}/${customer.tariff.warehousesLimit} `}/>
      <br/>
      <div>Учетные записи</div>
      <ProgressBar variant={values.accounts_percentage > 80
          ? "danger"
          : "success"} now={values.accounts_percentage} label={`${customer.totalAccounts}/${customer.tariff.accountLimit}`}/>
      <br/>
    </div>
    <Row className="ml-1"></Row> </>}
  </>)
  
  }
  function TabInsrumentContainer() {
  
    const [customer,setCustomer] = useState({name:"",description:''});
    const [showAdding, setShowAdding] = useState(false);
    const [instrumentConteiner,setInstrumentConteiner] = useState({content:[]});
    const [newInstruemntContainer,setNewInstruemntContainer] = useState({name:"",description:''});
    const [errorMessage,setErrorMessage] = useState(null);

    
    function fetch(pageParams){
        GetHttpParams(_URL_INSTRUMENT_CONTAINER,{page:pageParams[0],sort:pageParams[1]}).then(r => {
          setInstrumentConteiner(r);})
    }
    
    useEffect( ()=>fetch([0,'name,ASC']),[]);

    let handleChange = (event) => {
      const target = event.target
      if (target.id === "input-name") {
        newInstruemntContainer.name = target.value;
        setNewInstruemntContainer(newInstruemntContainer);
      } else if (target.id === "input-descr") {
        newInstruemntContainer.description = target.value;
        setNewInstruemntContainer(newInstruemntContainer);
      }
  
    }
    let handleCommit = () => {
      PutHttp(newInstruemntContainer, _URL_INSTRUMENT_CONTAINER)
      .then(r => {
              GetHttpParams(_URL_INSTRUMENT_CONTAINER).then(r => {setInstrumentConteiner(r)});
              }).catch((error) => setErrorMessage( error.data.reason));
              setShowAdding(false);
      }
    
    let handleDeleteInstrumentContainer = (event) => {
      DeleteHttp(_URL_INSTRUMENT_CONTAINER, {id: event.target.id}).then(() => GetHttpParams(_URL_INSTRUMENT_CONTAINER).then(r => {
        setInstrumentConteiner(r);
  
      })).catch((error) => setErrorMessage(error.data.reason));
    }
    
    let content = instrumentConteiner.content.map((v, k) => {
        return (<tr style={{
            height: "20px"
          }}>
          <td>{v.name}</td>
          <td>{v.description}</td>
          <td>{v.instrumentCount}</td>
          <td>
            <Button id={v.id} onClick={handleDeleteInstrumentContainer} variant="danger">X</Button>
          </td>
        </tr>)
      })
  
      const addDialog = <Modal show={showAdding} onHide={()=>setShowAdding(false)}>
        <Modal.Header closeButton="closeButton">
          <Modal.Title>Новый обьект</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <InputGroup>
                  <InputGroup.Text>Название</InputGroup.Text>
                  <FormControl id="input-name" onChange={handleChange}/>
                </InputGroup>
              </Col>
  
            </Row>
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Text >Примечание</InputGroup.Text>
                  <FormControl id="input-descr" onChange={handleChange}/>
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
  
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShowAdding(false)}>
            {templates.button_close}
          </Button>
          <Button variant="primary" onClick={handleCommit}>
            {templates.button_create}
          </Button>
        </Modal.Footer>
      </Modal>
    
      const fields = [{name:'Наименование',sort:'name'},{name:'Примечание',sort:'description'},{name:'Кол-во инструмента',sort:'instrumentCount'},{name:'Удалить',sort:''}]
      
      return (<> {addDialog} 
        {errorMessage!=null ? 
            <Alert dismissible variant="danger" onClose={()=>setErrorMessage(null)}>
              {errorMessage}
            </Alert> :''}
            <Col className="mt-2">
              <div>
                  <BasicTable fields={fields} page={instrumentConteiner} onsort={(e)=>fetch(e)}>
                    {content}
                  </BasicTable>
              </div>
              <div className="text-center">
                <Button onClick={(e)=>setShowAdding(true)}>{templates.button_create}</Button>
              </div>
            </Col>
          </>)
    
  
  }
  
  export function UserManager() {
    const [permissions, setPermissions] = useState([]);
    const [instrumentConteiners, setInstrumentConteiners] = useState([])
    const [errorMessage, setErrorMessage] = useState("");
    const [showInveteDialog, setShowInveteDialog] = useState(false);
    const [accounts, setAccounts] = useState({content: []});
    const [inputEmail, setInputEmail] = useState('');
    const [showAccountInfo, setShowAccountInfo] = useState();
  
   function fetchUsers(page ) {
      GetHttpParams(_URL_PERMISSION+"/list",{}).then((p) => {
        setPermissions(p);
        GetHttpParams(_URL_CUSTOMER + '/accounts', {page: page,size:50}).then((a) => {
            setAccounts(a);
            GetHttpParams(_URL_INSTRUMENT_CONTAINER+"/list",{}).then(r => {
            setInstrumentConteiners(r);
            
          });
        });
      });
    }

    useEffect(() => {
      fetchUsers(0);
    }, []);

    let handleChangeContainer = (event, account_id) => {
      GetHttpParams(_URL_ACCOUNT + '/allowcontainer', {
        user: account_id,
        container: event.target.value
      }).catch((error) => {setErrorMessage(error.data.reason);});
    }
  
    let handleChangePermission = (user, event) => {
      user.permission.id = event.target.value;
      PostHttp(user, _URL_ACCOUNT + '/permission')
      .then(()=>fetchUsers(0))
      .catch((error) => {setErrorMessage(error.data.reason);});
    }
    
    let handleInvite = () => {
  
      PostHttp({
        email: inputEmail}, _URL_CUSTOMER+"/member").then((response) => {
        GetHttpParams(_URL_CUSTOMER + '/accounts', {page: 0,size:50}).then((a) => {
          setAccounts(a)
          setShowInveteDialog(false);
          setInputEmail('')
          document.getElementById("email").value = ""
          if(response.reason!== undefined) {setErrorMessage(response.reason);}
        })
      }).catch((error) => {setErrorMessage(error.data.reason);setShowInveteDialog(false);});
  
    }
  
    let handleDeleteAccount = (event) => {
      DeleteHttp(_URL_CUSTOMER + '/member', {id: event.target.id}).then(() => {
        GetHttpParams(_URL_CUSTOMER + '/accounts', {page: 0,size:50}).then((a) => {
          setAccounts(a)
        })
      }).catch((error) => setErrorMessage(error.data.reason));
    }
  
    let handleClearBasket = (event) => {
      GetHttpParams(_URL_BASKET + '/clear', {user: event.target.id})
      .catch((error) => {setErrorMessage(error.data.reason);});
    }
    
  
    const table = accounts.content.map((val, key) => {
      const acc = val;
      const permission = acc.permission;
      const result =  <tr key={key}>
            <td className="text-wrap text-primary" style={{maxWidth:"250px",cursor:"pointer"}} id={acc.id} onClick={() => setShowAccountInfo(acc)}>
                {acc.firstName!==null? acc.firstName: 'Не указан'}  {acc.surnameName!==null? acc.surnameName : ''}
              </td>
            <td >{acc.phone}</td>
            <td>{
                acc.enabled
                  ? 'Активен'
                  : 'Отключен'
              }</td>
            <td>{acc.email}</td>
            <td >
              <Form.Control as="select" onChange={(event) => handleChangePermission(acc, event)}>
  
                {
                  permissions.map((v, k) => {
  
                    if (acc.permission.id === v.id) {
                      return (<option selected="selected" key={k} value={v.id}>{v.permissionName}</option>)
                    } else {
                      return (<option key={k} value={v.id}>{v.permissionName}</option>)
                    }
  
                  })
                }
              </Form.Control>
            </td>
            <td >
              <Form.Select onChange={(event) => handleChangeContainer(event, acc.id)} disabled={permission.permissionName==='Admin'}>
                {
                  instrumentConteiners.map((v, k) => {
                    if (acc.instrumentContainer.id === v.id) {
                      return (<option selected="selected" key={k} value={v.id}>{v.name}</option>)
                    } else {
                      return (<option key={k} value={v.id}>{v.name}</option>)
                    }
  
                  })
                }
              </Form.Select>
            </td>
  
            <td>
              <DropdownButton variant='success' id="dropdown-basic-button" title="Меню">
                <Dropdown.Item id={acc.id} onClick={handleDeleteAccount}>Удалить</Dropdown.Item>
                <Dropdown.Item id={acc.id} onClick={handleClearBasket}>Очистить корзину</Dropdown.Item>
              </DropdownButton>
            </td>
          </tr>
        
  
      return (result)
    })
    const fields = [{name:"Имя",sort:""},{name:"Телефон",sort:""},{name:"Состояние",sort:""},{name:"email",sort:""},{name:"Права доступа",sort:""},{name:"Обьект",sort:""},{name:"Управление",sort:""}]
    return (<>
    {errorMessage!==''? <Alert className='m-1' dismissible variant="danger" onClose={()=>setErrorMessage('')}>
      {errorMessage}
    </Alert> :''}
    <ConfirmDialog title="Приглашение сотрудника" show={showInveteDialog} onHide={() => setShowInveteDialog(false)} onCancel={() => setShowInveteDialog(false)} message={"Приглашение будет отправлено на " + inputEmail} onCommit={handleInvite}/>
      {showAccountInfo !== undefined
        ? <AccountInfo account={showAccountInfo} onClose={() => setShowAccountInfo(undefined)}/>
          : <div>
            <div>
            
              <h4 className='text-center p-1 m-2'>Сотрудники</h4>
              <BasicTable fields={fields} onsort={()=>fetchUsers(0)} page={accounts}>
              {table}
              </BasicTable>              
              <div className='text-center p-1 m-2'>
              <h4>Отправить приглашение</h4>
            </div>
            <div className="d-flex">
              <div >
                <InputGroup className="mb-3">
                  <InputGroup.Text >Email</InputGroup.Text>
                  <FormControl id="email" placeholder="email" type="email" onChange={(e)=>setInputEmail(e.target.value)}/>
                </InputGroup>
              </div>
              <div className="text-center ms-2">
                <Button onClick={() => setShowInveteDialog(true)}>{templates.button_send_invate}</Button>
              </div>
            </div>
            </div>
            
          </div>
    } </>
      )
}



function AccountInfo(props){
    let profile = props.account;

    return(<>
    {profile !== undefined ?
        <Container className='ms-1'>
            <h4 className='text-center  mt-2'>Профиль</h4 > <Row className="p-2 border  me-2">
              <Col sm='auto' className=''>
                <Row className="p-2 border rounded">
                  <div className="">
                    <AccountAvatar images={profile.images}/>
                  </div>
                </Row>
              </Col>
              <Col className="border p-2 ms-2">
              <div className='h5 p-1 my-1'>
                      {profile.registred? 
                      <div><i className="bi bi-person-check mx-2 text-success"></i>Активированная учетная запись</div>: 
                      <div><i className="bi bi-person-fill-slash me-2 text-danger"></i>Не активированная учетная запись</div>}
                  </div>
                  <div className='d-flex'>
                    <Form.Group className="mb-3 me-2">
                      <Form.Label>Имя</Form.Label>
                      <Form.Control id='firstname' type="text" defaultValue={profile.firstName} disabled={true}/>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3 me-2 ">
                      <Form.Label>Фамилия</Form.Label>
                      <Form.Control id='surname' type="text" defaultValue={profile.surnameName} disabled={true}/>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3 me-2 ">
                      <Form.Label>Отчество</Form.Label>
                      <Form.Control id='secondname' type="text" defaultValue={profile.secondName} disabled={true}/>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3 me-2 ">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" defaultValue={profile.email} disabled="disabled"/>
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3 me-2 ">
                    <Form.Label>Телефон</Form.Label>
                    <Form.Control type="text" defaultValue={profile.phone} disabled={true} id="phone"/>
                  </Form.Group>
                  
              </Col>              
              </Row>
              <div className="text-center mt-3">
              <Button onClick={props.onClose}>Закрыть</Button>
              </div>

        </Container> : ''
  } </>)}