/**
 *
 */

export const project_title = "skladsystem.ru";

export const button_create = "Создать";
export const button_cancel = "Отмена";
export const button_delete = "Удалить";
export const button_save = "Сохранить";
export const button_add = "Добавить";
export const button_doit = "Провести";
export const button_request = "Запросить";
export const button_refresh = "Обновить";
export const button_save_inhand = "Сохранить инструмент на руках";
export const button_back = "Назад";
export const button_inventory = "Инвентаризация";
export const button_dismiss = "Уволить";
export const button_change_foto ="Изменить фото";
export const button_catalog ="Каталог";
export const button_edit="Редактировать";
export const button_close="Закрыть";
export const button_add_instrument="Добавить инструмент";
export const button_add_instrument_group ="Добавить группу инструмента";
export const button_add_category ="Добавить категорию";
export const button_accept_transfer="Принять перемещение";
export const button_accept = "Принять";
export const button_register = "Зарегестрироватся";
export const button_ihavesigin = "У меня есть учетные данные";
export const button_send_invate = "Отправить приглашение";
export const button_search = "Поиск";
export const button_search_more = "Еще результаты";
export const button_codes="Штрих-коды";
export const button_issue_history="История выдачи";
export const button_instrument_stat="Статистика";
export const button_instrument_inhand="На руках";
export const button_import="Импорт";
export const button_enableEdit="Режим редактирования"
export const button_instrument_check = "Проверка инструмента"

export const error_message_search = "Произошла ошибка, повторите запрос познее или обратитесь в поддержку"
export const argument_exception = "Ошибка, проверте правильность значений ";
export const info_message_confirm_dismiss = "Весь инструмент будет возвращен на склад.Уволить"

export const block_background_color='#f2f5fa';

