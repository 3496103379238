import React, {
  useEffect,
  useState
} from 'react';
import {
  Button,
  Form,
  Alert,
  Image,
  Col,Row
} from 'react-bootstrap';
import "../assets/css/login.css";
import {
  RecoveryPassword,GetAuthentification,_URL_IMAGE_REPOSITORY
} from '../assets/js/Connectorv2';
import * as image from "../assets/images/default.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from 'react-oidc-context';



export default function Login() {
  const [error, setError] = useState();
  const [account,setAccount] = useState();
  const [showRecovery, setShowRecovery] = useState(false);
  const [avatar,setAvatar] =useState();
  let navigate = useNavigate();
  
  const auth = useAuth();

  function fetchAccount(){
    if(auth.isAuthenticated ){
    GetAuthentification()
      .then((acc)=>{
        setAccount(acc);
        const img = acc.images.find(e=> e.defaultFile);
        if(img!==undefined)
        {setAvatar(_URL_IMAGE_REPOSITORY+"?uuid=" + img.uuid+"&type=account");}
      })
    }
    
  }

  
  function onRecovery(email) {
    RecoveryPassword(email).then((e)=>{
      setShowRecovery(false)
      setError({text:"Мы отправили ссылку для восстановления доступа к вашему аккаунту на адрес " + email,variant:"primary"});
    });
  }
  
 

  function Sign() {
    let [user, setUser] = useState({
      username: '',
      password: ''
    });

    let handleTryLogin = () => {     
      auth.signinResourceOwnerCredentials({username:user.username,password:user.password,skipUserInfo:false})
          .then((user)=>{
              if(user!==null){
                document.location.href="/";                         
              }
          })        
  };

  function responseConvert(string){
    switch(string){
      case 'Invalid user credentials': return "Неверный логин или пароль";
      default :return string;
    }

  }
    function handleInputCredentials(id, value) {
      if (id === 'username') {
        setUser(user => ({
          username: value,
          password: user.password
        }));
      } else if (id === 'password') {
        setUser(user => ({
          username: user.username,
          password: value
        }));
      }

    }
    function clear(){
      const inputs = document.getElementsByTagName('input');
      for(let el of inputs ){
        el.value ='';
      }

    }

    const onResumeClick =()=>{
      navigate("/main");
    }
    const handleEnter = (event)=>{
      if(event.code === 'Enter'){
        handleTryLogin();
        clear()
      }
    }
    
    return (<> <div className = "mx-auto w-25" >
    <Col className="text-center" >
      <Image src="/assets/sklad_logo_big.png" style={{maxWidth:"20em"}}/>
    </Col>
    {error
        ? <div className="mx-auto">
            <Alert className="p-1 text-center" variant={error.variant}>{error.text}</Alert>
          </div>
        : ''
      }
    {auth.error
        ? <div className="mx-auto">
            <Alert className="p-1 text-center" variant='danger'>{responseConvert(auth.error.message)}</Alert>
          </div>
        : ''
      }
      
      {account!==undefined ? <div className='mb-3'>
      <div className='h5 text-center'><b>Выберите аккаунт для входа</b></div>
      <div className='border border-success rounded p-1 text-left d-flex mb-3 justify-content-start' onClick={onResumeClick} style={{cursor:"pointer"}}>
        <div className='mx-3'>
          <Image src={avatar === undefined ? image.default : avatar}
          width="60px" height="60px" className='border rounded-circle'/>
        </div>
        <div className='my-auto'>
          <div>{account.firstName +" "+ account.surnameName}</div>
          <div>{account.email}</div>
        </div>
        
      </div>
      <div className='h5 text-center'><b>Или смените аккаунт</b></div>
      </div>
      : ""
      }
    <div>
            <Form.Group >
              <Form.Label><b>Имя пользователя или email</b></Form.Label>
              <Form.Control
                  name="username"
                  value={user.username}
                  type="text" onInput={(event)=>handleInputCredentials("username",event.target.value)} 
                  onKeyUp={handleEnter}
                  />
              <Form.Text className="text-muted"></Form.Text>
            </Form.Group>
      </div>
     < Form.Group > <Form.Label ><b>Пароль</b></Form.Label>
        <Form.Control
          name="password"
          type="password"
          onInput={(event)=>handleInputCredentials("password",event.target.value)}
          onKeyUp={handleEnter}
          />
        </Form.Group>
        <div className="mt-4 mb-1">
          <Button className="w-100 mt-1" variant="success" onClick={(e) => {handleTryLogin();clear()}}>
            Войти
          </Button>
        </div>
        <div>
          <Button className="w-100 mt-1" variant="link text-decoration-none" onClick={() => {auth.removeUser();setShowRecovery(true);} }>
            Забыли пароль?
          </Button>
        </div>
        <div>
          <Button className="w-100 mt-1" variant="link text-decoration-none" onClick={() => navigate("/registration")}>
            Регистрация
          </Button>
        </div>
        
      </div>
      </>)
  }

  return (<>
    <div className = "h-100" >
    <div className="d-flex h-100">
    <div className="login-left-side"></div>
    {
      showRecovery
        ? <Recovery onRecovery={onRecovery} onHide={()=>setShowRecovery(false)}/>
      : <Sign/>
    }
  </div>
      </div>
      </>)
}

function Recovery(props) {
  const [email,setEmail] = useState('');
  const [message,setMessage] = useState();
  

  let validateInput = ()=> {
    if(email.length>0){
      props.onRecovery(email)
    }else {
      setMessage("Email не может быть пустым");
    }
  }

  return <> <div className = "mx-auto w-25" >
  <div className="text-center">
    <Image src="/assets/sklad_logo_big.png" style={{maxWidth:"20em"}}/>
  </div>
  {message  ?
  <div className="mx-auto">
      <Alert className="p-1" variant="danger">{message}</Alert>
  </div> : ''}
  <div>
    <Form.Group >
      <Form.Label><b>Укажите Email который был задан при регстрации</b></Form.Label>
      <Form.Control
        id="username"
        type="text"
        onInput={(event)=>setEmail(event.target.value)}
        autoComplete="username"/>
      <Form.Text className="text-muted"></Form.Text>
    </Form.Group>
  </div>

  <div className="">
    <Button className="w-100 mt-2" variant="primary" onClick={validateInput}>
      Отправить
    </Button>
  </div>
        <Row className="justify-content-md-center mb-2">
							<Button variant="link" onClick={props.onHide}>
							Отмена
				      </Button>
				</Row>
</div>
</>
}
