import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Row,
  FormControl,
  Image,
  Form,
  Col,
  Table,
  Modal,
  InputGroup,
  Toast
} from 'react-bootstrap';
import * as image from '../assets/images/default.png';
import {
  GetHttpParams,
  _URL_ISSUE,
  _URL_BASKET,
  PostHttp,
  DeleteHttp,
  _URL_ISSUE_MODE, _URL_IMAGE_REPOSITORY, _URL_RECIPIENT, _URL_INSTRUMENT
} from '../assets/js/Connectorv2'
import { ConfirmDialog, IssueModeSwitcher } from '../component/components';
import * as templates from "../assets/js/UITemplates";
import { MiniCatalog, RecipientSelection } from "../component/minicatalog";

export function IssueV2() {

  const [issueMode, setIssueMode] = useState(1);
  const [basket, setBasket] = useState([]);
  const [requestInstrument, setRequestInstrument] = useState(null);
  const [outputPanel, setOutputPanel] = useState({
    name: "",
    model: "",
    vendor: "",
    count: "",
    invent: "",
    type: ""
  })
  const [instrumentInHand, setInstrumentInHand] = useState([])
  const [showCatalog, setShowCatalog] = useState(false);
  const [showRecipientSelection, setShowRecipientSelection] = useState(false);
  const [avatar, setAvatar] = useState("")
  const [errorMsg, setErrorMsg] = useState("");
  const [recipient, setRecipient] = useState({
    firstname: 'ФИО получателя',
    secondname: '',
    surname: '',
    images: []
  });
  const [showToast, setShowToast] = useState(false);
  const [inputCount, setInputCount] = useState(0);

  const toggleMode = ((val) => {
    setIssueMode(val);
    PostHttp({
      mode: val
    }, _URL_ISSUE_MODE).then(() => {
      GetHttpParams(_URL_BASKET).then(b => {
        setBasket(b);
        setOutputPanel({
          name: "",
          model: "",
          vendor: "",
          count: "",
          invent: "",
          type: ""
        })
        setRecipient({
          firstname: 'ФИО получателя',
          secondname: '',
          surname: '',
          images: []
        })

      })
    });

  })

  useEffect(() => {

    GetHttpParams(_URL_ISSUE + '/mode').then(m => {
      GetHttpParams(_URL_BASKET).then(b => {
        setBasket(b);
        setIssueMode(m.mode ? 2 : 1);
      })
    })
  }, []);

  function readResponse(r) {
    let image;
    if (r !== '') {
      if (r.init === "ii") {
        r.instrument.images.forEach(v => {
          if (v.defaultFile) {
            image = v
          }
        });
        if(r.instrument.singleIssue){
          handleSingleIssue(r.instrument);
          
        }else {
          setRequestInstrument(r.instrument);
        }
        setAvatar("");
      } else if (r.init === "ri") {
        if (r.instrumentInHands.length > 0) {
          r.instrumentInHands[0].instrument.images.forEach(v => {
            if (v.defaultFile) {
              image = v
            }
          });
        }
        setInstrumentInHand(r.instrumentInHands);
        setAvatar("")
      }
      if (r.recipient !== null) {

        //по коду вернулся получатель
        GetHttpParams(_URL_BASKET).then(b => {
          r.recipient.images.forEach((v) => {
            if (v.defaultFile) {
              r.recipient.content = _URL_IMAGE_REPOSITORY + '/?uuid=' + v.uuid + '&type=recipient'
            }
          });
          setBasket(b);
          setRecipient(r.recipient);
          setShowToast(basket.length > 0);
        })
      }
      if (image !== undefined) {
        setAvatar(_URL_IMAGE_REPOSITORY + "?uuid=" + image.uuid + "&type=instrument");
      }
    }

  }

  const handleFindWithcId = (id) => {
    PostHttp({
      requestId: id,
      mode: issueMode
    }, _URL_ISSUE + '/findid').then(r => {
      readResponse(r);
    })
    

  }
  const handleFindCode = (code) => {
    PostHttp({
      code: code,
      mode: issueMode
    }, _URL_ISSUE + '/findcode').then(r => {
      readResponse(r);
    })
    
  }

  const handleFindCodeForm = (event) => {
    let code = event.target.value;
    if (event.keyCode === 13) {
      handleFindCode(code)
      event.target.value = "";
    }

  }

  const handleChangeInputCount = (event) => {
    if (event.keyCode === 13) {
      handleCommitRequestCount()
    }
    setInputCount(event.target.value)
  }

  const updateDetailPanel = (instrument) => {
    setOutputPanel({
      name: instrument.name,
      model: instrument.model,
      vendor: instrument.vendor,
      count: instrument.count,
      invent: instrument.invent,
      type: instrument.type
    })

  }

  const handleCloseRequestCount = () => {
    setRequestInstrument(null);

  }

  function handleSingleIssue(instrument) {
    let avalable;
    if (instrument.instrumentCounters != null) {
      avalable = instrument.instrumentCounters.avalable;
      if (avalable >= 1) {
        PostHttp({
          id: instrument.id,
          count: 1,
          mode: issueMode
        }, _URL_BASKET + '/add').then((b) => {
          updateBasket()
          
        })
      } else {
        alert("Доступно " + avalable)
      }
    } else {
      alert("Доступно 0 ")
    }
  }

  const handleCommitRequestCount = () => {
    let avalable;
    if (requestInstrument.instrumentCounters != null) {
      avalable = requestInstrument.instrumentCounters.avalable;
      if (avalable >= inputCount && inputCount >0) {
        PostHttp({
          id: requestInstrument.id,
          count: inputCount,
          mode: issueMode
        }, _URL_BASKET + '/add').then((b) => {
          updateBasket()
          updateDetailPanel(b.instrument);
          handleCloseRequestCount()
        })
      } else {
        alert("Доступно " + avalable + " Вы ввели " + inputCount)
      }
    } else {
      alert("Доступно 0  Вы ввели " + inputCount)
    }
  }

  function updateBasket() {
    GetHttpParams(_URL_BASKET).then((b) => {
      setBasket(b);
    })
  }

  const handleCloseinstrumentInHand = () => {
    updateBasket();
    setInstrumentInHand([]);
  }


  const onRecipientSelect = (recipient) => {
    setShowRecipientSelection(false);
    if (recipient.identificator !== undefined) {
      handleFindCode(recipient.identificator.code)
    }
  }

  function hanldeClicklBasketItem(instrument) {
    updateDetailPanel(instrument);
    setAvatar("");
    instrument.images.forEach(i => {
      if (i.defaultFile) {
        setAvatar(_URL_IMAGE_REPOSITORY + "?uuid=" + i.uuid + "&type=instrument");
      }
    });
  }

  let show = requestInstrument !== null;
  let outputstr = ""
  let count = 0;

  if (requestInstrument !== null) {
    outputstr = requestInstrument.name + " " + requestInstrument.model + " " + requestInstrument.vendor;
    if (issueMode === 1) {
      if (requestInstrument.instrumentCounters != null) {
        count = requestInstrument.instrumentCounters.avalable;
      } else {
        count = 0;
      }
    } else if (issueMode === 2) {
      if (requestInstrument.instrumentCounters != null) {
        count = requestInstrument.totalСount - requestInstrument.instrumentCounters.avalable;
      } else {
        count = 0;
      }
    }

  }

  let errorDialog;
  if (errorMsg !== '') {
    errorDialog = <ConfirmDialog show={errorMsg !== ''} title="Ошибка" message={errorMsg} onHide={() => setErrorMsg("")} onCancel={() => setErrorMsg("")} onCommit={() => setErrorMsg("")} />
  }
  let returnSelectRecipient = <InstrumentInHand show={instrumentInHand.length !== 0} onClose={handleCloseinstrumentInHand} instrumentInHand={instrumentInHand} updateDetailPanel={updateDetailPanel} />
  let content;
  const tableField = [{ name: "#", sort: 'id' }, { name: "Наименование", sort: "name" }, { name: "Производитель", sort: "vendor" },
  { name: "Модель", sort: "model" }, { name: "Кол-во", sort: "count" }, { name: "Удалть", sort: "" }]
  if (!showCatalog && !showRecipientSelection) {
    content = <>
      <div className="d-flex my-2 w-100">
        <div className="d-flex mb-3 mx-auto">
          <div className="">
            <IssueModeSwitcher onToggle={(mode) => toggleMode(mode)} value={issueMode} />
          </div>
        </div>
        <div className="mx-auto">
          <Form.Group className='text-center'>
            <h4>{`${issueMode==1?'Выдача':'Возврат'} инструмента`}</h4>
            <FormControl autoFocus placeholder="ID Инструмента" style={{ maxWidth: '450px' }} onKeyUp={handleFindCodeForm} />
          </Form.Group>
        </div>
        <div className="d-flex mx-auto">
          <div>
            <Button className="me-1" style={{ marginTop: '32px' }} onClick={() => setShowCatalog(true)}>Каталог</Button>
          </div>
          <div>
            <Button style={{ marginTop: '32px' }} onClick={() => setShowRecipientSelection(true)} disabled={basket.length == 0}>Получатели</Button>
          </div>
        </div>

      </div>
      <ImagePanel avatar={avatar}
        outputPanel={outputPanel}
        recipient={recipient}

      />
      <div className="text-center">
        <h4 className='ms-auto'>{
          issueMode === 1
            ? 'ТМЦ на выдачу'
            : 'ТМЦ на возврат'
        }</h4>
      </div>

      {/* <BasketTable fields={tableField} page={this.state.basket} onsort={()=>{}} >

      </BasketTable> */}
      <BasketTable
        basket={basket}
        hanldeClicklBasketItem={(instrument) => hanldeClicklBasketItem(instrument)}
        updateBasket={() => updateBasket()}
      />
    </>
  }

  if (showRecipientSelection) {
    content = <RecipientSelection onclose={() => setShowRecipientSelection(false)} onselect={onRecipientSelect} />;
  }
  if (showCatalog) {
    content =
      <>
        <div className="mt-3">
          <MiniCatalog show={showCatalog} onHide={() => setShowCatalog(false)} find={handleFindWithcId}>
            {basket.map((v, key) => {
              let instrument = v.instrument;
              let instrumentInHand = v.instrumentInHand;
              let value;
              if (instrument == null) {
                value = instrumentInHand.instrument
              } else {
                value = instrument
              }

              return (
                <tr style={{ height: "20px" }} key={key}>
                  <td className="text-truncate" style={{ maxWidth: "110px" }}>{value.name}</td>
                  <td className="text-truncate" style={{ maxWidth: "110px" }}>{value.vendor}</td>
                  <td>{value.model}</td>
                  <td>{v.count}</td>
                </tr>
              )
            })}
          </MiniCatalog>
        </div>
      </>
  }
  const render = <>
    <div className="d-md-none d-lg-block" >
      <div className="position-absolute bottom-0 end-0">
        <Toast show={showToast} delay={15000} autohide={true} onClose={() => setShowToast(false)} style={{ minWidth: "300px" }}>
          <Toast.Header>
            <strong className="mr-auto">Сообщение</strong>
            <small>Сейчас</small>
          </Toast.Header>
          <Toast.Body>Выдано: {recipient.firstname + " " + recipient.secondname + " " + recipient.surname}</Toast.Body>
        </Toast>
      </div>

      {content}
    </div>
    <div className=" d-none d-md-block d-lg-none ms-1 " >
      <div className="position-absolute bottom-0 end-0">
        <Toast show={showToast} delay={15000} autohide={true} onClose={() => setShowToast(false)} style={{ minWidth: "300px" }}>
          <Toast.Header>
            <strong className="me-auto">Сообщение</strong>
            <small>Сейчас</small>
          </Toast.Header>
          <Toast.Body>Выдано: {recipient.firstname + " " + recipient.secondname + " " + recipient.surname}</Toast.Body>
        </Toast>
      </div>
      {content}
    </div></>

  return (<>

    <div className="content-pane dynamic-content dynamic-content-md">
      {show ? <RequestCountInputDialog
        show={show}
        handleCloseRequestCount={handleCloseRequestCount}
        outputstr={outputstr}
        count={count}
        handleCommitRequestCount={handleCommitRequestCount}
        handleChangeInputCount={handleChangeInputCount}
      /> : ''}
      {returnSelectRecipient}
      {errorDialog}
      {render}
    </div>

  </>)

}

export class Issue extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      button_issue_mode: 1,
      basket: [],
      requestInstrument: null,
      outputPanel: {
        name: "",
        model: "",
        vendor: "",
        count: "",
        invent: "",
        type: ""
      },
      instrumentInHand: [],
      showCatalog: false,
      showRecipientSelection: false,
      avatar: '',
      errorMsg: '',
      recipient: {
        firstname: 'ФИО получателя',
        secondname: '',
        surname: '',
        images: []
      },
      showToast: false
    }
  }

  toggleMode = ((val) => {
    this.setState({ button_issue_mode: val });
    PostHttp({
      mode: val
    }, _URL_ISSUE_MODE).then(() => {
      GetHttpParams(_URL_BASKET).then(b => {
        this.setState({
          basket: b,
          outputPanel: {
            name: "",
            model: "",
            vendor: "",
            count: "",
            invent: "",
            type: ""
          },
          avatar: '',
          recipient: {
            firstname: 'ФИО получателя',
            secondname: '',
            surname: '',
            images: []
          }
        })

      })
    });

  })

  componentDidMount() {
    GetHttpParams(_URL_ISSUE + '/mode').then(m => {
      GetHttpParams(_URL_BASKET).then(b => {
        this.setState({
          basket: b,
          button_issue_mode: m.mode ? 2 : 1
        })
      })
    })
  }

  readResponse(r) {
    let image;
    if (r !== '') {
      if (r.init === "ii") {
        r.instrument.images.forEach(v => {
          if (v.defaultFile) {
            image = v
          }
        });
        this.setState(({ requestInstrument: r.instrument, avatar: '' }))
      } else if (r.init === "ri") {
        if (r.instrumentInHands.length > 0) {
          r.instrumentInHands[0].instrument.images.forEach(v => {
            if (v.defaultFile) {
              image = v
            }
          });
        }
        this.setState({ instrumentInHand: r.instrumentInHands, avatar: '' });
      }
      if (r.recipient !== null) {

        //по коду вернулся получатель
        GetHttpParams(_URL_BASKET).then(b => {
          r.recipient.images.forEach((v) => {
            if (v.defaultFile) {
              r.recipient.content = _URL_IMAGE_REPOSITORY + '/?uuid=' + v.uuid + '&type=recipient'
            }
          });
          this.setState((state) => ({
            basket: b,
            recipient: r.recipient,
            showToast: state.basket.length > 0
          }))

        })
      }
      if (image !== undefined) {
        this.setState({
          avatar: _URL_IMAGE_REPOSITORY + "?uuid=" + image.uuid + "&type=instrument"
        })
      }
    }

  }

  handleFindWithcId = (id) => {
    PostHttp({
      id: id,
      issue: this.state.button_issue_mode
    }, _URL_ISSUE + '/findid').then(r => {
      this.readResponse(r);
    })

  }
  handleFindCode = (code) => {
    PostHttp({
      code: code,
      issue: this.state.button_issue_mode
    }, _URL_ISSUE + '/findcode').then(r => {
      this.readResponse(r);

    })

  }

  handleFindCodeForm = (event) => {
    let code = event.target.value;
    if (event.keyCode === 13) {
      this.handleFindCode(code)
      event.target.value = "";
    }

  }

  handleChangeInputCount = (event) => {
    if (event.keyCode === 13) {
      this.handleCommitRequestCount()
    }
    this.setState({ inputCount: event.target.value })
  }

  updateDetailPanel = (instrument) => {
    this.state.outputPanel = {
      name: instrument.name,
      model: instrument.model,
      vendor: instrument.vendor,
      count: instrument.count,
      invent: instrument.invent,
      type: instrument.type
    }
  }

  handleCloseRequestCount = () => {
    this.setState({ requestInstrument: null })
  }
  handleCommitRequestCount = () => {
    let avalable;
    if (this.state.requestInstrument.instrumentCounters != null) {
      avalable = this.state.requestInstrument.instrumentCounters.avalable;
      if (avalable >= this.state.inputCount) {
        PostHttp({
          id: this.state.requestInstrument.id,
          count: this.state.inputCount,
          mode: this.state.button_issue_mode
        }, _URL_BASKET + '/add').then((b) => {
          this.updateBasket()
          this.updateDetailPanel(b.instrument);
          this.handleCloseRequestCount()
        })
      } else {
        alert("Доступно " + avalable + " Вы ввели " + this.state.inputCount)
      }
    } else {
      alert("Доступно 0  Вы ввели " + this.state.inputCount)
    }
  }

  updateBasket() {
    GetHttpParams(_URL_BASKET).then((b) => {
      this.setState({ basket: b })
    })
  }
  /**
   * Переделать api  на Page<T>
   * 
   * @param {*} pageParams 
   */
  // updateBasket(pageParams) {
  //   GetHttpParams(_URL_BASKET,{page:pageParams[0],sort:pageParams[1]}).then((b) => {
  //     this.setState({basket: b})
  //   })
  // }

  handleCloseinstrumentInHand = () => {
    this.updateBasket();
    this.setState({ instrumentInHand: [] })

  }

  handlehideCatalog = () => {
    this.setState({ showCatalog: false })
  }
  handleShowCatalog = () => {
    this.setState({ showCatalog: true });
  }
  handleCloseToast = () => {
    this.setState({
      toast: {
        show: false
      }
    });
  }

  onRecipientSelect = (recipient) => {
    this.setState({ showRecipientSelection: false });
    if (recipient.identificator !== undefined) {
      this.handleFindCode(recipient.identificator.code)
    }
  }

  hanldeClicklBasketItem(instrument) {
    this.updateDetailPanel(instrument);
    this.setState({ avatar: '' })
    instrument.images.forEach(i => {
      if (i.defaultFile) {
        this.setState({
          avatar: _URL_IMAGE_REPOSITORY + "?uuid=" + i.uuid + "&type=instrument"
        })
      }
    });
  }
  handleHideToast = () => {
    this.setState({ showToast: false })
  }
  render() {
    let show = this.state.requestInstrument !== null;
    let outputstr = ""
    let count = "0"

    if (this.state.requestInstrument !== null) {
      outputstr = this.state.requestInstrument.name + " " + this.state.requestInstrument.model + " " + this.state.requestInstrument.vendor;
      if (this.state.button_issue_mode === 1) {
        if (this.state.requestInstrument.instrumentCounters != null) {
          count = this.state.requestInstrument.instrumentCounters.avalable;
        } else {
          count = 0;
        }
      } else if (this.state.button_issue_mode === 2) {
        if (this.state.requestInstrument.instrumentCounters != null) {
          count = this.state.requestInstrument.totalСount - this.state.requestInstrument.instrumentCounters.avalable;
        } else {
          count = 0;
        }
      }

    }

    let errorDialog;
    if (this.state.errorMsg !== '') {
      errorDialog = <ConfirmDialog show={this.state.errorMsg !== ''} title="Ошибка" message={this.state.errorMsg} onHide={() => this.setState({ errorMsg: '' })} onCancel={() => this.setState({ errorMsg: '' })} onCommit={() => this.setState({ errorMsg: '' })} />
    }
    let returnSelectRecipient = <InstrumentInHand show={this.state.instrumentInHand.length !== 0} onClose={this.handleCloseinstrumentInHand} instrumentInHand={this.state.instrumentInHand} updateDetailPanel={this.updateDetailPanel} />
    let content;
    const tableField = [{ name: "#", sort: 'id' }, { name: "Наименование", sort: "name" }, { name: "Производитель", sort: "vendor" },
    { name: "Модель", sort: "model" }, { name: "Кол-во", sort: "count" }, { name: "Удалть", sort: "" }]
    if (!this.state.showCatalog && !this.state.showRecipientSelection) {
      content = <>
        <div className="d-flex my-2 w-100">
          <div className="d-flex mb-3 mx-auto">
            <div className="">
              <IssueModeSwitcher onToggle={(mode) => this.toggleMode(mode)} value={this.state.button_issue_mode} />
            </div>
          </div>
          <div className="mx-auto">
            <Form.Group className='text-center'>
              <h4>Выдача инструмента</h4>
              <FormControl placeholder="ID Инструмента" style={{ maxWidth: '450px' }} onKeyUp={this.handleFindCodeForm} />
            </Form.Group>
          </div>
          <div className="d-flex mx-auto">
            <div>
              <Button className="me-1" style={{ marginTop: '32px' }} onClick={this.handleShowCatalog}>Каталог</Button>
            </div>
            <div>
              <Button style={{ marginTop: '32px' }} onClick={() => this.setState({ showRecipientSelection: true })} disabled={this.state.basket.length == 0}>Получатели</Button>
            </div>
          </div>

        </div>
        <ImagePanel avatar={this.state.avatar}
          outputPanel={this.state.outputPanel}
          recipient={this.state.recipient}

        />
        <div className="text-center">
          <h4 className='ms-auto'>{
            this.state.button_issue_mode === 1
              ? 'ТМЦ на выдачу'
              : 'ТМЦ на возврат'
          }</h4>
        </div>
        <BasketTable
          basket={this.state.basket}
          hanldeClicklBasketItem={(instrument) => this.hanldeClicklBasketItem(instrument)}
          updateBasket={() => this.updateBasket()}
        />
      </>
    }

    if (this.state.showRecipientSelection) {
      content = <RecipientSelection onclose={() => this.setState({ showRecipientSelection: false })} onselect={this.onRecipientSelect} />;
    }
    if (this.state.showCatalog) {
      content =
        <>
          <div className="mt-3">
            <MiniCatalog show={this.state.showCatalog} onHide={this.handlehideCatalog} find={this.handleFindWithcId}>
              {this.state.basket.map((v) => {
                let instrument = v.instrument;
                let instrumentInHand = v.instrumentInHand;
                let value;
                if (instrument == null) {
                  value = instrumentInHand.instrument
                } else {
                  value = instrument
                }

                return (
                  <tr style={{ height: "20px" }}>
                    <td className="text-truncate" style={{ maxWidth: "110px" }}>{value.name}</td>
                    <td className="text-truncate" style={{ maxWidth: "110px" }}>{value.vendor}</td>
                    <td>{value.model}</td>
                    <td>{v.count}</td>
                  </tr>
                )
              })}
            </MiniCatalog>
          </div>
        </>
    }
    const render = <>
      <div className="d-md-none d-lg-block" >
        <div className="position-absolute bottom-0 end-0">
          <Toast show={this.state.showToast} delay={15000} autohide="autohide" onClose={this.handleHideToast} style={{ minWidth: "300px" }}>
            <Toast.Header>
              <strong className="mr-auto">Сообщение</strong>
              <small>Сейчас</small>
            </Toast.Header>
            <Toast.Body>Выдано: {this.state.recipient.firstname + " " + this.state.recipient.secondname + " " + this.state.recipient.surname}</Toast.Body>
          </Toast>
        </div>

        {content}
      </div>
      <div className=" d-none d-md-block d-lg-none ms-1 " >
        <div className="position-absolute bottom-0 end-0">
          <Toast show={this.state.showToast} delay={15000} autohide="autohide" onClose={this.handleHideToast} style={{ minWidth: "300px" }}>
            <Toast.Header>
              <strong className="me-auto">Сообщение</strong>
              <small>Сейчас</small>
            </Toast.Header>
            <Toast.Body>Выдано: {this.state.recipient.firstname + " " + this.state.recipient.secondname + " " + this.state.recipient.surname}</Toast.Body>
          </Toast>
        </div>

        {content}
      </div></>
    return (<>
      <div className="content-pane dynamic-content dynamic-content-md">
        {show ? <RequestCountInputDialog
          show={show}
          handleCloseRequestCount={this.handleCloseRequestCount}
          outputstr={outputstr}
          count={count}
          handleCommitRequestCount={this.handleCommitRequestCount}
          handleChangeInputCount={this.handleChangeInputCount}
        /> : ''}
        {returnSelectRecipient}
        {errorDialog}
        {render}
      </div>


    </>)
  }
}

function RequestCountInputDialog(props) {

  useEffect(() => {

  }, [])

  return (<>
    <Modal show={props.show} onHide={props.handleCloseRequestCount}>
      <Modal.Header closeButton>
        <Modal.Title>Введите Количество</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <p>Инструмент: {props.outputstr}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>Доступно: {props.count}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup>
                <InputGroup.Text id="input-count">Количество</InputGroup.Text>
                <FormControl id="countInput" onChange={props.handleChangeInputCount} />
              </InputGroup>

            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseRequestCount}>
          {templates.button_close}
        </Button>
        <Button variant="primary" onClick={props.handleCommitRequestCount}>
          {templates.button_add}
        </Button>
      </Modal.Footer>
    </Modal>
  </>)
}

export default Issue;
class InstrumentInHand extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      inputCount: 0
    };

  }
  handleChange = (event) => {
    this.setState({ inputCount: event.target.value })
  }
  handleAddToBasket = (event, val) => {
    if (event.target.type === "button") {
      if (this.state.inputCount > 0) {
        if (this.state.inputCount < val.count | this.state.inputCount == val.count) {
          PostHttp({
            count: this.state.inputCount,
            id: val.id,
            mode: 2
          }, _URL_BASKET + '/add').then((b) => {
            this.props.updateDetailPanel(b.instrumentInHand.instrument)
            this.props.onClose()

          })
        } else {
          alert("Недопустимое значение " + this.state.inputCount)
        }
      } else {
        alert("Укажите число болше 0")
      }
    } else {
      event.stopPropagation();
      return false;
    }

  }
  render() {
    return (<Modal show={this.props.show} onHide={this.props.onClose}>
      <Modal.Header closeButton="closeButton">
        <Modal.Title>Выбор получателя</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container >
          <Row className="mt-5">
            <Table striped="striped" bordered="bordered" hover="hover" size="sm" className="mt-3 instrument-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Имя</th>
                  <th>Выдано</th>
                  <th>Вернуть</th>
                  <th>Выбрать</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.props.instrumentInHand.map((v, k) => {

                    return (<tr style={{
                      height: "20px"
                    }}>
                      <td key={k}>{k + 1}</td>
                      <td>{v.recipient.firstname + " " + v.recipient.surname}</td>
                      <td>{v.count}</td>
                      <td style={{
                        width: '40px'
                      }}>
                        <InputGroup >
                          <FormControl size="sm" onChange={this.handleChange} />
                        </InputGroup>

                      </td>
                      <td >
                        <Button size="sm" variant="outline-success" onClick={(e) => this.handleAddToBasket(e, v)}>Выбрать</Button>{' '}

                      </td>
                    </tr>)
                  })
                }
              </tbody>
            </Table>
          </Row>

        </Container >

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onClose}>
          Закрыть
        </Button>

      </Modal.Footer>
    </Modal>)
  }
}
function ImagePanel(props) {

  let fio = props.recipient.firstname + " " + props.recipient.secondname + " " + props.recipient.surname
  const [images, setImages] = useState([]);

  function fetchImages(id) {
    GetHttpParams(_URL_RECIPIENT + "/resourses", { recipient: id })
      .then(res => {
        setImages(res);
      });
  }

  useEffect(() => {
    if (props.recipient.id !== undefined) {
      fetchImages(props.recipient.id);
    }
  }, [props.recipient])

  return (<>
    <div className="mb-5 d-flex w-100 styled-block border p-1">
      <div className="border me-2 p-1">
        <div className='text-center'>
          <Image style={{ maxWidth: "300px", maxHeight: "260px" }} fluid src={props.avatar !== ''
            ? props.avatar
            : image.default}></Image>
        </div>
      </div>
      <div className="border rounded p-3 flex-fill me-2">
        <Table >
          <tbody>
            <tr>
              <td className="w-25"><b>Наименование</b></td>
              <td>{props.outputPanel.name}</td>
            </tr>
            <tr>
              <td><b>Тип</b></td>
              <td>{props.outputPanel.type}</td>
            </tr>
            <tr>
              <td><b>Производитель</b></td>
              <td>{props.outputPanel.vendor}</td>
            </tr>
            <tr>
              <td><b>Mодель</b></td>
              <td>{props.outputPanel.vendor}</td>
            </tr>
            <tr>
              <td><b>Инвентарный №</b></td>
              <td>{props.outputPanel.invent}</td>
            </tr>
          </tbody>
        </Table>


      </div>
      <div className="rounded border p-1 ms-auto">
        <div className='text-center'>
          <Image style={{ maxWidth: "300px", maxHeight: "260px" }} fluid src={images.length > 0 ? 'data:image/png;base64,' + images[0].data : image.default} />
          <div>{
            fio.length > 50
              ? fio.slice(0, 50) + "...."
              : fio
          }
          </div>
        </div>
      </div>
    </div>

  </>)
}


function BasketTable(props) {

  function handleRemoveBasket(event) {
    DeleteHttp(_URL_BASKET, { id: event.target.id }).then(() => {
      props.updateBasket();
    })
  }
  return (<>
    <div className="border shadow p-1">
      <Table striped="striped" bordered="bordered" hover="hover" size="sm" className="mt-3 instrument-table">
        <thead>
          <tr style={{
            textAlign: "center"
          }}>
            <th>#</th>
            <th>Наименование</th>
            <th>Производитель</th>
            <th>Модель</th>
            <th>Кол-во</th>
            <th style={{
              width: "20px"
            }}>Удалить</th>
          </tr>
        </thead>
        <tbody>
          {
            props.basket.map((v, k) => {
              let instrument;
              if (v.instrument == null) {
                instrument = v.instrumentInHand.instrument;
              } else {
                instrument = v.instrument
              }
              return (<tr key={k} style={{
                height: "20px"
              }}>
                <td>{k + 1}</td>
                <td className="text-wrap" style={{
                  cursor: "pointer",
                  color: "blue"
                }} onClick={() => props.hanldeClicklBasketItem(instrument)}>{instrument.name}</td>
                <td>{instrument.vendor}</td>
                <td>{instrument.model}</td>
                <td>{v.count}</td>
                <td>
                  <Button id={v.id} variant="danger" onClick={(event) => handleRemoveBasket(event)}>X</Button>
                </td>
              </tr>)
            })}
        </tbody>
      </Table>
    </div>
  </>)
}