import React, { useContext, useEffect, useReducer, useState } from 'react';

import {
  Alert,
  Row,
  Button,
  Form,
  Table
} from 'react-bootstrap'
import GetHttp, {
  GetHttpParams,
  PostHttp,
  _URL_ACCOUNT,
  GetHttpKeycloak,
  PostHttpKeycloak,
  DeleteHttpKeycloak,
} from '../assets/js/Connectorv2'
import { phoneFormatter, BasicDialog, AccountAvatar, UploadFileForm } from "../component/components.js"
import { AccountContext } from '../App.js';
import { PageLoadMain } from '../component/loader.js';


export function Profile() {


  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  let [message, setMessage] = useState('');
  const [events, setEvents] = useState([]);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showPasswordChange, setPasswordChange] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: "", attributes: { middleName: [], phone: [] } });

  const [profile, update] = useContext(AccountContext);


  useEffect(() => {

    fetch();
    fetchSession();
    fetchAccountEvents();
  }, [profile.id])

  function fetch() {
    GetHttpKeycloak("/account")
      .then((res) => setUserInfo(res));
  }
  function fetchSession() {
    GetHttpKeycloak("/account/sessions/devices")
      .then((res) => setSessions(res));
  }
  // function fetchAccount(){
  //   GetHttpParams(_URL_ACCOUNT).then((p) => {
  //     setProfile(p);

  //   })
  // }
  function fetchAccountEvents() {
    GetHttpParams(_URL_ACCOUNT + "/events")
      .then(res => setEvents(res));
  }
  function handleLogout(id) {
    DeleteHttpKeycloak(`/account/sessions/${id}`)
      .then(() => fetchSession())
  }



  const handleSetPassword = (event) => {
    const id = event.target.id;
    const val = event.target.value;
    switch (id) {
      case 'password':
        setPassword(val)
        break;
      case 'password2':
        setPassword2(val);
        break;
    }
  }

  const handleSendNewPassword = () => {
    PostHttp({ password: password }, _URL_ACCOUNT + "/password")
      .then(() => {
        setPasswordChange(false)
      }
      )
      .catch(err => {
        setMessage(err.data.message);
        setPasswordChange(false)
      })
  }


  if (profile) {
    return (<>
      <div className="content-pane dynamic-content dynamic-content-md">

        <> <div className="content-pane w-100">
          {
            message !== ''
              ? <Alert onClose={() => setMessage("")} dismissible={true} variant='success'>{message}</Alert>
              : ''
          }
          <EditProfileDialog profile={userInfo} show={showEditProfile} onHide={()=>{setShowEditProfile(false);fetch()}} />
          <BasicDialog show={showPasswordChange} onHide={() => setPasswordChange(false)} title="Смена пароля" onCommit={handleSendNewPassword}>
            <div>
              <Form.Group className="mb-3 me-5">
                <Form.Label>Пароль</Form.Label>
                <Form.Control id='password' type="password" onInput={handleSetPassword} isInvalid={password !== password2 || password.length === 0} />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
              <Form.Group className="mb-3 me-5">
                <Form.Label>Пароль повтор</Form.Label>
                <Form.Control id='password2' type="password" onInput={handleSetPassword} isInvalid={password !== password2 || password.length === 0} />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>
            </div>
          </BasicDialog>

          <h4 className='text-center mt-2' > Учётные данные</h4>
          <div className="p-2 border mr-2 d-flex" >
            <div>
              <div className="p-2 me-1">
                <div className="p-1 m-1 text-center" >
                  <AccountAvatar images={profile.images} className="border rounded-circle p-1 mb-3" />
                  <div className='text-center'>
                    <UploadFileForm type="account" id={0} onUploaded={() => update()} />
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5 ">
              <div className='d-flex '>
                <Form.Group className="mb-3 me-5">
                  <Form.Label>ФИО</Form.Label>
                  <p className="fs-4">{userInfo.firstName} {userInfo.lastName} {userInfo.attributes.middleName !== undefined ? userInfo.attributes.middleName[0] : ''}</p>
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>

              </div>
              <div className="d-flex">
                <Form.Group className="mb-3 me-5">
                  <Form.Label>Email</Form.Label>
                  <p className="fs-4">{userInfo.email}</p>
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <Form.Group className="mb-3 me-2">
                  <Form.Label>Телефон</Form.Label>
                  <p className="fs-4">{userInfo.attributes.phone !== undefined ? userInfo.attributes.phone : ''}</p>
                </Form.Group>
              </div>

              <div className="text-center mt-auto d-flex">
                <Button variant='link' onClick={() => setShowEditProfile(true)}>Изменить личные данные</Button>
                <Button variant='link' onClick={() => setPasswordChange(true)}>Изменить пароль</Button>

              </div>
            </div>
          </div>

          <div className="border mt-4 styled-block me-1">
            <Row className="p-4 ">
              <h4 className='text-center ml-auto mr-auto'>Активные сессии</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>os</th>
                    <th>os version</th>
                    <th>device</th>
                    <th>Последняя активность</th>
                  </tr>

                </thead>
                <tbody>
                  {sessions.map((v, i) => {
                    return (<>
                      <tr key={i}>
                        <td>{v.os}</td>
                        <td>{v.osVersion}</td>
                        <td>{v.device}</td>
                        <td>{new Date(v.lastAccess * 1000).toLocaleString()}</td>
                      </tr>
                      {v.sessions.map((v1, i1) => {
                        return (<tr key={i1} >
                          <td colspan="3">{`Session id: ${v1.id} ip: ${v1.ipAddress} Браузер:${v1.browser} `}</td>
                          <td><Button variant='outline-primary' onClick={() => handleLogout(v1.id)}>Выход</Button></td>
                        </tr>)
                      })}
                    </>)
                  })}
                </tbody>
              </Table>
              <h4 className='text-center mt-2' >События</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Тип</th>
                    <th>IP</th>
                    <th>Ошибка</th>
                    <th>Дата</th>
                  </tr>

                </thead>
                <tbody>
                  {events.map((v, i) => {
                    return (<>
                      <tr key={i}>
                        <td>{v.type}</td>
                        <td>{v.ipAddress}</td>
                        <td>{v.error}</td>
                        <td>{new Date(v.time).toLocaleString()}</td>
                      </tr>

                    </>)
                  })}
                </tbody>
              </Table>

            </Row>
          </div>

        </div>
        </>

      </div>

    </>)
  } else {
    return (<PageLoadMain />)
  }
}

function EditProfileDialog({ show, profile, onHide }) {
 
  let handleSave = () => {
    PostHttpKeycloak("account", {
      attributes: {middleName: profileEdited.attributes.middleName,phone:profileEdited.attributes.phone},
      firstName: profileEdited.firstName,
      lastName: profileEdited.lastName,
    }).then(() => {
      onHide()
     
    });
  }

  function reducer(act,val){
    if(val.name==="init"){return val.value;}
    if(val.name.startsWith("middleName") || val.name.startsWith("phone") ){
      act.attributes[`${val.name}`]= val.value;
      console.log( act)
    }else{
      act[val.name]= val.value;
    }
    return act;
  }

  const [profileEdited ,dispatch]= useReducer(reducer,profile);
 
  useEffect(()=>dispatch({name:"init",value:profile}),[profile.id]);

  return (
    <BasicDialog show={show} onHide={() => onHide()} title={"Редактирование профиля"} onCommit={handleSave}>
      <div>
        <Form.Group className="mb-3 me-5">
          <Form.Label>Имя</Form.Label>
          <Form.Control id='firstname' type="text" defaultValue={profileEdited.firstName} onChange={(e)=>dispatch({name:'firstName', value:e.target.value})} />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Form.Group className="mb-3 me-5">
          <Form.Label>Фамилия</Form.Label>
          <Form.Control id='lastName' type="text" defaultValue={profileEdited.lastName}  onChange={(e)=>dispatch({name:'lastName', value:e.target.value})}/>
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Form.Group className="mb-3 md-5">
          <Form.Label>Отчество</Form.Label>
          <Form.Control id='middleName' type="text" defaultValue={profileEdited.attributes.middleName} onChange={(e)=>dispatch({name:'middleName', value:e.target.value})} />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
        <Form.Group className="mb-3 me-2">
          <Form.Label>Телефон</Form.Label>
          <Form.Control type="text" defaultValue={profileEdited.attributes.phone} onChange={(e)=>dispatch({name:'phone', value:e.target.value})} id="phone" />
        </Form.Group>
      </div>
    </BasicDialog>)
}