/**
 *
 */
import React, { useEffect, useState } from 'react';
import {
	Table, Form, Button, Row, Col,Spinner
} from 'react-bootstrap'
import {BasicTable} from '../component/table';
import { DeleteHttp, GetHttpParams, PostHttp, _URL_WRITEOFF, _URL_ISSUE } from "../assets/js/Connectorv2"
import { RequestInputCount, Catalog, ConfirmDialog, DateFilter } from "../component/components"
import * as templates from "../assets/js/UITemplates";
import { ExportComponent } from "../component/components.js"
import { ErrorDialog } from '../component/dialog';
import { PageLoadMain } from '../component/loader.js';

export function Writeoff(props) {
	const [filter, setFilter] = useState({ dateFrom: null, dateTo: null });
	const [showSpinner, setShowSpinner] = useState(false);
	const [writeoff, setWriteoff] = useState({ content: [], totalPage: 0 })
	const [errorMessage, setErrorMessage] = useState(null);
	const [contentid, setContentId] = useState(4);
	const [selectedItem, setSelectedItem] = useState(null);
	

	let handleUpdateListWriteoffContainer = (page) => {
		GetHttpParams(_URL_WRITEOFF + "/continue").then(w => {
			if (w === '') {
				// fetchData([0,'','']);
			} else {
				setSelectedItem(w);
				setContentId(0);
			}
		})

	}
	function fetchData(pageParams) {
		GetHttpParams(_URL_WRITEOFF + "/listcontainer", { page: pageParams[0],sort:pageParams[1], dateFrom: filter.dateFrom, dateTo: filter.dateTo })
			.then((r) => {
				setWriteoff(r);
			})

	}
	useEffect(() => {
		handleUpdateListWriteoffContainer(0);
	}, [])

	let handlefilterByDate = (dateFrom, dateTo) => {
		if (dateFrom !== '' & dateTo !== '') {
			filter.dateFrom = dateFrom + " 00:00:00";
			filter.dateTo = dateTo + " 23:59:00";
		} else {
			filter.dateFrom = null;
			filter.dateTo = null;
		}
		GetHttpParams(_URL_WRITEOFF + "/listcontainer", { page: 0, dateFrom: filter.dateFrom, dateTo: filter.dateTo })
			.then((r) => {

				setWriteoff(r);
			})
	}
	
	let content;
	let tableContent;
	let errorDialog;
	let spinner;
	let button_bar;
	button_bar =
		<div className="d-flex border p-2 mb-3 shadow">
			<div>
				<DateFilter onFilter={handlefilterByDate} show={contentid === 4} />
			</div>
			
		<div className="ms-auto">
				{contentid === 1 ?
					<Button
						variant='success'
						size='sm'
						className="me-2 ms-auto"
						onClick={() => setContentId(3)}
					>
						<i className="bi bi-save mr-1"></i>{templates.button_save}
					</Button>
					: ''}

				{contentid === 4 ?
					<Button size='sm'
						className="ms-auto "
						onClick={() => GetHttpParams(_URL_WRITEOFF + "/add").then((e) => {
							setSelectedItem(e);
							setContentId(0);
						}).catch(error=>setErrorMessage(error.data.reason))}>
						<i className="bi bi-plus-circle me-1"></i>{templates.button_create}
					</Button>
					: ''}
		</div>

		</div>
	const tableFields = [{name:'#',sort:'id'},{name: "Коментарий",sort:'comment'} 
	,{name:"Дата",sort:'timestamp'}, { name:"Сотрудник",sort:''},{name:"Подробно",sort:"" }];
	if (showSpinner) {
		spinner =
			<Row className="justify-content-md-center">
				<PageLoadMain />
			</Row>
	} else {
		errorDialog = <ErrorDialog
			show={errorMessage !== null}
			title="Ошибка списания"
			onHide={() => setErrorMessage(null)}
			onCancel={() => setErrorMessage(null)}
			message={errorMessage}>
				<div>{errorMessage}</div>
				</ErrorDialog>
		switch (contentid) {
			case 0:
				content = <AddInstrumentWriteoff
					onHide={() => { setContentId(4); handleUpdateListWriteoffContainer(0); }}
					writeoffContainer={selectedItem}
				/>
				break
			case 1:
				content = <ViewInstrumentWriteoff
					writeoffContainer={selectedItem}
					onClose={() => { setContentId(4) }}
				/>
				break;
			case 3:

				content = <div className='m-1'>
					<ExportComponent type={1} onExit={() => setContentId(1)} id={selectedItem.id} title='Списание' />
				</div>
				break;
			case 4:
				tableContent =
				writeoff.content.map((v, k) => {

					let comment = '';
					if (v.comment !== null) {
						comment = v.comment;
					}
					return (
							<tr key={k}>
								<td>{k + 1}</td>
								<td >{comment.length > 40 ? comment.slice(0, 40) + "...." : comment}</td>
								<td >{new Date(v.timestamp).toLocaleString()}</td>
								<td >{v.account_str}</td>
								<td>
									<i className="bi bi-card-list" id={v.id} style={{ cursor: "pointer" }} onClick={() => {
										setSelectedItem(v);
										setContentId(1);
									}}></i>
								</td>
							</tr>
						
					)
				})

				content = <>
					{button_bar}
						<BasicTable  fields={tableFields} page={writeoff} onsort={(e)=>fetchData(e)}>
							{tableContent}
						</BasicTable>
					</>
				break;

			default:
				break;
		}
	}
	
	return (<>
		
		
		<div className="content-pane dynamic-content dynamic-content-md">
			{errorDialog}
			{content}
			{spinner}
		</div>
		
	</>)

}

class AddInstrumentWriteoff extends React.Component {

	constructor(props) {
		super(props)

		/** if( this.props.writeoffContainer !== undefined ){ editWr = this.props.writeoffContainer}
		else { editWr = { comment: "", writeoffInstrument: [] }}*/
		this.state = {
			instrumentByCode: '',
			showRequestCount: false,
			writeoffContainer: this.props.writeoffContainer,
			showCatalog: false,
			errorMessage: null
		}
	}
	componentDidMount() {
		GetHttpParams(_URL_WRITEOFF + '/details', { container: this.props.writeoffContainer.id })
			.then(a => {
				this.setState({ writeoffContainer: a });
			})
	}

	handleChange = (event) => {
		let value = event.target.value;
		if (event.target.id === "comment") {
			this.setState((state) => state.writeoffContainer.comment = value)
		}
	}
	handleHideRequestCount = () => {

		this.setState({ showRequestCount: false })
	}
	handleHideErrorMsg = () => this.setState({ errorMessage: null });

	handleDelete = (event) => {
		DeleteHttp(_URL_WRITEOFF, { id: event.target.id })
			.then(() => {
				GetHttpParams(_URL_WRITEOFF + '/details', { container: this.props.writeoffContainer.id })
					.then(a => {
						this.setState({ writeoffContainer: a });
					})
			})
	}

	handleCommitRequestCount = (count) => {

		const newItem = {
			writeoffContainer: this.props.writeoffContainer.id,
			instrument: this.state.instrumentByCode,
			count: count
		}

		if (this.state.instrumentByCode.instrumentCounters !== null &&
			this.state.instrumentByCode.instrumentCounters.avalable >= count) {
			PostHttp(newItem, _URL_WRITEOFF)
				.then(e => {
					GetHttpParams(_URL_WRITEOFF + '/details', { container: this.props.writeoffContainer.id })
						.then(a => {
							this.setState({ writeoffContainer: a });
						})
				})
			this.handleHideRequestCount();
		}


	}

	handleAddWriteoffContainer = () => {
		if (this.state.writeoffContainer.writeoffInstrument.length > 0) {
			GetHttpParams(_URL_WRITEOFF + '/dowriteoff', { container: this.state.writeoffContainer.id })
				.then(() => {
					this.props.onHide();
				}
				).catch((error) => {
					this.setState({ errorMessage: error.data.message })
				})
		}
	}

	handleCodeInput = (event) => {
		if (event.keyCode === 13) {
			PostHttp({ code: event.target.value, mode: 1 }, _URL_ISSUE + '/findcode').then((r) => {
				if (r.init === "ii") {
					this.setState({ instrumentByCode: r.instrument, showRequestCount: true })
				}
			})
			event.target.value = "";
		}

	}
	handleFindWithId = (id) => {

		PostHttp({ requestId: id, mode: 1 }, _URL_ISSUE + '/findid').then((r) => {
			if (r.init === "ii") {
				this.setState({ instrumentByCode: r.instrument, showRequestCount: true })
			}
		})


	}
	handleShowCatalog = () => {
		this.setState({ showCatalog: true })
	}
	handleHideCatalog = () => {
		this.setState({ showCatalog: false })
	}
	handleCancel = () => {
		GetHttpParams(_URL_WRITEOFF + '/cancel')
			.then(() => this.props.onHide())

	}
	render() {
		let content;
		let request;
		let errorDialog;

		request = <RequestInputCount
			show={this.state.showRequestCount}
			onHide={this.handleHideRequestCount}
			string={"Наименование: " + this.state.instrumentByCode.name}
			string1={"Производитель: " + this.state.instrumentByCode.vendor}
			onCommit={this.handleCommitRequestCount}
		/>

		errorDialog = <ConfirmDialog
			show={this.state.errorMessage !== null}
			title="Ошибка списания"
			onHide={this.handleHideErrorMsg}
			onCancel={this.handleHideErrorMsg}
			onCommit={this.handleHideErrorMsg}
			message={this.state.errorMessage}

		/>
		if (!this.state.showCatalog) {
			content =
				<>
					<div>
						<Row>
							<Form.Group as={Col} >
								<Form.Label>Коментарий</Form.Label>
								<Form.Control
									id="comment"
									type="text"
									defaultValue={this.state.writeoffContainer.comment}
									onInput={this.handleChange}
									onBlur={() => {
										PostHttp({
											id: this.state.writeoffContainer.id,
											comment: this.state.writeoffContainer.comment
										}, _URL_WRITEOFF+"/save");

									}}
								/>
							</Form.Group>
						</Row>
						<div className="d-flex align-items-end mb-2">
							<Form.Group >
								<Form.Label>Штрих-код</Form.Label>
								<Form.Control
									placeholder="ID Инструмента"
									id="counterPartyText"
									type="text"
									onKeyUp={this.handleCodeInput}
								/>
							</Form.Group>
							<div className="ms-2">
							<Button  onClick={this.handleShowCatalog}>Каталог</Button>
							</div>
						</div>
						<Table striped bordered hover className="instrument-table" size="sm">
							<thead>
								<tr style={{ textAlign: "center" }}>
									<th>#</th>
									<th>Название</th>
									<th>Модель</th>
									<th>Производитель</th>
									<th>Количество</th>
									<th>Удалить</th>
								</tr>
							</thead>
							<tbody>
								{this.state.writeoffContainer.writeoffInstrument.map((v, k) => {
									return (
										<tr key={k}>
											<td>{k + 1}</td>
											<td>{v.instrument.name}</td>
											<td>{v.instrument.model}</td>
											<td>{v.instrument.vendor}</td>
											<td>{v.count}</td>
											<td><Button id={v.id} onClick={this.handleDelete}>X</Button></td>
										</tr>
									)
								})}
							</tbody>
						</Table>
						<div className="text-center">
							<Button className="me-1" variant="secondary" onClick={this.handleCancel}>{templates.button_cancel}</Button>
							<Button onClick={this.handleAddWriteoffContainer}>{templates.button_doit}</Button>
						</div>
					</div>
				</>
		}
		return (
			<>
				{errorDialog}
				{request}
				{content}
				<Catalog
					show={this.state.showCatalog}
					find={this.handleFindWithId}
					onHide={this.handleHideCatalog}
					writeoffInstrument={this.state.writeoffContainer.writeoffInstrument}


				/>

			</>
		)
	}
}

export default Writeoff;

class ViewInstrumentWriteoff extends React.Component {

	constructor(props) {
		super(props)
		this.state = { writeoffContainer: { comment: '', writeoffInstrument: [], timestimp: new Date() } }
	}

	componentDidMount() {
		GetHttpParams(_URL_WRITEOFF + '/details', { container: this.props.writeoffContainer.id })
			.then(a => {
				this.setState({ writeoffContainer: a });
			})

	}


	formatDate(timestamp) {
		return new Date(timestamp).toLocaleDateString();
	}
	render() {
		let content =
			<div>
				<div className="mb-2 d-flex">
					<Form.Group className="me-2">
						<Form.Label>Дата</Form.Label>
						<Form.Control defaultValue={this.state.writeoffContainer.timestamp} disabled />
					</Form.Group>
					<Form.Group>
						<Form.Label>Коментарий</Form.Label>
						<Form.Control type="text" defaultValue={this.state.writeoffContainer.comment} disabled />
					</Form.Group>
				</div>

				<Table striped bordered hover className="instrument-table" size="sm">

					<thead>
						<tr style={{ textAlign: "center" }}>
							<th>#</th>
							<th>Название</th>
							<th>Модель</th>
							<th>Производитель</th>
							<th>Количество</th>


						</tr>
					</thead>
					<tbody>
						{this.state.writeoffContainer.writeoffInstrument.map((v, k) => {
							return (
								<tr key={k}>
									<td>{k + 1}</td>
									<td>{v.instrument.name}</td>
									<td>{v.instrument.model}</td>
									<td>{v.instrument.vendor}</td>
									<td>{v.count}</td>

								</tr>
							)
						})}
					</tbody>

				</Table>

				<div className="text-center">
					<Button className="" variant="secondary" onClick={this.props.onClose}>{templates.button_close}</Button>
				</div>
			</div>

		return (
			<>
				{content}
			</>
		)
	}

}
