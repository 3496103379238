
import React, { useEffect, useState } from 'react';
import * as templates from "../assets/js/UITemplates";
import { Form, Modal,Button,Table,ButtonGroup,ListGroup,Badge } from 'react-bootstrap'
import { SendFile, _URL_IMAGE_REPOSITORY, GetHttpParams, _URL_SETAVATAR,
	DeleteHttp, _URL_FILE_RESOURCE, _URL_DOCUMENT, PostHttp, host,_URL_CATEGORY,
	_URL_INSTRUMENT_LIST,_URL_LOGGING} from '../assets/js/Connectorv2';
import {ConfirmDialog} from './components'    
export function Import(){
	const [name,setName] = useState("0");
	const [model,setModel] = useState("1");
	const [vendor,setVendor] = useState("2");
	const [type,setType] = useState("3");
	const [serial,setSerial] = useState("4");
	const [invent,setInvent] = useState("5");
	const [count,setCount] = useState("6");
	const [fileResource,setFileResoure]=useState([])
	const [selectedImport,setSelectedImport] = useState(undefined);
	const [message,setMessage] = useState({message: undefined});
	const [preview,setPreview] = useState([])
	const [startRow,setStartRow] = useState("1");
	
	useEffect( ()=> {
		GetHttpParams(_URL_FILE_RESOURCE+"/imports")
		.then( (response)=> {
		  setFileResoure(response);
		})
	},[])
  
	function isMatchNumber(string){
	  return string !== "" && string.match("^[0-9]*$")!== null;
	}
	const onPreview=(file)=>{
	  PostHttp({
		uuid:file.uuid,
		startRow: startRow,
		name: name,
		model:model,
		vendor:vendor,
		type:type,
		serial:serial,
		invent: invent,
		count:count
	  },_URL_FILE_RESOURCE+"/imports/preview")
	  .then((response)=> {
		setPreview(response);
	  }).catch(err => { setMessage({message:templates.argument_exception})})
	}
	const uploadFile= (event)=> {
	  SendFile(event.target.files[0],"import","0")
	  .then(()=> 
	  GetHttpParams(_URL_FILE_RESOURCE+"/imports")
		.then( (response)=> {
		  setFileResoure(response);
		}) ).catch(err => { setMessage(err)})
	}
	const onDeleteFile = (file) =>{
	  DeleteHttp(_URL_FILE_RESOURCE+"/",{id:0,uuid:file.uuid,type:'import'}).then(()=>{
		GetHttpParams(_URL_FILE_RESOURCE+"/imports")
		.then( (response)=> {
		  setFileResoure(response);
		})
	  }).catch(err => { setMessage(err)})
	}
	const onImport = () => {
	  if(selectedImport !== undefined ){
		PostHttp({
			uuid:selectedImport.uuid,
		    startRow: startRow,
		  	name: name,
			model:model,
			vendor:vendor,
			type:type,
			serial:serial,
			invent: invent,
			count:count
		},_URL_FILE_RESOURCE+"/imports/onimport")
		.then(()=> {
		  GetHttpParams(_URL_FILE_RESOURCE+"/imports")
		.then( (response)=> {
		  setFileResoure(response);
		})
		  setSelectedImport(undefined);
		}).catch(err => { setMessage({message:templates.argument_exception})})
	  }
	}
	let uploaded;
	if(fileResource.length>0){
	  uploaded=<>
	  <div className="d-flex my-2 ">
		{fileResource.map((e) => {
		  
		 return <div>
           <ButtonGroup>
			<Button disabled={e.locked || !isMatchNumber(name)} onClick={()=>setSelectedImport(e)} variant="outline-secondary" className="ms-2">Импорт {new Date(e.created).toLocaleString()} </Button>
			<Button disabled={e.locked} onClick={()=>onPreview(e)} variant="outline-success" className="" >
                <i className="bi bi-receipt-cutoff"></i>
            </Button>
            <a href={host + '/api/files?uuid=' + e.uuid + '&type=import'} className="btn btn-outline-success"><i className="bi bi-cloud-download"></i></a>
			<Button disabled={e.locked} onClick={()=>onDeleteFile(e)} variant="outline-danger" className="me-2">X</Button>
		   </ButtonGroup>
		 </div>
		})}
	  </div>
	  </>
	}
	return(
	<>
	<ConfirmDialog 
	  title="Подтверждение"
	  message={"Нажмите \'"+ templates.button_accept+"\' для начала импорта"}
	  show={selectedImport!== undefined}
	  onCancel={()=> setSelectedImport(undefined)}
	  onHide={()=> setSelectedImport(undefined)}
	  onCommit={onImport}
	  />
	<ConfirmDialog titel="Сообщение" show={message.message!== undefined} 
	  onCancel={()=>setMessage({message:undefined})} 
	  onHide={()=>setMessage({message:undefined})} 
	  onCommit={()=>setMessage({message:undefined})} message={message.message!== undefined  ? message.message : ""}/>
	<Modal show={preview.length!==0} onHide={()=>setPreview([])} fullscreen={true}>
		  <Modal.Header closeButton>
			<Modal.Title>Предварительный просмотр - первые 5 инструментов с каждого листа</Modal.Title>
		  </Modal.Header>
		  <Modal.Body>        
		  <Table>
			<thead>
			  <tr>
				<th>Наименование</th>
				<th className={model ===""? "visually-hidden":""}>Модель</th>
				<th className={vendor ===""? "visually-hidden":""}>Производитель</th>
				<th className={type ===""? "visually-hidden":""}>Тип</th>
				<th className={serial ===""? "visually-hidden":""}>Серийный</th>
				<th className={invent ===""? "visually-hidden":""}>Инвентарный</th>
				<th className={count ===""? "visually-hidden":""}>Кол-во</th>
				<th>Категория</th>
			  </tr>
			</thead>
			<tbody>
			  {preview.map( (inst ) => {
				return(
				  <tr>
					<td>{inst.name}</td>
					<td className={model ===""? "visually-hidden":""}>{inst.model}</td>
					<td className={vendor ===""? "visually-hidden":""}>{inst.vendor}</td>
					<td className={type ===""? "visually-hidden":""}>{inst.type}</td>
					<td className={serial ===""? "visually-hidden":""}>{inst.serial}</td>
					<td className={invent ===""? "visually-hidden":""}>{inst.invent}</td>
					<td className={count ===""? "visually-hidden":""}>{inst.totalСount.toString()}</td>
					<td >{inst.instrumentCategory.name}</td>
				</tr>
				)
			  })}
			  
			</tbody>
		  </Table>
		  </Modal.Body>
		  <Modal.Footer>
			<Button variant="secondary" onClick={()=>setPreview([])}>
			  {templates.button_close}
			</Button>          
		  </Modal.Footer>
		</Modal>
	<div className='text-center'><h3>Импорт инструмента</h3></div>
	<div className="styled-block p-2 border shadow ">
	<div className="d-flex mb-3 align-items-end">
	  <div>
		<Form.Group className=" p-1" >
		<Form.Label>Начать со строки</Form.Label>
		<Form.Control type="number"  defaultValue={startRow} onInput={(event)=> setStartRow(event.target.value)}/>
		  <Form.Text className="text-muted">
  
		  </Form.Text>
		</Form.Group>
	  </div>
	</div>
	<div>
	  <div>
		Укажите номер столбца, для импорта:
	  </div>
	  </div>
	  <div className="d-flex mb-3 align-items-end" style={{maxWidth:"75%"}}>
		<div>
		  <Form.Group className=" p-1" >
			<Form.Label>Наименование</Form.Label>
			<Form.Control type="text"  isValid={isMatchNumber(name)} isInvalid={!isMatchNumber(name)} defaultValue={name} onInput={(event)=> setName(event.target.value)}/>          
		  </Form.Group>
		</div>
		<div>
		  <Form.Group className="p-1">
		  <Form.Label>Модель</Form.Label>
		  <Form.Control type="text"   isValid={isMatchNumber(model)} defaultValue={model} onInput={(event)=> setModel(event.target.value)}/>
		  </Form.Group>
		</div>
		<div>
		  <Form.Group className=" p-1" >
		  <Form.Label>Производитель</Form.Label>
		  <Form.Control type="text" isValid={isMatchNumber(vendor)} defaultValue={vendor} onInput={(event)=> setVendor(event.target.value)}/>
			<Form.Text className="text-muted">
  
			</Form.Text>
		  </Form.Group>
		</div>
		<div>
		  <Form.Group className=" p-1" >
		  <Form.Label>Тип</Form.Label>
		  <Form.Control type="text" isValid={isMatchNumber(type)} defaultValue={type} onInput={(event)=> setType(event.target.value)}/>
			<Form.Text className="text-muted">
  
			</Form.Text>
		  </Form.Group>
		</div>
		<div>
		  <Form.Group className="p-1" >
		  <Form.Label>Серийный №</Form.Label>
		  <Form.Control type="text"  isValid={isMatchNumber(serial)} defaultValue={serial} onInput={(event)=> setSerial(event.target.value)}/>
			<Form.Text className="text-muted">
  
			</Form.Text>
		  </Form.Group>
		</div>
		<div>
		  <Form.Group className="p-1" >
		  <Form.Label>Инвентарный</Form.Label>
		  <Form.Control type="text"  isValid={isMatchNumber(invent)} defaultValue={invent} onInput={(event)=> setInvent(event.target.value)}/>
			<Form.Text className="text-muted">
  
			</Form.Text>
		  </Form.Group>
		</div>
		<div>
		  <Form.Group className="p-1" >
		  <Form.Label>Кол-во</Form.Label>
		  <Form.Control type="text"  isValid={isMatchNumber(count)} defaultValue={count} onInput={(event)=> setCount(event.target.value)}/>
			<Form.Text className="text-muted">
			</Form.Text>
		  </Form.Group>
		</div>
	  </div>
	  <div>
	  <Form.Text className="text-muted">
		* Пустое поле для пропуска
	  </Form.Text>
	  </div>
	  {uploaded}
	  <div>
		<Form.Group className="my-3">
		  <Form.Label>Файл импорта</Form.Label>
		  <Form.Control type="file" placeholder="Выбирите файл" onChange={uploadFile} accept=".xlsx"/>
		  <Form.Text className="text-muted">
			Файл в формате xlsx
		  </Form.Text>
		</Form.Group>
	  </div>
     
	</div>
	</>
  )
  }
  
  function LogEntry(props) {

    const [logs,setLogs] = useState({content :[]})
    const fetch = () => {
        GetHttpParams(_URL_LOGGING,{ logtype:'imprt' })
        .then((response)=> setLogs(response));
    }
    useEffect( ()=> {
        fetch();
        const inter = setInterval(fetch,20000)
        return ()=>clearInterval(inter);
    },[]);

    return (
      <ListGroup as="ol" numbered>
        {logs.content.map((v)=> {
            return(
            <ListGroup.Item
            as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
            <div className="fw-bold">{v.owner.firstName + " "+ v.owner.surnameName}</div>
            {v.message}
            </div>
            <Badge bg="primary" pill>
            {new Date(v.time).toLocaleString()}
            </Badge>
            </ListGroup.Item>)
        })}
        
        
      </ListGroup>
    );
  }