import React from 'react';
import {Modal,Button} from 'react-bootstrap'
import * as templates from "../assets/js/UITemplates";

export function ErrorDialog (props){

	return(
	<>
		<Modal show={props.show} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.children}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={props.onCancel}>
						{templates.button_close}
					</Button>
					
				</Modal.Footer>
			</Modal>
	</>)
}


