
import * as templates from "../../assets/js/UITemplates";
import * as image from "../../assets/images/default.png";
import React from 'react';
import {
    Button,
    Image
  } from 'react-bootstrap'
import { _URL_IMAGE_REPOSITORY } from "../../assets/js/Connectorv2";

export function CatalogItem(props){
    const item = props.item;
    
    return(<div className="p-2 border shadow mb-2" style={{ borderRadius: "10px", backgroundColor: templates.block_background_color }} >
    <div className='d-flex'>
      <div className="my-auto mx-2">
        <Image className="border " rounded src={item.images.length > 0 ? _URL_IMAGE_REPOSITORY + "?uuid=" + item.images[0].uuid + 
          "&type=category"
          : image.default} width='140' height='96'></Image>
      </div>
      <div className="my-auto mx-2">
        <div><h5 style={{ cursor: 'pointer' }} onClick={props.onNextCategory}>{item.name}</h5></div>
        <div>Элементов: {item.childrenElementCounter}</div>
        <div>Тип: {item.onlyInstrument ? "Группа с инструментами" : "Категория"}</div>
      </div>
      <div className="my-auto ms-auto d-block justify-content-end">
        <div className="text-end">
          <Button variant="outline-primary" className="mt-3 mb-3" onClick={props.setEditCategory}>Редактировать</Button>
        </div>
        <div className="text-end">
          {item.childrenElementCounter > 0 ? <Button disabled variant="outline-secondary" >Удалить</Button> : <Button variant="outline-danger" onClick={props.handleDelete}>Удалить</Button>}
        </div>
      </div>
    </div>
  </div>)
}

export function ButtonPanelInstrument(props) {
    return (
      <div className="border border-success rounded p-2 text-nowrap mx-2" style={{ backgroundColor: templates.block_background_color }}>
        <div className="d-block">
          <div className='p-1'>
            <Button variant="success" className="mb-1 w-100" onClick={props.onShowAddInstrument}>
              <i className="bi bi-plus-circle me-1"></i>{templates.button_add_instrument}
            </Button>
          </div>
          <div className='p-1'>
            <Button variant="success" className="w-100" onClick={props.backCategoryClick}><i className="bi bi-arrow-left-square me-1"></i>{templates.button_back} </Button>
          </div>
  
        </div>
      </div>
    )
  }

  export function ButtonPanelCategory(props) {
    const response = props.response;
    return (<>
      <div>
        <div className="border border-success rounded  text-nowrap p-2 mx-2" style={{ backgroundColor: templates.block_background_color }}>
          <div className="d-block">
            <div className='p-1'>
              <Button variant="success" className="mb-1 w-100" onClick={() => { props.showCategoryCreator(true) }}>
                <i className="bi bi-plus-circle me-1"></i>{templates.button_add_category}
              </Button>
            </div>
            {response.categories.totalElements === 0 & props.path[props.path.length-1] !== -1?
              <div className='me-auto p-1'><Button variant="success" className="mb-1 w-100" onClick={() => props.setContent(3)}><i className="bi bi-plus-circle me-1"></i>{templates.button_add_instrument} </Button></div>
              : ''}
            {props.path.length > 1 ? <div className='ms-auto p-1'>
              <Button variant="success" className="w-100" onClick={props.onBackCategoryClick}><i className="bi bi-arrow-left-square me-1"></i>{templates.button_back} </Button></div> : ''}
          </div>
  
          {props.path.length === 1 ?
            <div style={{ backgroundColor: templates.block_background_color }}>
              <div className="d-block">
                <div className='me-auto p-1'>
                  <Button className="w-100" onClick={props.onInventory}><i className="bi bi-arrow-left-square me-1"></i>{templates.button_inventory} </Button>
                </div>
              </div>
            </div> : ''}
        </div>
      </div>
    </>)
  }