import React, {
  Children,
  createContext,
  useEffect,
  useState
} from 'react';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  contex,
  GetHttpParams,
  PostHttp,
  _URL_ACCOUNT,
  oidcConfig
} from './assets/js/Connectorv2';
import Login from './page/signin';
import {
  InputGroup,
  Nav,
  Button,
  FormControl,
  Spinner,
  Row,
  Col,
  Container,Card
} from 'react-bootstrap';
import {
  useLocation,
  createBrowserRouter,
  RouterProvider,
  useNavigate
} from 'react-router-dom';
import { IssueV2 } from './page/main';
import Instrument from './page/instrument';
import Recipient from './page/recipient';
import Report from './page/report';
import Arrival from './page/arrival ';
import Transfer from './page/transfer';
import Writeoff from './page/writeoff';
import Registration from './page/registration';
import SearchResult from './page/searchResult';
import * as templates from "./assets/js/UITemplates";
import {Profile} from './page/account';
import { AdminPanel } from './page/admin';
import {
  MainDropdownMenu,
  Search
} from "./component/components";
import { MainMenu,SelectCustomer, WirehouseSelection } from './app/components';
import { PageLoadMain } from './component/loader';
import { AuthProvider, useAuth } from 'react-oidc-context';



function  ErrorPage(){

  return(<>
  <Container>
    <Row className='vh-100'>
      <div className='text-center my-auto'>
        <div className='h5'> Произошла ошибка или неверный адрес </div>
        <a href="/"> Главная страница </a>
      </div>
    </Row>
  </Container>
  </>)
  
}

function AppRouter(){
    
  const router = createBrowserRouter([
    {
      path:contex + '/signin',
      element: <AuthProvider {...oidcConfig} > <Login/></AuthProvider>
    },
    {
      path: contex + '/registration',
      element:<Registration/>
    },
    {
      path:contex + '/',
      element:<MainLayout ><IssueV2/></MainLayout>,
      errorElement:<ErrorPage/>
    },{
      path:contex + '/main',
      element:<MainLayout ><IssueV2/></MainLayout>
    },{
      path:contex + '/instrument',errorElement:<ErrorPage/>,
      element:<MainLayout ><Instrument/></MainLayout>
    },      
    {
      path:contex + '/recipient',errorElement:<ErrorPage/>,
      element:<MainLayout ><Recipient/></MainLayout>
    },{
      path:contex + '/arrival',errorElement:<ErrorPage/>,
      element:<MainLayout ><Arrival/></MainLayout>
    },{
      path:contex + '/transfer',errorElement:<ErrorPage/>,
      element:<MainLayout ><Transfer/></MainLayout>
    },{
      path:contex + '/writeoff',errorElement:<ErrorPage/>,
      element:<MainLayout ><Writeoff/></MainLayout>
    },{
      path:contex + '/report',errorElement:<ErrorPage/>,
      element:<MainLayout ><Report/></MainLayout>
    },{
      path:contex + '/profile',errorElement:<ErrorPage/>,
      element:<MainLayout ><Profile/></MainLayout>
    },{
      path:contex + '/search',errorElement:<ErrorPage/>,
      element:<MainLayout ><SearchResult /></MainLayout>
    },{
      path:contex + '/adminpanel',errorElement:<ErrorPage/>,
      element:<MainLayout ><AdminPanel /></MainLayout>
    },{
      path:"*",
      element:<MainLayout><NoFound/></MainLayout>
    }

  ])
  
  
    return( 
      <RouterProvider router={router}/>
    
    )
  
  
}

export const AccountContext = createContext(null);

function App() {
  
 
  return (<>
  
      <AppRouter/>    
  
  </>)
  }



export default App;

function MainLayout({children}){
  const [account,setAccount] = useState({name: '',instrumentContainer:''});
  const [customer,setCustomer] =useState({name: ''});
  const [avalableCustomers,setAvalableCustomers] = useState([]);
  const [showLoader,setShowLoader] = useState(true);
  const [showCustomerDialog,setShowCustomerDialog] = useState(false);
  const [showNoDefaultCustomerDialog,setShowNoDefaultCustomerDialog] = useState('');

  let navigate = useNavigate();

  function fetchAccount(){
    GetHttpParams(_URL_ACCOUNT)
    .then((acc) => {
      setAccount(acc);
    })
  }

    useEffect(()=>{
      fetchAccount();
          GetHttpParams(_URL_ACCOUNT + '/customer')
            .then((cust) => {
              if (cust === '') {
                setShowNoDefaultCustomerDialog('exception')
              } else {
                if (cust.customerState !== "normal") {
                  setShowNoDefaultCustomerDialog('exception')
                }
                setCustomer(cust);
              }
              setShowLoader(false);
            });
      
  },[])

  const onSearch=(e)=>{
    navigate("/search",{state:{search:e}})

  }
  const handleShowSelectCustomer = () => {
    GetHttpParams(_URL_ACCOUNT + '/customers')
      .then((c) => {
        setAvalableCustomers(c);
        setShowCustomerDialog(true);
      })
  }
  
  const handleSetCustomer = (customerToken) => {
    if(customer.token !== customerToken ){
    PostHttp({
        customer_token: customerToken
      }, _URL_ACCOUNT + "/customer")
      .then(() => handleLogout());
    }
    setShowCustomerDialog(false);
  }

  const handleLogout = () => {
    navigate("/signin");
  }
 
  
  const handleActiveMenu = (event) => {
    const menuItems = document.getElementsByClassName("menu-item d-lg-flex d-none menu-active");
    for (let i = 0; i < menuItems.length; i++) {
      menuItems[i].className = "menu-item d-lg-flex d-none";
    }
    let element ;
    if (event.target.tagName === "A" ) {
      element = event.target ;
      element.className = "menu-item d-lg-flex d-none menu-active";

    }else {
      
      event.stopPropagation();
    } //event.target.className="menu-item d-lg-flex d-none active"
  }

  let customer_change;
  const spinner = <Spinner animation="border" role="status" />

    
    if (customer.name === "") {
      customer_change = spinner
    } else {
      customer_change = <Nav.Link  eventKey="1" onClick={handleShowSelectCustomer}>{customer.name}</Nav.Link>
    }

    let top_bar =
      <>
				<div className='d-flex position-fixed top-0 border-bottom bg-white p-2 top-bar align-items-center' >
					<Col className="d-none d-lg-block ms-1">
            <a className='text-decoration-none text-dark' href={"https://"+templates.project_title} target="_blank">
              <h4 className='p-0 m-0'>Складские Системы</h4>
            </a></Col>
					<Row >
						<Col>
							<Search onSearch={onSearch} />
						</Col  >
						<Col md="auto" >
							<Nav.Item>
								<div className='d-flex text-primary text-start mt-1 '><i className="bi bi-display me-2"></i>{customer_change}</div>
							</Nav.Item>
						</Col  >
						<Col md="auto" >
							<Nav.Item>
              <div className="d-flex text-primary text-start mt-1"><i className="bi bi-box-seam me-2"></i>
                <WirehouseSelection account={account} show={false}/>
              </div>
							</Nav.Item>
						</Col>
						<Col md="auto">
							<MainDropdownMenu account={account} onLogout={handleLogout}/>
						</Col>
					</Row>
				</div>
        
			</>
  
  if(showLoader){
   return (
          <div >
            <PageLoadMain /> 
          </div>)
  }else {
      return(
        <>     <AuthProvider {...oidcConfig} > 
              <Authorized >
                {showNoDefaultCustomerDialog !==''? <NoCustomerSelected message={showNoDefaultCustomerDialog}/> : <>
                  {top_bar}         
                    <MainMenu handleActiveMenu={(event)=>handleActiveMenu(event)}/>
                  {showCustomerDialog ? 
                    <SelectCustomer show={showCustomerDialog} avlCustomers={avalableCustomers} handleSetCustomer={handleSetCustomer} handleHide={()=>setShowCustomerDialog(false)}/>:''}
                    <AccountContext.Provider value={[account,()=>fetchAccount()] }>
                      {children}                
                    </AccountContext.Provider>
                  </>}
                </Authorized>
                </AuthProvider>
            </>
          
      )
  }
}

function Authorized({children}){
  const auth = useAuth();
  if(auth.isAuthenticated){
    return <>{children}</>
  }else if(auth.isLoading){
    return(<PageLoadMain />)
  }else {
    
  }
}

function NoFound() {
  let location = useLocation();
  return (
    <Container className="d-lg-block d-none content-pane">
			<div className='text-center'>
				<h4 className='mt-5'>Страница {location.pathname} не найдена!</h4>
			</div>
		</Container>
  )
}

function NoCustomerSelected(){

  const [customersList,setCustomersList] = useState([]);
  const [selected,setSelected] = useState("-1");

  useEffect( ()=>{
    GetHttpParams(_URL_ACCOUNT + '/customers')
      .then((c) => {
        setCustomersList(c);
        
      })
  },[])

  const handlecommit= () =>{
    if(selected!=="-1"){
      PostHttp({
        token: selected
      }, _URL_ACCOUNT + "/customer")
      .then( ()=>document.location.href="/signin" )
    }
  }
  
  return(<>
    <Container className='w-50 mt-3'>
      <Card className="text-center">
        <Card.Header></Card.Header>
        <Card.Body>
          <Card.Title>Необходимо выбрать организацию для входа</Card.Title>
          <Card.Body>
            <InputGroup className="mb-3">
                  <InputGroup.Text id="customer">Организация</InputGroup.Text>
                <FormControl
                  as="select"
                  placeholder="customer"
                  aria-label="customer"
                  aria-describedby="customer"
                  onChange={(e)=>setSelected(e.target.value)}
                >
                  <option selected value="-1">Выбрать</option>
                  {customersList.map((e)=>
                    <option value={e.token}>{e.name}</option>  
                  )}
                </FormControl>
              </InputGroup>
          </Card.Body>
          <Button variant="primary" onClick={handlecommit}>Подтвердить</Button>
        </Card.Body>
        
      </Card>
      </Container>
 </>)
}