import React, { useEffect, useState } from 'react';
import {PaginationComponent} from '../component/components';
import {BasicTable} from '../component/table';
import {
	Button,	Table, Image,Spinner
} from 'react-bootstrap'
import * as templates from "../assets/js/UITemplates";
import {
	GetHttpParams, _URL_SETAVATAR,
	_URL_FILE_RESOURCE, _URL_DOCUMENT, _URL_CATEGORY,
	_URL_INSTRUMENT_LIST,_URL_IMAGE_REPOSITORY,
	_URL_RECIPIENT
} from '../assets/js/Connectorv2';
import * as image from "../assets/images/default.png";
import { PageLoadMain } from './loader';

export function MiniCatalog({onHide,show,children,find}) {

	
	let [instrument, setInstrument] = useState({ content: [] });
	let [currentPageCategory, setCurrentPageCategory] = useState(0);
	let [showSpinner, setShowSpinner] = useState(true);
	let [category, setCategory] = useState({ categories: { content: [] }, onlyInstrument: false });
	let [nav] = useState([-1]);

	useEffect(() => {
		GetHttpParams(_URL_CATEGORY + '/list', { page: nav[0] })
			.then((cat) => {
				setCategory(cat);
				setShowSpinner(false);
			})
	}, [])

	function showNextCategory(event) {
		let parent_cat = parseInt(event.target.id);
		nav.push(parent_cat);
		GetHttpParams(_URL_CATEGORY + '/list', { page: "0", parent_category: parent_cat })
			.then((cat) => {
				if (!cat.onlyInstrument) {
					setCategory(cat);
					setInstrument({ content: [] });
					setShowSpinner(false);

				} else {
					GetHttpParams(_URL_INSTRUMENT_LIST, { page: 0, parent_category: parent_cat })
						.then((ins) => {
							setCategory({ categories: { content: [] }, onlyInstrument: true });
							setShowSpinner(false);
							setInstrument(ins);
						})
				}
			})
	}
	let tableState = [];
	function fetchInstrument(pageParams,parent_cat){
		tableState = pageParams;
		GetHttpParams(_URL_INSTRUMENT_LIST, { page: pageParams[0],sort:pageParams[1], parent_category: parent_cat })
						.then((ins) => {
							setInstrument(ins);
						})
	}
	function onPageCatClick(page) {

		GetHttpParams(_URL_CATEGORY + '/list', { page: page, parent_category: nav[nav.length - 1] })
			.then((cat) => {
				if (!cat.onlyInstrument) {
					setCategory(cat);
					setInstrument({ content: [] });
					setShowSpinner(false);
					setCurrentPageCategory(page);

				} else {
					GetHttpParams(_URL_INSTRUMENT_LIST, { parent_category: nav[nav.length - 1], page: page })
						.then((ins) => {
							setCategory({ categories: { content: [] }, onlyInstrument: true });
							setInstrument(ins);
							setShowSpinner(false);

						})

				}

			})
	}
	function showPrevCategory(event) {
		let parent_cat = parseInt(event.target.id);
		nav.pop()
		GetHttpParams(_URL_CATEGORY + '/list', { page: 0, parent_category: parent_cat })
			.then((cat) => {
				if (!cat.onlyInstrument) {
					setCategory(cat);
					setInstrument({ content: [] });
					setShowSpinner(false);

				} else {
					GetHttpParams(_URL_INSTRUMENT_LIST, { parent_category: parent_cat, page: 0 })
						.then((ins) => {
							setCategory({ categories: { content: [] }, onlyInstrument: true });
							setInstrument(ins);
							setShowSpinner(false);
						})
				}
			})
	}


	function handleHide() {
		nav = [-1];
		GetHttpParams(_URL_CATEGORY + '/list', { page: 0, parent_category: nav[0] })
			.then((cat) => {
				setCategory(cat);
				setInstrument({ content: [] });
				setShowSpinner(false);
			})
		onHide();
	}
	let table;
	let paggination;
	let c = category;
	let spinner = null;
	
	
	const tableFields = [{name:"Нa-ние",sort:"name"},{name:"Пр-тель",sort:"vendor"},
			{name:"Модель",sort:"model"},{name:"Остаток/Всего",sort:"totalСount"}]
	

	function handleClickInstrument(id){
		find(id); 
		fetchInstrument(tableState,nav[nav.length - 1])
	}


	if (!c.onlyInstrument) {
		table = <Table striped className="text-center p-2" size="sm">
			<tbody>
				{c.categories.content.map((e, key) => {
					let img;
					for (let i = 0; i < e.images.length; i++) {
						if (e.images[i].defaultFile) {
							img = e.images[i];
							break;
						}
					}

					return (<tr key={key}>
						<td style={{ width: "10px" }}>
							<Image src={img !== undefined ?
								_URL_IMAGE_REPOSITORY + "?uuid=" + img.uuid + 
								"&type=category"
								: image.default} width='64' height='38'></Image>
						</td>
						<td><Button variant="link" id={e.id} onClick={(e)=>showNextCategory(e)}>{e.name}</Button></td>


					</tr>)
				})}
			</tbody></Table>

		paggination = <PaginationComponent page={c.categories} pageActive={currentPageCategory} toPage={(e)=>onPageCatClick(e)} />

	} else if (c.onlyInstrument) {
		table = <BasicTable fields={tableFields} page={instrument} onsort={(p)=>{fetchInstrument(p,nav[nav.length - 1])}}>
			{instrument.content.map((e, key) => {
				let stat;
				if (e.instrumentCounters != null) {
					stat = e.instrumentCounters.avalable + "/" + e.totalСount;
				} else {
					stat = "0/" + e.totalСount;
				}
				return (
					<tr key={key} onClick={() => handleClickInstrument(e.id)} style={{ cursor: "pointer" }}>
						<td className="text-truncate" style={{ maxWidth: "110px" }}>{e.name}</td>
						<td className="text-truncate" style={{ maxWidth: "110px" }}>{e.vendor}</td>
						<td className="text-truncate" style={{ maxWidth: "110px" }}>{e.model}</td>
						<td>{stat}</td>

					</tr>)
			})}
			</BasicTable>
		

		
	} else {
		table = <div style={{ textAlign: "center" }}>Ничего не найдено :(</div>

	}

	if (showSpinner) {
		spinner =
			<PageLoadMain />
	}

	if (show) {
		return (<div className="w-100 ms-2 mb-2">
			<div className="styled-block p-2 shadow border">
				<div className="text-center"><h2>Каталог</h2></div>
				<div className="d-flex">
					<div className="catalog-basket border mx-2 p-2" style={{ width: "100%" }}>
						<div><h5 className='text-center'>Склад</h5></div>
							{table}
						{spinner}
						<div className='text-center mt-1'>
							{paggination}
						</div>
						<div className="text-center">
							{nav.length !== 1
							?<Button id={nav[nav.length - 2]} onClick={(e)=>showPrevCategory(e)} >Назад</Button>:''}
							
						</div>
					</div >
					<div className="catalog-basket border me-2 p-2" style={{ width: "100%" }}>
						<div><h5 className='text-center'>В Корзине</h5></div>
						<div style={{ overflow: "auto" }}>
							<Table striped bordered hover className="instrument-table" size="sm">
								<thead>
									<tr style={{ textAlign: "center" }}>
										<th>Наименование</th>
										<th>Производитель</th>
										<th>Модель</th>
										<th>Кол-во</th>
									</tr>
								</thead>
								<tbody>
									{children}
								</tbody>
							</Table>
						</div>
					</div>
				</div>
				<div className="text-center my-2">
					<Button onClick={()=>handleHide()}>{templates.button_close}</Button>
				</div>
			</div>
		</div>)

		
	}
}

export function RecipientSelection(props) {
	const id_max_len = 30;
	const [recipients,setRecipients] = useState({ content: [] });
	const tableFields = [{ name: "#", sort: "id" }, { name: "ФИО", sort: "surname" },
    { name: "ID получателя", sort: "identificator_id" }, { name: "Телефон", sort: "phone" },
    { name: "Должность", sort: "function" }]

	function fetch(onPageParams) {
		GetHttpParams(_URL_RECIPIENT + "/list", { page: onPageParams[0], sort: onPageParams[1] }).then(r =>
			setRecipients(r));
	  }
	
	return (<div className="text-center">
			<Button className="my-1" onClick={props.onclose}>Отмена</Button>
			<BasicTable fields={tableFields} page={recipients} onsort={(p) => fetch(p)}>
                {recipients.content.map((v, k) => {
                  let ident = "Нет";
                  if (v.identificator !== null) {
                    ident = v.identificator.code;
                    if (ident.length > id_max_len) {
                      ident = ident.substring(0, id_max_len) + "...";
                    }
                  }
                  return (
                    <tr>
                      <td> {k + 1} </td>
                      <td> {v.firstname + " " + v.surname} </td>
                      <td> {ident} </td>
                      <td className="d-md-none d-lg-table-cell">
                        {v.phone}
                      </td>
                      <td className="d-md-none d-lg-table-cell">
                        {v.function}
                      </td>
					  <td onClick={()=>props.onselect(v)}><Button size='sm'>Выбрать</Button></td>
                    </tr>
                  );
                })}
              </BasicTable>

	</div>)
}