/**
 *
 */
import React, { useEffect, useReducer } from 'react';

import {
	Container,
	Row,
	Col,
	Button,
	Form,
	Alert,

} from 'react-bootstrap'
import { _URL_REGISTRATION,Register } from '../assets/js/Connectorv2'
import '../assets/css/registration.css'
import * as templates from "../assets/js/UITemplates";
import { useNavigate } from 'react-router-dom';


function Registration() {

	function reduce(state, act) {
		let res = {
			password: state.password,
			confirmPassword: state.confirmPassword,
			surnameName: state.surnameName,
			firstName: state.firstName,
			code: state.code,
			message: state.message	
		}
		res[act.id] = act.value;
		
		return res;
		
	 }

	const [state,dispatch ] = useReducer(reduce,
		{
			password: '',
			confirmPassword: '',
			surnameName: '',
			firstName: '',
			code: '',
			message: ''
			
		})

	function readCode(){
		let sp = document.location.href.split("?")[1];
		if (sp !== undefined) {
			let params = document.location.href.split("?")[1].split("=");
			if (params.length === 2 && params[0] === "code")  {
					dispatch({id:"code", value: params[1] })	
			}
		}
	}
	const navigate = useNavigate();
	useEffect(()=>{
		readCode();
	},[])
	const hadleSend=()=>{
		Register(state).then(()=>{
			navigate("/signin");
		}).catch( err=>{
			dispatch({id:"message",value:err.data.reason});
		});
	}
	const handleChange = (event) => {
		const target = event.target;
		const id = target.name;
		dispatch({id:id,value:target.value});
		
	}
	const password_valid = (state.confirmPassword === state.password) && state.password !== '' && state.password.length > 5;
	const firstname_valid = state.firstName.length > 0;
	const surname_valid = state.surnameName.length > 0;
	const codeValid = state.code !== undefined ? state.code.length === 4 : false;
	const inputValid = password_valid && firstname_valid && surname_valid && codeValid;
	return (
		
		<>
			<Container fluid>
				<Row className=''>
					<Col className="registration-left-side">
					</Col>
					<Col className="registration-right-side " sm="6" >
						<Row className="justify-content-md-center mb-5">
							<h2>Регистрация</h2>
						</Row>
						{state.message!=="" ?
						<Row>
							<Alert variant='danger'>{state.message}</Alert>
						</Row>
						:""}
						<Row>
							<Col>
								<Form.Group>
									<Form.Label>*Проверочный код</Form.Label>
									<Form.Control
										name="code"
										defaultValue={state.code}
										id="code"
										type="text"
										onChange={handleChange}
										isInvalid={!codeValid}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group >
									<Form.Label>*Пароль</Form.Label>
									<Form.Control
										name="password"
										type="password" onChange={handleChange}
										isValid={password_valid}
										isInvalid={!password_valid}
										

									/>
									<Form.Text className="text-muted">
										Пароль не менее 6 символов
									</Form.Text>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group >
									<Form.Label>*Повтор пароля</Form.Label>
									<Form.Control
										name="confirmPassword"
										type="password" onChange={handleChange}
										isValid={password_valid}
										isInvalid={!password_valid}

									/>
									<Form.Text className="text-muted">
										Повторный ввод пароля
									</Form.Text>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group >
									<Form.Label>*Имя</Form.Label>
									<Form.Control
										name="firstName"
										type="text" onChange={handleChange}
										isInvalid={!firstname_valid}  />
									<Form.Text className="text-muted">
										Не менее 1 символа
									</Form.Text>
								</Form.Group>

							</Col>
							<Col>
								<Form.Group >
									<Form.Label>*Фамилия</Form.Label>
									<Form.Control
										name="surnameName"
										isInvalid={!surname_valid}
										type="text" onChange={handleChange} />
								</Form.Group>

							</Col>
						</Row>
						<Row className="text-center">
							<Form.Group >
								<p>Регистрируясь на сайте, вы соглашаетесь с <a href="https://skladsystem.ru">правилами и условиявим </a> использования </p>
							</Form.Group>
						</Row>
						<Row className="justify-content-md-center mt-3">
							<Button variant="primary" onClick={hadleSend} disabled={!inputValid}>
								{templates.button_register}
							</Button>
						</Row>
						
						<Row className="justify-content-md-center mt-5">
							<Button variant="link" onClick={()=>navigate("/signin")}>
							У меня есть  учетная запись
							</Button>
						</Row>
						
					</Col>
				</Row>
			</Container>
		</>
	)

}
export default Registration;
