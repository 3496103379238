/**
 *
 */
import React, { useEffect, useState } from 'react';

import * as templates from "../assets/js/UITemplates";
import { InstrumentEditor } from './catalog/instrumentEditor'
import { GetHttpParams, _URL_IMAGE_REPOSITORY, _URL_INSTRUMENT_IMAGE } from "../assets/js/Connectorv2";
import { PageLoadMain } from '../component/loader';
import { useLocation } from 'react-router-dom';
import { EditRecipient } from './recipient';


export function SearchResult() {

	const [editInstrument, setEditInstrument] = useState()
	const [editRecipientID, setEditRecipientID] = useState();
	const location = useLocation();
	const [findMode, setFindMode] = useState("instrument");

	function navBack() {
		setEditInstrument();
		setEditRecipientID();
	}
	function ButtonBar({ onChange, mode }) {


		return (<div className='d-flex'>
			<div
				className={`${mode === "instrument" ? 'text-secondary' : 'text-primary'} text-decoration-underline mx-2`}
				style={{ cursor: "pointer" }}
				onClick={() => onChange("instrument")}
			>Поиск по инструменту</div>
			<div
				className={`${mode === "recipient" ? 'text-secondary' : 'text-primary'} text-decoration-underline mx-2`}
				style={{ cursor: "pointer" }}
				onClick={() => onChange("recipient")}
			>Поиск по получателю</div>
			<div
				className={`${mode === "code" ? 'text-secondary' : 'text-primary'} text-decoration-underline mx-2`}
				style={{ cursor: "pointer" }}
				onClick={() => onChange("code")}
			>Поиск по коду</div>
		</div>
		)
	}

	switch (findMode) {
		case "instrument":
			if (editInstrument) {
				return (<InstrumentEditor id={editInstrument} onClose={() => navBack()} />)
			} else {
				return (
					<div className="dynamic-content dynamic-content-md">
						<ButtonBar onChange={(e) => setFindMode(e)} mode={findMode} />
						<InstrumentResult searchText={location.state.search} viewInstrument={(id) => setEditInstrument(id)} />
					</div>
				)
			}
		case "recipient":
			if (editRecipientID) {
				return (
					<div className="dynamic-content dynamic-content-md">
					<EditRecipient onClose={() => navBack()} recipient={{ id: editRecipientID }} />
					</div>
				)
			} else {
				return (
					<div className="dynamic-content dynamic-content-md">
						<ButtonBar onChange={(e) => setFindMode(e)} mode={findMode} />
						<RecipientResult searchText={location.state.search} viewRecipient={(id) => { setEditRecipientID(id) }} />
					</div>)
			}

		case "code":
			if (editInstrument) {
				return (<InstrumentEditor id={editInstrument} onClose={() => navBack()} />)
			}else if (editRecipientID) {
				return (
					<div className="dynamic-content dynamic-content-md">
						<EditRecipient onClose={() => navBack()} recipient={{ id: editRecipientID }} />
					</div>)
			}else {
				return <div className="dynamic-content dynamic-content-md">
					<ButtonBar onChange={(e) => setFindMode(e)} mode={findMode} />
					<CodeResult searchText={location.state.search} viewInstrument={(id) => setEditInstrument(id)} viewRecipient={(id) => { setEditRecipientID(id) }} />
				</div>;
			}

	}



}

function InstrumentResult({ searchText, viewInstrument }) {

	const [instrumentResult, setSearchResult] = useState()

	function onSearch() {
		GetHttpParams("/instrument/search", { search: searchText, page: 0 })
			.then(res => {
				setSearchResult(res);
			})
	}

	useEffect(() => { onSearch() }, [searchText])

	function handleSearchNextPage() {
		if (instrumentResult.number + 1 < instrumentResult.totalPages) {
			let nextPage = instrumentResult.number + 1;
			GetHttpParams("/instrument/search", { search: searchText, page: nextPage })
				.then(res => {
					let n = instrumentResult.content.concat(res.content);
					res.content = n;
					setSearchResult(res);
				})
		}
	}
	if (instrumentResult) {
		return (
			<div >
				<div>
					{instrumentResult.content !== undefined && instrumentResult.content.length > 0 ?
						<h2>Результаты по запросу {searchText}. Всего элементов: {instrumentResult.totalElements} </h2>
						: <h2>По запросу "{searchText}" ничего не найдено.  </h2>}
				</div>
				<div>
					{instrumentResult.content !== undefined ? instrumentResult.content.map((v, i) => {
						return (
							<InstrumentItem key={i} value={v} onView={(id) => viewInstrument(id)} />
						)
					}) : <div>{templates.error_message_search}</div>}
				</div>
				<div className="p-1 m-1 text-center">
					{instrumentResult.totalPages > (instrumentResult.number + 1) ?
						<div style={{ cursor: 'pointer' }} onClick={() => handleSearchNextPage()} className="text-primary h5">{templates.button_search_more}</div> : ''}
				</div>
			</div>
		)
	} else {
		return (<PageLoadMain />)
	}
}

function RecipientResult({ searchText, viewRecipient }) {

	const [recipientResult, setRecipientResult] = useState();

	function onSearch() {
		GetHttpParams("/recipient/search", { search: searchText, page: 0 })
			.then(res => {
				setRecipientResult(res);
			})
	}

	useEffect(() => { onSearch() }, [searchText])

	function handleSearchNextPage() {
		if (recipientResult.number + 1 < recipientResult.totalPages) {
			let nextPage = recipientResult.number + 1;
			GetHttpParams("/instrument/search", { search: searchText, page: nextPage })
				.then(res => {
					let n = recipientResult.content.concat(res.content);
					res.content = n;
					setRecipientResult(res);
				})
		}
	}
	if (recipientResult) {
		return (
			<div>
				<div>
					{recipientResult.content !== undefined && recipientResult.content.length > 0 ?
						<h2>Результаты по запросу {searchText}. Всего элементов: {recipientResult.totalElements} </h2>
						: <h2>По запросу "{searchText}" ничего не найдено.  </h2>}
				</div>
				<div>
					{recipientResult.content !== undefined ? recipientResult.content.map((v, i) => {
						return (
							<RecipientItem key={i} value={v} onView={(id) => viewRecipient(id)} />
						)
					}) : <div>{templates.error_message_search}</div>}
				</div>
				<div className="p-1 m-1 text-center">
					{recipientResult.totalPages > (recipientResult.number + 1) ?
						<div style={{ cursor: 'pointer' }} onClick={() => handleSearchNextPage()} className="text-primary h5">{templates.button_search_more}</div> : ''}
				</div>
			</div>
		)
	} else {
		return (<PageLoadMain />)
	}
}

function CodeResult({ searchText, viewInstrument, viewRecipient }) {


	const [codeResult, setCodeResult] = useState();

	function onSearch() {
		GetHttpParams("/code/search", { search: searchText, page: 0 })
			.then(res => {
				setCodeResult(res);
			})
	}
	useEffect(() => { onSearch() }, [searchText])

	function handleSearchNextPage() {
		if (codeResult.number + 1 < codeResult.totalPages) {
			let nextPage = codeResult.number + 1;
			GetHttpParams("/instrument/search", { search: searchText, page: nextPage })
				.then(res => {
					let n = codeResult.content.concat(res.content);
					res.content = n;
					setCodeResult(res);
				})
		}
	}
	if (codeResult) {
		return (
			<div>
				<div>
					{codeResult.content !== undefined && codeResult.content.length > 0 ?
						<h2>Результаты по запросу {searchText}. Всего элементов: {codeResult.totalElements} </h2>
						: <h2>По запросу "{searchText}" ничего не найдено.  </h2>}
				</div>
				<div>
					{codeResult.content !== undefined ? codeResult.content.map((v, i) => {
						if (v.recipient != null) {
							return (<RecipientItem key={i} value={v.recipient} onView={(id)=>viewRecipient(id)}/>)
						} else if (v.instrument != null) {
							return (<InstrumentItem key={i} value={v.instrument} onView={(id) => viewInstrument(id)} />)
						}
					}) : <div>{templates.error_message_search}</div>}
				</div>
				<div className="p-1 m-1 text-center">
					{codeResult.totalPages > (codeResult.number + 1) ?
						<div style={{ cursor: 'pointer' }} onClick={() => handleSearchNextPage()} className="text-primary h5">{templates.button_search_more}</div> : ''}
				</div>
			</div>
		)
	} else {
		return <PageLoadMain />
	}
}

function RecipientItem({ key, value, onView }) {

	return (
		<div className='m-1 p-1 mb-3' key={key} onClick={()=>onView(value.id)} style={{cursor:'pointer'}}>
			<div className='text-decoration-underline text-primary h5' style={{ cursor: 'pointer' }}
				onClick={() => onView(value.id)}>{value.name}
			</div>
			<div ><b>Фамилия: </b>  {`${value.surname === null ? 'нет' : value.surname}`}</div>
			<div ><b>Имя:</b>  {`${value.firstname === null ? 'нет' : value.firstname}`}</div>
			<div ><b>Должность:</b>  {`${value.function === null ? 'нет' : value.function}`}</div>
		</div>
	)
}

function InstrumentItem({ key, value, onView }) {


	return (
		<div className='m-1 p-1 mb-3' key={key}>
			<div className='text-decoration-underline text-primary h5' style={{ cursor: 'pointer' }}
				onClick={() => onView(value.id)}>{value.name}
			</div>
			<div className="">{"Производитель: " + (value.vendor === null ? "нет" : value.vendor)}</div>
			<b>Тип: </b> {value.type} <b>Инвентарный:</b> {value.invent} <b>Серийный №:</b> {value.serial}
		</div>
	)
}

export default SearchResult;
