import React, {useState,useEffect} from 'react';
import {
  Button,
  Form,
  } from 'react-bootstrap'
  import {
    _URL_INSTRUMENT,
    PostHttp,
    DeleteHttp,
    GetHttpParams
  } from '../../assets/js/Connectorv2';

  export function Comments(props){

    const [text,setText] = useState('');
    const [comments,setComments] = useState({content:[]});
    let [page,setPage] = useState(0);

    function fetch(params){
      GetHttpParams(_URL_INSTRUMENT+"/comment",{instrumentid:props.instrument.id,sort:params.sort,page:params.page})
      .then((res)=>setComments(res));
    }

    const onCommentEdit=() =>{
      if(text.length>0){
        PostHttp({message:text,instrumentid:props.instrument.id},_URL_INSTRUMENT+"/comment")
        .then(()=>{
          fetch({sort:'created,DESC',page:0});
          setText('');
        });
      }
      }
    useEffect(()=>{fetch({page:0,sort:'created,DESC'})},[])

    const handleDelete=(event)=>{
      DeleteHttp(_URL_INSTRUMENT+"/comment",{id:event.target.id})
      .then(()=>fetch({page:0,sort:'created,DESC'}))
    }

    function handleMoreMessage(){
      if (page + 1 < comments.totalPages) {
        GetHttpParams(_URL_INSTRUMENT+"/comment",{instrumentid:props.instrument.id,page:page+1,sort:'created,DESC'})
      .then((res)=>{
        comments.content.push(comments.content.push(res.content));
        setComments(comments);
        setPage(page+1);
      });
      }
    }

    return(<>
        <Form.Group className="m-1">
          <Form.Label className='d-flex p-1'><div>Комментарий</div> 
            <div className='ms-auto'></div></Form.Label>
            <Form.Control id="comment" as="textarea"
              onChange={(e)=>setText(e.target.value)}
              value={text}             
              rows={3}/>
         </Form.Group>
          <div className='text-end p-1 m-1'>
            <Button className='ms-auto p-1 my-1' variant='outline-primary' onClick={onCommentEdit}><i class="bi bi-send p-1 me-2"/>Отправить</Button>
          </div>
          <div>
            {comments.content.map((item)=>{
              return(<>
              <div className='border p-1 rounded bg-white mb-2'>
                <div className='d-flex border-bottom my-1 p-1'>
                    <div className='me-2 ms-auto'> <b>{item.who}</b></div>
                    <div> <b>{new Date(item.created).toLocaleString()}</b></div>
                    <div><Button variant="outline-danger" className='ms-2 py-0' size='sm' onClick={handleDelete} id={item.id}>x</Button></div>
                </div>
                <div className='p-2'>{item.message}</div>
              </div>
              </>)

            })}
            {(page + 1) < comments.totalPages ? 
            <div className="text-center"><Button size="sm" onClick={handleMoreMessage} variant='link'>Показать еще</Button></div>
            : ''}
          </div>
    </>)
  }