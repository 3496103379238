import React, { useEffect, useState } from 'react';
import {
	Alert,
	Form, Col, Modal, Container, Row, InputGroup, Button, FormControl,
	Table, Image, Pagination, Spinner
} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { SendFile, _URL_IMAGE_REPOSITORY, GetHttpParams, _URL_SETAVATAR,
	_URL_FILE_RESOURCE, _URL_DOCUMENT, PostHttp, _URL_CATEGORY,
	_URL_INSTRUMENT_LIST,
	_URL_ACCOUNT} from '../assets/js/Connectorv2';
import * as image from "../assets/images/default.png"
import "../assets/css/components.css"
import * as templates from "../assets/js/UITemplates";

export function phoneFormatter(value) {
	if (!value) return value;
	const phoneNumber = value.replace(/[^\d]/g, "");
	const phoneNumberLength = phoneNumber.length;

	if (phoneNumberLength < 4) return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(1, 4)}`;

	if (phoneNumberLength < 7) {
		return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`;
	}

	return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(
		4,
		7
	)}-${phoneNumber.slice(7, 11)}`;
}

export class RequestInputCount extends React.Component {

	constructor(props) {
		super(props)
		this.state = { inputCount: 0 }
	}

	handleChange = (event) => {
		this.setState({inputCount:event.target.value })
	}

	handleCommit = () => {
		if (this.state.inputCount > 0) {
			this.props.onCommit(this.state.inputCount);
		}
	}

	render() {


		return (<>
			<Modal show={this.props.show} onHide={this.props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>Введите Количество</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col><p>{this.props.string}</p></Col>
						</Row>
						<Row>
							<Col><p>{this.props.string1}</p></Col>
						</Row>
						<Row>
							<Col>
								<InputGroup>
										<InputGroup.Text id="input-count">Количество</InputGroup.Text>
									<FormControl onChange={this.handleChange} />
								</InputGroup>
							</Col>
						</Row>
					</Container>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.props.onHide}>
						{templates.button_close}
					</Button>
					<Button variant="primary" onClick={this.handleCommit}>
						{templates.button_add}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
		)
	}
}

export class Catalog extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			selectedInstrument: null,
			count: 0,
			instrument: { content: [] },
			instrumentCurrentPage: 0,
			categoryCurrentPage: 0,
			showSpinner: true,
			category: { categories: { content: [] } },

		}
		this.nav = [-1];
	}

	showNextCategory = (event) => {
		let parent_cat = parseInt(event.target.id);
		this.nav.push(parent_cat);
		GetHttpParams(_URL_CATEGORY+'/list',{page:0,parent_category:parent_cat})
			.then((cat) => {
				if (!cat.onlyInstrument) {
					this.setState({
						category: cat,
						instrument: { content: [] },
						showSpinner: false

					})


				} else {
					GetHttpParams(_URL_INSTRUMENT_LIST,{page:0,parent_category:parent_cat})
						.then((ins) => {
							this.setState({
								category: { categories: { content: [] }, onlyInstrument: true },
								instrument: ins,
								showSpinner: false,
							})
						})
				}
			})
	}
	onPageClick = (page) => {
		GetHttpParams(_URL_INSTRUMENT_LIST,{ parent_category:this.nav[this.nav.length - 1],page:page } )
			.then((ins) => {
				this.setState({ instrument: ins, showSpinner: false,instrumentCurrentPage : page });
			})
	}
	onPageCatClick = (page) => {
		
		GetHttpParams(_URL_CATEGORY+'/list',{page:page,parent_category:this.nav[this.nav.length - 1]})
			.then((cat) => {
				if (!cat.onlyInstrument) {
					this.setState({
						category: cat,
						instrument: { content: [] },
						showSpinner: false,
						categoryCurrentPage : page

					})
				} else {
					GetHttpParams(_URL_INSTRUMENT_LIST,{ parent_category:this.nav[this.nav.length - 1],page:page } )
						.then((ins) => {
							this.setState({
								category: { categories: { content: [] }, onlyInstrument: true },
								instrument: ins,
								showSpinner: false,
								instrumentCurrentPage : page
							})

						})

				}

			})
	}
	showPrevCategory = (event) => {
		let parent_cat = parseInt(event.target.id);
		this.nav.pop()
		GetHttpParams(_URL_CATEGORY+'/list',{page:0,parent_category:parent_cat})
			.then((cat) => {
				if (!cat.onlyInstrument) {
					this.setState({
						category: cat,
						instrument: { content: [] },
						showSpinner: false

					})

				} else {
					GetHttpParams(_URL_INSTRUMENT_LIST,{parent_category:parent_cat,page:0})
						.then((ins) => {
							this.setState((state, props) => ({
								category: { categories: { content: [] }, onlyInstrument: true },
								instrument: ins,
								showSpinner: false,
							}))
						})
				}
			})
	}
	componentDidMount() {
			GetHttpParams(_URL_CATEGORY+'/list',{page: this.nav[0]} )
				.then((cat) => {
					this.setState({ category: cat, instruments: { content: [] }, showSpinner: false })
				})

	}

	handleAddToBasket(id) {
		this.props.find(id);
	}

	handleHide = () => {
		this.nav = [-1];
		GetHttpParams(_URL_CATEGORY+'/list',{page:0,parent_category:this.nav[0]})
			.then((cat) => {
				this.setState({ category: cat, instruments: { content: [] }, showSpinner: false })
			})
		this.props.onHide();
	}

	render() {
		function instrumentTable(items) {
			return (<>
				<thead>
					<tr style={{ textAlign: "center", position: "sticky", top: "0", backgroundColor: "white" }}>
						<th>Нa-ние</th>
						<th>Пр-тель</th>
						<th>Модель</th>
						<th>Тип</th>
						<th>Остаток/Всего</th>
					</tr>
				</thead>
				<tbody className="text-wrap">
					{items}
				</tbody></>)
		}

		function categoryTable(items) {
			return (<><thead></thead>
				<tbody>
					{items}
				</tbody></>)
		}


		let table;
		let button;
		let paggination;
		let c = this.state.category;
		if (this.nav.length !== 1) {
			button = <>
				<Button id={this.nav[this.nav.length - 2]} onClick={this.showPrevCategory} >Назад</Button>

			</>
		}

		if (!c.onlyInstrument) {
			const items =
				c.categories.content.map((e, key) => {
					let img;
					for (let i = 0; i < e.images.length; i++) {
						if (e.images[i].defaultFile) {
							img = e.images[i];
							break;
						}
					}

					return (<tr>
						<td style={{ width: "10px" }}>
							<Image src={img !== undefined ?
								_URL_IMAGE_REPOSITORY + "?uuid=" + img.uuid + 
								"&type=category"
								: image.default} width='64' height='38'></Image>
						</td>
						<td><Button variant="link" id={e.id} onClick={this.showNextCategory}>{e.name}</Button></td>


					</tr>)
				})
			table = categoryTable(items);

			paggination = <PaginationComponent page={c.categories} pageActive={this.state.categoryCurrentPage} toPage={this.onPageCatClick}/>

		} else if (c.onlyInstrument) {

			let items =
				this.state.instrument.content.map((e, key) => {
					let stat;
					if (e.instrumentCounters != null) {
						stat = e.instrumentCounters.avalable + "/" + e.totalСount;
					} else {
						stat = "0/" + e.totalСount;
					}
					return (
						<tr onClick={() => this.handleAddToBasket(e.id)} style={{ cursor: "pointer" }}>
							<td className="text-truncate" style={{maxWidth:"110px"}}>{e.name}</td>
							<td className="text-truncate" style={{maxWidth:"110px"}}>{e.vendor}</td>
							<td className="text-truncate" style={{maxWidth:"110px"}}>{e.model}</td>
							<td>{e.type}</td>
							<td>{stat}</td>

						</tr>)
				})

			table = instrumentTable(items);

			paggination = <PaginationComponent page={this.state.instrument} pageActive={this.state.instrumentCurrentPage} toPage={this.onPageClick}/>
		} else {
			table = <div style={{ textAlign: "center" }}>Ничего не найдено :(</div>

		}


		let spinner = null;
		if (this.state.showSpinner) {
			spinner =
				<div className="text-center">
					<Spinner animation="border" role="status" >
						
					</Spinner>
				</div>
		}
		let content;
		let table_items

		if (this.props.basket !== undefined) {
			table_items = this.props.basket.map((v, k) => {
				let instrument = v.instrument;
				let instrumentInHand = v.instrumentInHand;
				let value;
				if (instrument == null) {
					value = instrumentInHand.instrument
				} else {
					value = instrument
				}

				return (
					<tr style={{ height: "20px" }}>
						<td className="text-truncate" style={{maxWidth:"110px"}}>{value.name}</td>
						<td className="text-truncate" style={{maxWidth:"110px"}}>{value.vendor}</td>
						<td>{value.model}</td>
						<td>{v.count}</td>
					</tr>
				)
			})
		} else if (this.props.instrumentArrival !== undefined) {
			table_items = this.props.instrumentArrival.map((v, k) => {

				return (
					<tr style={{ height: "20px" }}>
						<td>{v.name}</td>
						<td>{v.vendor}</td>
						<td>{v.model}</td>
						<td>{v.count}</td>
					</tr>
				)
			})

		} else if (this.props.transferRequest !== undefined) {
			table_items = this.props.transferRequest.map((v, k) => {
				return (
					<tr style={{ height: "20px" }}>
						<td>{v.instrument.name}</td>
						<td>{v.instrument.vendor}</td>
						<td>{v.instrument.model}</td>
						<td>{v.count}</td>
					</tr>
				)
			})
		} else if (this.props.writeoffInstrument !== undefined) {
			table_items = this.props.writeoffInstrument.map((v, k) => {
				return (
					<tr style={{ height: "20px" }}>
						<td>{v.instrument.name}</td>
						<td>{v.instrument.vendor}</td>
						<td>{v.instrument.model}</td>
						<td>{v.count}</td>
					</tr>
				)
			})
		}

		if (this.props.show) {
			content = <>
				<div className="styled-block p-2 shadow border">
					<div className="text-center"><h2>Каталог</h2></div>
					<div className="d-flex">
						<div className="catalog-basket border mx-2 p-2" style={{width:"100%"}}>
							<div><h5 className='text-center'>Склад</h5></div>
								<Table striped  className="text-center p-2" size="sm">
									{table}
								</Table>
							{spinner}
							<div className='text-center mt-1'>
								{paggination}
							</div>
							<div className="text-center">
								{button}
							</div>
						</div >
						<div className="catalog-basket border me-2 p-2" style={{width:"100%"}}>
							<div><h5 className='text-center'>В Корзине</h5></div>
							<div style={{ overflow: "auto" }}>
								<Table striped bordered hover className="instrument-table" size="sm">
									<thead>
										<tr style={{ textAlign: "center" }}>
											<th>Наименование</th>
											<th>Производитель</th>
											<th>Модель</th>
											<th>Кол-во</th>
										</tr>
									</thead>
									<tbody>
										{table_items}
									</tbody>
								</Table>
							</div>
						</div>
					</div>
					<div className="text-center my-2">
						<Button onClick={this.handleHide}>{templates.button_close}</Button>
					</div>
				</div>
			</>
		}
		return (
			<>
			<div className="w-100 ms-2 mb-2">
				{content}
				</div>
			</>
		)
	}

}
export function MainDropdownMenu(props) {
	
	
		return (
			<>
				<div className="dropdown">
				  <a className="btn btn-link  text-decoration-none p-0 me-2" href="#" role="button" 
				  id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
				    {/* _URL_IMAGE_REPOSITORY + "?uuid=" + e.uuid + "&token=" + token.accessToken + 
							"&type=account" */}
					<Image src="assets/default-avatar.png" width={30} className="rounded-5"/>
				  </a>
				  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
				    <li><Link className="dropdown-item" to="/profile">Аккаунт</Link></li>
				    <li><Link className="dropdown-item" to="/adminpanel">Управление</Link></li>
				    <li><Link className="dropdown-item" to="/signin" onClick={props.onLogout}>Выход</Link></li>
				  </ul>
				</div>

			</>

	)
}
export function BasicDialog (props){

	return(
	<>
		<Modal show={props.show} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.children}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={props.onHide}>
						{templates.button_close}
					</Button>
					<Button variant="primary" onClick={props.onCommit}>
						{templates.button_accept}
					</Button>
				</Modal.Footer>
			</Modal>
	</>)
}

export function ConfirmDialog (props){

	return(
	<>
		<Modal show={props.show} onHide={props.onHide}>
				<Modal.Header closeButton>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col><p>{props.message}</p></Col>
						</Row>

					</Container>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={props.onCancel}>
						{templates.button_close}
					</Button>
					<Button variant="primary" onClick={props.onCommit}>
						{templates.button_accept}
					</Button>
				</Modal.Footer>
			</Modal>
	</>)
}


export class Search extends React.Component {

	constructor(props) {
		super(props);
		this.state = { inValid: false, search: '' }
	}

	handleFocus = (event) => {
		event.target.style.width = "400px"

	}

	handleSearchKey = (event) => {
    
		if (event.keyCode === 13) {
			this.handleSearch()
		  
		}
	
	  } 

	handleLostFocus = (event) => {
		event.target.style.width = "auto"
	}

	handleInput = (event) => {
		this.setState({ search: event.target.value })
	}

	handleSearch = () => {
		if (this.state.search.length > 0) {
			this.props.onSearch(this.state.search);
			this.setState({ inValid: false, search: '' })

		} else {
			this.setState({ inValid: true })
		}

	}

	render() {

		return (
			<Row >
				<Col className="p-0 mr-1 d-sm-none">
					<FormControl size='sm' type="text" placeholder="Поиск" style={{ width: 'auto' }}
						onInput={this.handleInput} isInvalid={this.state.inValid} value={this.state.search} onKeyUp={this.handleSearchKey}/>
				</Col>
				<Col className="p-0 mr-1 d-none d-sm-block">
					<FormControl size='sm' type="text" placeholder="Поиск" style={{ width: 'auto' }} onKeyUp={this.handleSearchKey} onFocus={this.handleFocus} onBlur={this.handleLostFocus}
						onInput={this.handleInput} isInvalid={this.state.inValid} value={this.state.search} />
				</Col>
				<Col className="p-0 m-0">
					<Button variant="outline-success" size='sm' onClick={this.handleSearch} onkey>{templates.button_search}</Button>
				</Col>
			</Row>
		)
	}
}
/**

 */
export class DateFilter extends React.Component {



	getDefaultDate(minusDays) {
		let date = new Date();
		date.setDate(date.getDate() - minusDays);
		var currentDate = date.toISOString().substring(0, 10);

		return currentDate;
	}

	handleFilter = () => {
		let dateF = document.getElementById('from').value;
		let dateT = document.getElementById('to').value;
		this.props.onFilter(dateF, dateT);
	}
	render() {
		let date1 = this.getDefaultDate(this.props.backdays !== undefined ? this.props.backdays : 1);
		let date2 = this.getDefaultDate(0);
		let content =
			this.props.show ?
				<div className="d-flex">
					<div className="me-2">
						<div>C</div>
					</div>
					<div className="me-2">
						<Form.Control id="from" type="date" size='sm' defaultValue={date1} />
					</div>
					<div className="me-2">
						<div>По</div>
					</div>
					<div className="me-2">
						<Form.Control type='date' id="to" size='sm' defaultValue={date2} />
					</div>
					<div className="me-2">
						< Button size='sm' onClick={this.handleFilter}>Поиск</Button>
					</div>
				</div>
				:
				<></>
		return (
			<>
				{content}
			</>

		)
	}

}
export function PaginationComponent(props) {

	// const [pageActive, setPageActive] = useState(props.startPage === undefined ? 0 : props.startPage);

	let content;
	if (props.page.pageable === undefined) {
		content = <ShowSpinner show={true} />
	} else {

		let pagination_item = [];
		for (let a = props.pageActive-2; a < props.pageActive+3; a++) {
			if(props.page.totalPages > a){
				pagination_item[a] = <Pagination.Item key={a} id={a} active={props.pageActive===a}  onClick={()=>{props.toPage(a)} }>{a + 1}</Pagination.Item>
			}
		}
		let next = props.pageActive + 1 < props.page.totalPages ? props.pageActive + 1 : props.pageActive ;
		let priveous = props.pageActive -1 >= 0 ? props.pageActive -1 : 0 ;
		content = <Pagination className="justify-content-center" size={props.size !== undefined ? props.size : ''}>
			<Pagination.First  onClick={() => props.toPage(0)}/>
			<Pagination.Prev  onClick={() => props.toPage(priveous)} />
			{pagination_item}
			<Pagination.Next onClick={() => props.toPage(next)}/>
			<Pagination.Last onClick={() => props.toPage(props.page.totalPages - 1)}/>
		</Pagination>
	}
	return (
		<>
			{content}
		</>
	)
}
export function AccountAvatar(props){
	
	const images = props.images;
	if(images.length>0){
		return(
			<Image width="220" height="220" src={'data:image/png;base64,'+images[0].data} className={props.className} style={props.style}></Image>
		)
	}else {return(<>
			<Image width="220" height="220" src={image.default} className={props.className} ></Image>
		</>)
	}
}

export function UploadFileForm(props){
	const [show,setShow] = useState(false);
	const [image,setImage] = useState();
	const handleAdditem = (event) => {
		if(image!== undefined){	
			SendFile(image, props.type, props.id).then(()=>{
				setImage(undefined);
				if(props.onUploaded!==undefined){
					props.onUploaded();
					setShow(false);
				}
			});	
		}
	}

	const handleSelectImage = (event) => {
		const file = event.target.files[0];
		setImage(file);
	}


	return(<>
	<Button onClick={()=>setShow(true)} variant='link' className='mb-1'>Загрузить</Button>
	<Modal show={show} >
        <Modal.Header closeButton>
          <Modal.Title>Загрузитьт фото</Modal.Title>
        </Modal.Header>

        <Modal.Body>
			<Form.Control id="file-select" className='btn btn-outline-primary' type="file"  accept=".jpg, .jpeg, .png" onChange={handleSelectImage}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setShow(false)}>Закрыть</Button>
          <Button disabled={image===undefined}  variant="primary" onClick={handleAdditem}>Загрузать</Button>
        </Modal.Footer>
      
	  </Modal>
		
		
	</>)
}



export function ExportComponent(props) {

	const [showLoader, setShowLoader] = useState(true);
	const [docs, setDocs] = useState([])
	const [request, setRequest] = useState({ header: '', banner: '', footer: '', type: props.type, documentid: props.id === undefined ? '' : props.id })
	const [message,setMessage] =useState()

	function fetch(){
		GetHttpParams(_URL_DOCUMENT+"/24",{type:props.type}).then((e) => {
			setShowLoader(false)
			setDocs(e);
		})
	}
	useEffect(() => {
		
		fetch();
	}, [showLoader, props.type])

	let handleInput = (event) => {
		const id = event.target.id;
		const val = event.target.value;

		switch (id) {
			case 'input-header':
				request.header = val;
				setRequest(request);
				break;
			case 'input-banner':
				request.banner = val;
				setRequest(request);
				break;
			case 'input-footer':
				request.footer = val;
				setRequest(request);
				break;
			default:
				break;
		}
	}
	const content = <>
	<Row className='justify-content-md-center '>
		<ShowSpinner show={showLoader} />
	</Row>
	
	
	<div className='d-flex'>
		<div className='w-100 me-2 mt-1'>
			<div className='text-center'><h4>{props.title}</h4></div>
			{message !== undefined ? <Alert variant='danger'>{message}</Alert> : ''}
			{showLoader ? '' :
			<div className='w-100 p-1 border' style={{ backgroundColor: templates.block_background_color, borderRadius: '4px' }}>
					<div className='d-flex'>
						<div className=''>Последний запрошенный отчет: </div>						
						{docs.length>0?<><div><a href={`/api/files?type=inventory&uuid=${docs[0].uuid}`}>Скачать {new Date(docs[0].created).toLocaleString()}</a></div></>:''}
					</div>
					<Form.Group className="mb-3 w-100" >
						<Form.Label>Заголовок</Form.Label>
						<Form.Control as="textarea" rows={3} onInput={handleInput} id='input-header' />
					</Form.Group>
					<Form.Group className="mb-3 w-100" >
						<Form.Label>Шапка</Form.Label>
						<Form.Control as="textarea" rows={3} onInput={handleInput} id='input-banner' />
					</Form.Group>
					<Form.Group className="mb-3 w-100" >
						<Form.Label>Подвал</Form.Label>
						<Form.Control as="textarea" rows={3} onInput={handleInput} id='input-footer' />
					</Form.Group>
				</div>}
		</div>
		<div className='ms-auto mt-1'>
			<ButtonPanelExport 
			onRefresh={() =>
				GetHttpParams(_URL_DOCUMENT , { type: props.type  }).then((e) => {
					setDocs(e);
				})
			} 
			onBack={props.onExit}
			onRequest={() => {
				PostHttp(request, _URL_DOCUMENT + "/request")
				.then(() => {fetch();} )
				.catch(err=>{
					setMessage(err.data.message)
				})} 
				} />
		</div>
	</div>
	</>
	return <>
		<div className="">{content}</div>
        
      
	</>
}
function ButtonPanelExport(props) {


	return (<>
		<div className=" border border-success rounded p-2" style={{ backgroundColor: templates.block_background_color, width: '230px' }}>
			<div className="d-block">
					<div className='p-1'>
						<Button variant="success" className="w-100" onClick={props.onRequest}><i className="bi bi-arrow-left-square mr-1"></i>{templates.button_request} </Button>
					</div>
				<div className='p-1'>
					<Button variant="success" className="w-100" onClick={props.onBack}><i className="bi bi-arrow-left-square mr-1"></i>{templates.button_back} </Button>
				</div>

			</div>

		</div>

	</>)
}
export function ShowSpinner(prop) {

	return <> {prop.show ? <div className="spinner-border">
		<Spinner />
	</div> : ''}</>
}
export function IssueModeSwitcher(props){

	return(

		<div className="btn-group ml-5" style={{marginTop:'32px'}} >
							<button type="button" className={props.value === 1 ? 'btn btn-toggle btn-toggle-selected' : 'btn btn-toggle'} onClick={()=>props.onToggle(1)}>Выдача</button>
							<button type="button" className={props.value === 2 ? 'btn btn-toggle-red btn-toggle-selected-red' : 'btn btn-toggle-red'} onClick={()=>props.onToggle(2)}>Возврат</button>

						</div>
	)
}


