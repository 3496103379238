import React, { useEffect, useState } from 'react';

import { DateFilter } from "../component/components";
import { DownloadFile, GetHttpParams, _URL_INSTRUMENT_CONTAINER, _URL_REPORT } from '../assets/js/Connectorv2';
import { BasicTable } from '../component/table';
import { Tabs, Tab, Table } from "react-bootstrap"
import { PageLoadMain } from '../component/loader';

function Report(props) {
  const [key, setkey] = useState("journal")

  return (<>
    <div className="content-pane dynamic-content">
      <Tabs defaultActiveKey="profile" className="mb-3" activeKey={key} onSelect={(k) => setkey(k)} mountOnEnter unmountOnExit>
        <Tab eventKey="journal" title="Общий журнал" className='bg-white'>
          <ReportJournal />
        </Tab>
        <Tab eventKey="recipient" title="На руках по получателям" className='bg-white'>
          <ReportRecipientInstrument />
        </Tab>
        <Tab eventKey="instrument" title="На руках по инструменту" className='bg-white'>
          <ReportInstrumentRecipient />
        </Tab>
      </Tabs>
    </div>


  </>)
}

function ReportJournal(props) {
  const [filter, setFilter] = useState({ dateFrom: getDate(4) + " 00:00:00", dateTo: getDate(0) + " 23:59:59" });
  const backdays = 4;

  const [instrumentTransfer, setInstrumentTransfer] = useState({ content: [] })
  const [showLoader,setShowLoader] = useState(true);

  function getDate(minusDays) {
    let date = new Date();
    date.setDate(date.getDate() - minusDays);
    var currentDate = date.toISOString().substring(0, 10);

    return currentDate;
  }

  const handleTRinfo = (event) => {
    if (event.target.nodeName !== "TD") {
      return
    }
    const content = instrumentTransfer.content;
    const parent = event.target.parentNode;
    let id = parent.getAttribute("index");
    const container = content[id].srcInstrumentContainer;
    if (container !== 0) {

      if (parent.getAttribute("collapsed") === null) {
        GetHttpParams(_URL_INSTRUMENT_CONTAINER + '/more', { id: content[id].dstInstrumentContainer }).then(d => {
          GetHttpParams(_URL_INSTRUMENT_CONTAINER + '/more', { id: container }).then(s => {

            const tr = document.createElement("tr")
            const td = document.createElement("td")
            td.setAttribute("colSpan", "6");
            td.id = "info-" + id;
            parent.setAttribute("collapsed", '');
            td.style.cssText = "text-align : center";
            td.innerHTML = `<b>Отправитель:</b> ${s.name} <b>  Получатель:</b> ${d.name}`;
            tr.appendChild(td)
            parent.after(event.target.parentNode, tr);
          })
        })

      } else {
        parent.removeAttribute("collapsed");
        document.getElementById("info-" + id).remove();
      }
    }

  }

  const handleFilter = (dateF, dateT) => {
    setShowLoader(true);
    if (dateF !== '' & dateT !== '') {
      setFilter({ dateTo: dateT + " 23:59:00", dateFrom: dateF + " 00:00:00" });
    } else {
      setFilter({ dateTo: null, dateFrom: null });
    }
    GetHttpParams(_URL_REPORT + '/transfers', {
      page: 0,
      datef: dateF + " 00:00:00",
      datet: dateT + " 23:59:00"
    }).then((r) => {setInstrumentTransfer(r);setShowLoader(false)});
  }

  function fetch(pageParams) {
    
    GetHttpParams(_URL_REPORT + '/transfers', {
      page: pageParams[0], sort: "id",
      datef: filter.dateFrom,
      datet: filter.dateTo
    })
      .then((r) => { setInstrumentTransfer(r);setShowLoader(false) });
  }

  const tableFields = [{ name: "#", sort: 'id' }, { name: "Получатель", sort: "recipient" }, { name: "Наименование", sort: "instrument_name" }, { name: "Количество", sort: "count" },
  { name: "Операция", sort: "instrumentTransferAction", }, { name: "Дата", sort: "timestamp" }];

    return (<>
      <div>
        <div className="d-flex border p-2 mb-3 shadow">
          <div>
            <DateFilter show={true} onFilter={handleFilter} backdays={backdays} />
          </div>
        </div>
        <div className="border p-1 shadow">
          
          <BasicTable fields={tableFields} onsort={(v) => fetch(v)} page={instrumentTransfer} >
            {showLoader ?<tr> <td colspan={6}><PageLoadMain /></td> </tr>:
              instrumentTransfer.content.map((v, k) => {
                let action;
                if (v.instrumentTransferAction === "issue_inst") {
                  action = "Выдача"
                } else if (v.instrumentTransferAction === "return_inst") {
                  action = "Возврат"
                } else if (v.instrumentTransferAction === "transf_inst") {
                  action = "Перемещение"
                }
                return (<tr key={k} index={k} id={v.id} collapsed={false} style={{
                  height: "20px",
                  textAlign: "center",
                  cursor: 'pointer'
                }} onClick={handleTRinfo}>
                  <td>{k + 1}</td>
                  <td>{v.recipient.firstname + " " + v.recipient.surname}</td>
                  <td>{v.instrument.name}</td>
                  <td>{v.count}</td>
                  <td>{action}
                    {
                      v.srcInstrumentContainer != 0 & v.dstInstrumentContainer !== 0
                        ? <i class="bi bi-info-lg ml-1"></i>
                        : ''
                    }
                  </td>
                  <td>{new Date(v.timestamp).toLocaleDateString()}</td>
                </tr>)
              })
            }
          </BasicTable>
        </div>
      </div>
    </>)
}


export default Report;

function ReportRecipientInstrument(props) {

  const [report, setReport] = useState([]);
  const [showLoader,setShowLoader] = useState(true);
  useEffect(() => {
    fetch();
  }, [])

  function fetch() {
    GetHttpParams(_URL_REPORT + "/recipient/instrument")
      .then(e => {setReport(e);setShowLoader(false)});
  }
  const onXLSX=()=>{
    DownloadFile("/report/recipient/instrument/export")
    .then(res=>{
      if(res.ok){
        res.blob().then(b=>{
        const blob = URL.createObjectURL(b);
        const link = document.getElementById('link');
        link.href=blob;
        link.click();
      });
      }
    })
  }


  if(showLoader){
    return(<PageLoadMain />)
  }else {
  return (<>
    <div className='text-end'>
    <a href="3" id="link"/>
      <a href="#" onClick={onXLSX}><i className="bi bi-filetype-xlsx fs-4 text-success"></i></a>
    </div>
    <Table striped bordered hover >
      <tbody>
        {report.map(v => {
          if (v.instrumentInHands.length > 0)
            return (<>
              <tr>
                <td colSpan={5} className='bg-warning bg-opacity-25'>{v.recipient.surname + " " + v.recipient.secondname}</td>
              </tr>
              <tr className="fw-bold">
                <td>Наименование</td>
                <td>Модель</td>
                <td>Инвентарный</td>
                <td>Доступно</td>
                <td>Выдано</td>
              </tr>
              {v.instrumentInHands.map(e => {
                return (
                  <tr>
                    <td>{e.instrument.name}</td>
                    <td>{e.instrument.model}</td>
                    <td>{e.instrument.invent}</td>
                    <td>{e.instrument.instrumentCounters !== null ? e.instrument.instrumentCounters.avalable : "0"}</td>
                    <td>{e.count}</td>
                  </tr>)
              })}
            </>)
        })}


      </tbody>
    </Table>
  </>)}
}

function ReportInstrumentRecipient(props) {
  const [report, setRepor] = useState([]);
  const [showLoader,setShowLoader] = useState(true);
  useEffect(() => {
    fetch();
  }, [])

  function fetch() {
    GetHttpParams(_URL_REPORT + "/instrument/recipient")
      .then(e => {setRepor(e);setShowLoader(false)});
  }
  const onXLSX=()=>{
    DownloadFile("/report/instrument/recipient/export")
    .then(res=>{
      if(res.ok){
        res.blob().then(b=>{
        const blob = URL.createObjectURL(b);
        const link = document.getElementById('link');
        link.href=blob;
        link.click();
      });
      }
    })
  }
  if(showLoader){
    return(<PageLoadMain />)
  }else {
  return (<>
    <div className='text-end'>
      <a href="3" id="link"/>
      <a href="#" onClick={onXLSX}><i className="bi bi-filetype-xlsx fs-4 text-success"></i></a>
    </div>
    <Table striped bordered hover >

      <tbody>
        {report.map(v => {
          return (<>
            <tr className="fw-bold">
              <td>Наименование</td>
              <td>Модель</td>
              <td>Инвентарный</td>
              <td>Доступно</td>
              <td>Выдано</td>
            </tr>
            <tr>
              <td>{v.instrument.name}</td>
              <td>{v.instrument.model}</td>
              <td>{v.instrument.invent}</td>
              <td>{v.instrument.instrumentCounters !== null ? v.instrument.instrumentCounters.avalable : 0}</td>
              <td>{v.instrumentInHands.reduce((a, a1) => a + a1.count, 0)}</td></tr>
            {v.instrumentInHands.map(e => {
              return (
                <tr>
                  <td colSpan={4}>{e.recipient.surname + " " + e.recipient.firstname}</td>
                  <td >{e.count}</td>
                </tr>)
            })}
          </>)
        })}


      </tbody>
    </Table>
  </>)}
}