
import React, { useEffect, useState } from "react";
import './imageSlider.css';
import * as templates from "../assets/js/UITemplates";
import { DeleteHttp, _URL_FILE_RESOURCE } from "../assets/js/Connectorv2";
import { UploadFileForm } from "./components";

export default function ImageSlider(props) {


    let slideIndex =1;
    useEffect(()=>showSlides(slideIndex),[])
    
    function handleDeleteImage(uuid){

      DeleteHttp(_URL_FILE_RESOURCE,{uuid:uuid,id:props.instrumentid,type:'instrument'})
      .then(props.update)

    }

    function currentSlide(n) {
        showSlides(n);
    }

    function plusSlides(n) {
        showSlides(slideIndex += n);
    }
    function showSlides(n) {
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dot1");
        if(slides.length >0 && dots.length > 0){
            if (n > slides.length) { slideIndex = 1 }
            if (n < 1) { slideIndex = slides.length }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(" activeImage", "");
            }
            slides[slideIndex - 1].style.display = "block";
            dots[slideIndex - 1].className += " activeImage";
        }
    }

    return (
        <>  <div style={{ backgroundColor: templates.block_background_color, borderRadius: "10px", }}>
            <h5 className="text-center my-2">Фото</h5>
                <div className="slideshow-container p-2 my-2 " >

                    {
                        props.images.map((img,i) => {
                            return (
                                <div className="mySlides fade1 text-center">
                                    <div className="numbertext text-dark" >{i+1}/ {props.images.length}</div>
                                    <img className="border rounded p-1" src={'data:image/png;base64,' + img.data}  width={250} height={250}/>
                                    {!img.defaultFile ? 
                                        <div onClick={()=>handleDeleteImage(img.uuid)} className="text">Удалить</div>
                                    : ''}
                                </div>
                            )
                        })
                    }
                    <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
                    <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
                </div>
                <div className="text-center">
                    {props.images.map((e,i)=>{
                        return(<span className="dot1" onClick={() => currentSlide(e)}></span>)
                    })}                                
                </div>
                <div className="text-center p-1">
                    <UploadFileForm  type='instrument' id={props.instrumentid} onUploaded={()=>{props.update();}}/>
                </div>
            </div>
        </>
    )
}