import React, { useEffect, useState } from 'react';
import {
  Table,
  Form,
  Button,
  Col,
} from 'react-bootstrap'
import { RequestInputCount, Catalog,DateFilter } from "../component/components"
import {Import} from '../component/ImportExcel'
import * as templates from "../assets/js/UITemplates";
import { GetHttpParams, PostHttp, PutHttp, _URL_INSTRUMENT_ARRIVAL, _URL_ISSUE } from '../assets/js/Connectorv2';
import { BasicTable } from '../component/table';
import {ErrorDialog} from '../component/dialog';


function Arrival (props) {

  const [filter ] = useState({dateFrom: null,dateTo: null});
  const [iac,setIac] = useState({content: [],totalPage: 0});
  const [showAddIac,setShowAddIac] =useState(false);
  const [selected,setSelected] = useState('')
  const [showDatefilter,setShowDatefilter] = useState(true);
  const [showImport,setShowImport] = useState(false);
  let backdays = 4;

  useEffect(()=> {
    filter.dateFrom = getDate(backdays) + " 00:00:00";
    filter.dateTo = getDate(0) + " 23:59:59";
  },[])
  
  function fetchData(pageParams) {
    GetHttpParams(_URL_INSTRUMENT_ARRIVAL, {
      page: pageParams[0], sort: pageParams[1],
      datef: filter.dateFrom,
      datet: filter.dateTo
    }).then((iac1) => {setIac(iac1);});
  }
  
  function getDate(minusDays) {
    let date = new Date();
    date.setDate(date.getDate() - minusDays);
    var currentDate = date.toISOString().substring(0, 10);

    return currentDate;
  }
  const handleShowAddIAC = () => {
    setShowAddIac(true);
    setShowDatefilter(false);    
  }
  const handleHideAddIAC = () => {
    fetchData([0,""]);
    setShowDatefilter(true);
    setShowAddIac(false);
    
  }

  const handleViewDetails = (event) => {
    const id = event.target.id;
    iac.content.forEach(v => {
      if (v.id == id) {
        setSelected(v);
        setShowDatefilter(false);
        
      }
    })
  }
  const handleFilter = (dateF, dateT) => {
    if (dateF !== '' && dateT !== '') {
      filter.dateTo = dateT + " 23:59:00";
      filter.dateFrom = dateF + " 00:00:00";
    } else {
      filter.dateTo = null;
      filter.dateFrom = null;
    }
    fetchData([0, ""]);
  }
  function buttonPanel(){

    return (<>
                    <Button className="me-1" size="sm" onClick={()=>setShowImport(true)}>
                    <i className="bi bi-arrow-bar-down me-1"></i>{templates.button_import}</Button>
                    <Button size="sm" onClick={handleShowAddIAC}>
                    <i className="bi bi-plus-circle me-1"></i>{templates.button_create}</Button>
                    </>
    )
  }

    let content;
    const tableFields = [{ name: "#", sort: "id" }, { name: "Коментарий", sort: "comment" }, { name: "Дата", sort: "docDate" },
    { name: "Сотрудник", sort: "accountName" }, { name: "Подробнее", sort: "" }]
    const tableContent = iac.content.map((v, k) => {
      return (<>
        <tr key={k}>
          <td>{k + 1}</td>
          <td >{v.comment}</td>
          <td >{new Date(v.docDate).toLocaleString()}</td>
          <td >{v.accountName}</td>
          <td>
            <i className="bi bi-card-list" id={v.id} style={{
              cursor: "pointer"
            }} onClick={handleViewDetails}></i>
          </td>
        </tr></>)

    })

    if (showAddIac) {
      content = <AddInstrumentArrivalContainer onHide={handleHideAddIAC} />
    } else if(showImport){
      content = <><Import/></>;
    } else {

      if (selected === '') {
        content = <>
          <div className="d-flex border p-2 mb-3 shadow">
            <div className="mx-2">
              <DateFilter show={showDatefilter} onFilter={handleFilter} />
            </div>

            <div className="ms-auto">
              {showAddIac ? '' : buttonPanel()}
            </div>
          </div>
          <div className="shadow p-1 border">

            <BasicTable fields={tableFields} page={iac} onsort={(e) => fetchData(e)} >
              {tableContent}
            </BasicTable>

          </div>
        </>
      } else {
        content = <ViewInstrumentArrivalContainer iac={selected} onHide={() => {setSelected(''); setShowDatefilter(true)} }/>
      }

    }

    return (
      <>
        <div className='content-pane dynamic-content dynamic-content-md'>{content}</div>
      </>)
  
}

export default Arrival;

class AddInstrumentArrivalContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      instrumentByCode: '',
      showRequestCount: false,
      arrivalContainer: {
        comment: "",
        counterPartyText: "",
        instrumentArrival: []
      },
      showCatalog: false,
      errorMessage: ''
    }
  }

  handleChange = (event) => {
    let value = event.target.value;
    if (event.target.id === "comment") {
      this.setState((state) => state.arrivalContainer.comment = value)
    } else if (event.target.id === "counterPartyText") {
      this.setState((state) => state.arrivalContainer.counterPartyText = value)
    }
  }
  handleHideRequetCount = () => {

    this.setState({ showRequestCount: false })
  }

  handleDelete = (event) => {
    const index = parseInt(event.target.id);
    this.setState((state) => ({ arrivalContainer: state.arrivalContainer }));

  }
  handleCommitRequestCount = (count) => {

    const newItem = {
      instrument: {
        id: this.state.instrumentByCode.id
      },
      name: this.state.instrumentByCode.name,
      model: this.state.instrumentByCode.model,
      vendor: this.state.instrumentByCode.vendor,
      count: count
    }
    let isExist = false;
    this.state.arrivalContainer.instrumentArrival.forEach((v, k) => {
      if (v.instrument.id === this.state.instrumentByCode.id) {
        v.count = + count + + v.count;
        isExist = true;

      }
    });
    if (!isExist) {
      this.state.arrivalContainer.instrumentArrival.push(newItem);
    }
    this.handleHideRequetCount();
  }

  handleAddArrival = () => {
    if (this.state.arrivalContainer.instrumentArrival.length > 0) {
      PutHttp(this.state.arrivalContainer, _URL_INSTRUMENT_ARRIVAL).then(() => {
        this.props.onHide();

      }).catch(error => {
        this.setState({ errorMessage: error.data.reason })
      });

    }
  }

  handleCodeInput = (event) => {
    if (event.keyCode === 13) {
      PostHttp({
        code: event.target.value,
        mode: 1
      }, _URL_ISSUE + '/findcode').then((r) => {
        if (r.init === "ii") {
          this.setState({ instrumentByCode: r.instrument, showRequestCount: true })
        }
      }).catch(error => {
        this.setState({ errorMessage: error.data.reason })
      });
      event.target.value = "";
    }

  }
  handleFindWithId = (id) => {
    PostHttp({
      requestId: id,
      mode: 1
    }, _URL_ISSUE + '/findid').then((r) => {
      if (r.init === "ii") {
        this.setState({ instrumentByCode: r.instrument, showRequestCount: true })
      }
    }).catch(error => {
      this.setState({ errorMessage: error.data.reason })
    });

  }
  handleShowCatalog = () => {
    this.setState({ showCatalog: true })
  }
  handleHideCatalog = () => {
    this.setState({ showCatalog: false })
  }

  render() {
    const request = <RequestInputCount show={this.state.showRequestCount} onHide={this.handleHideRequetCount} string={"Наименование: " + this.state.instrumentByCode.name} string1={"Производитель: " + this.state.instrumentByCode.vendor} onCommit={this.handleCommitRequestCount} />

    let content;
    if (!this.state.showCatalog) {
      content = <> <div className="mt-3 p-2 border shadow"> <div>
        <Form.Group as={Col} >
          <Form.Label>Коментарий</Form.Label>
          <Form.Control id="comment" type="text" onChange={this.handleChange} />
        </Form.Group>
        <Form.Group as={Col} >
          <Form.Label>Контрагент</Form.Label>
          <Form.Control id="counterPartyText" type="text" onChange={this.handleChange} />
        </Form.Group>
      </div>
        <div className="d-flex align-items-end mb-2">
          <Form.Group >
            <Form.Label>Штрих-код</Form.Label>
            <Form.Control placeholder="ID Инструмента" id="counterPartyText" type="text" onKeyUp={this.handleCodeInput} />
          </Form.Group>
          <div className="ms-2">
            <Button onClick={this.handleShowCatalog}>Каталог</Button>
          </div>
        </div>
        <Table striped="striped" bordered="bordered" hover="hover" className="instrument-table" size="sm">
          <thead>
            <tr style={{
              textAlign: "center"
            }}>
              <th>#</th>
              <th>Название</th>
              <th>Модель</th>
              <th>Производитель</th>
              <th>Количество</th>
              <th>Удалить</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.arrivalContainer.instrumentArrival.map((v, k) => {
                return (<tr key={k}>
                  <td>{k + 1}</td>
                  <td>{v.name}</td>
                  <td>{v.model}</td>
                  <td>{v.vendor}</td>
                  <td>{v.count}</td>
                  <td>
                    <Button id={k} onClick={this.handleDelete}>X</Button>
                  </td>
                </tr>)
              })
            }
          </tbody>
        </Table>
        <div className="text-center">
          <Button className="me-1" variant="secondary" onClick={this.props.onHide}>{templates.button_close}</Button>
          <Button onClick={this.handleAddArrival}>{templates.button_doit}</Button>
        </div>
      </div>
      </>
    }

    return (<>
    
      {request}
      {content}
      <Catalog show={this.state.showCatalog} find={this.handleFindWithId} onHide={this.handleHideCatalog} instrumentArrival={this.state.arrivalContainer.instrumentArrival} />
      <ErrorDialog onCancel={() => this.setState({ errorMessage: '' })} title={"Ошибка"} show={this.state.errorMessage!== ''}>
        {this.state.errorMessage}
      </ErrorDialog>
    </>)
  }
}

class ViewInstrumentArrivalContainer extends React.Component {

  constructor(props) {
    super(props)

  }

  render() {

    let content;

    content = <> <div className="mt-3 border shadow p-2"> <div>
      <Form.Group as={Col} controlId="formBasicIdentificator">
        <Form.Label>Коментарий</Form.Label>
        <Form.Control id="comment" type="text" defaultValue={this.props.iac.comment} disabled="disabled" />
      </Form.Group>
      <Form.Group as={Col} controlId="formBasicIdentificator">
        <Form.Label>Контрагент</Form.Label>
        <Form.Control id="counterPartyText" type="text" defaultValue={this.props.iac.counterPartyText} disabled="disabled" />
      </Form.Group>

    </div>
      <div>
        <Form.Group as={Col}>
          <Form.Label>Дата</Form.Label>
          <Form.Control type="text" defaultValue={new Date(this.props.iac.docDate).toLocaleDateString()} disabled="disabled" style={{
            maxWidth: "200px"
          }} />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Сотрудник</Form.Label>
          <Form.Control type="text" defaultValue={this.props.iac.accountName} disabled="disabled" />
        </Form.Group>
      </div>
      <div ></div>
      <Table striped="striped" bordered="bordered" hover="hover" className="instrument-table mt-2" size="sm">
        <thead>
          <tr style={{
            textAlign: "center"
          }}>
            <th>#</th>
            <th>Название</th>
            <th>Модель</th>
            <th>Производитель</th>
            <th>Количество</th>

          </tr>
        </thead>
        <tbody>
          {
            this.props.iac.instrumentArrival.map((v, k) => {
              return (<tr key={k}>
                <td>{k + 1}</td>
                <td>{v.instrument.name}</td>
                <td>{v.instrument.model}</td>
                <td>{v.instrument.vendor}</td>
                <td>{v.count}</td>

              </tr>)
            })
          }
        </tbody>

      </Table>
      <div className="text-center">
        <Button variant="secondary" onClick={this.props.onHide}>Закрыть</Button>
      </div>
    </div>

    </>

    return (<> {content} </>)
  }
}
