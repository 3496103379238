import axios from "axios";
export const contex = "";
export let host="";
export let api_url ="/api";
export let register_post;

const _URL_SINGIN = '/signin';
export let _URL_INSTRUMENT = '/instrument';
export let _URL_INSTRUMENT_INHAND = '/instrument/inhand';
export let _URL_INSTRUMENT_ARRIVAL = '/instrumentarrival';
export let _URL_INSTRUMENT_CONTAINER = '/instcontainer';
export let _URL_INSTRUMENT_IMAGE = '/instrument/image';
export let _URL_CATEGORY = '/instrument/category';
export let _URL_INSTRUMENT_LIST = '/instrument/list';
export let _URL_IMAGE_REPOSITORY="/api/files";
export let _URL_FILE_RESOURCE = '/files';
export let _URL_SETAVATAR = "/files/default";
export let _URL_CUSTOMER = "/customer";
export let _URL_TARIFF = "/tariff";
export let _URL_EXPORT_INVENTORY = "/export/inventory";
export let _URL_EXPORT_INHAND = "/export/inhand";
export let _URL_EXPORT_WRITEOFF = "/export/inhand";
export let _URL_ACCOUNT_CUSTOMER = "/account/customer";
export let _URL_ACCOUNT = "/account";
export let _URL_DOCUMENT = "/document";
export let _URL_WRITEOFF = "/writeoff";
export let _URL_RECIPIENT = '/recipient';
export let _URL_REPORT = '/report';
export const _URL_REGISTRATION ="/registration";
export let _URL_ISSUE = '/issue/';
export let _URL_ISSUE_MODE = '/issue/mode';
export let _URL_BASKET = '/basket';
export let _URL_PERMISSION = '/permission';
export let _URL_IC_REQUEST = '/icrequest';
export let _URL_RECOVERY_PASSWORD = '/login/recovery';
export let _URL_LOGGING = '/logging';
export const _REALM = "skladsystem";

export const oidcConfig = {
    authority: "/realms/skladsystem",
    client_id: "login-app",
    client_secret: 'b4GRTaL55GXYGFWbwsUNJm65DAM3dhTk',
    scope: 'openid',
    response_type: 'token',
    // metadataUrl: `https://oauth2.skladsystem.ru/realms/${_REALM}/.well-known/openid-configuration`
};

export async function GetAuthentification() {
    let result;
    await axios.get(api_url + _URL_ACCOUNT, {
            headers:getAccessTokenNoRedirect(),
        }).then(response => { result= response.data  })
        
    return result;
}

export async function GetHttpKeycloak(endpoint){
   let result;
    await axios.get(`/realms/${_REALM}${endpoint}`,{headers:getAccessToken()})
    .then(resonse => result = resonse.data);
    return result;
}

export async function DeleteHttpKeycloak(endpoint){
    let result;
     await axios.delete(`/realms/${_REALM}${endpoint}`,{headers:getAccessToken()})
     .then(resonse => result = resonse.data);
     return result;
 }

export async function PostHttpKeycloak(endpoint,body){
    let result;
    await axios.post(`/realms/${_REALM}/${endpoint}`,body,{headers:getAccessToken()})
    .then(resonse => result = resonse.data);
    return result;
}

function getAccessTokenNoRedirect(){
    const string = sessionStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
        if(string !== null){
            let auth = JSON.parse(string);
            if(auth!==null){
                return {Authorization: auth.token_type+" "+auth.access_token};
            }
        }
    
}

function getAccessToken(){
    const string = sessionStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
        if(string !== null){
            let auth = JSON.parse(string);
            if(auth!==null){
                return {Authorization: auth.token_type+" "+auth.access_token};
            }
        }
    document.location.href="/signin";
}


export async function GetHttp(serviceUrl) {
    
    let result;
    await  axios.get(
            api_url + serviceUrl, {
            headers:getAccessToken()    
        }).then(response => { result= response.data  })
        .catch(err=>{
             throw err.response;
        })
      return result;
}
export async function GetHttpParams(serviceUrl, params) {
    let result;
      await axios.get(api_url + serviceUrl, {
            params: params,
            headers:getAccessToken(),
        }).then(response => { result= response.data  })
        .catch(err=>{throw err.response});
        
    return result;
}

export async function DownloadFile(target){
    return await fetch(api_url +target,{headers:getAccessToken()})
}

export async function Register(body) {
    let result;
    await axios.post(api_url + _URL_REGISTRATION, body)
            .then(e => result = e.data)
            .catch(err=>{
                 throw err.response;
            })
    return result;

}
export async function PostHttp(body, serviceUrl) {
    let result;
    await axios.post(api_url + serviceUrl, body,{headers:getAccessToken()})
            .then(e => result = e.data)
            .catch(err=>{throw err.response})
    return result;

}
export async function PutHttp(body, serviceUrl) {
    await axios.put(api_url + serviceUrl, body,{headers:getAccessToken()})
    .catch(err=>{throw err.response})
}

export async function RecoveryPassword(email) {
    return await axios.post(api_url + "/login/recovery", {email:email} )
        .then(response => { return response.data;
        })

}
export async function DeleteHttp(serviceUrl, param) {
    
    await axios.delete(api_url + serviceUrl, { params: param,headers:getAccessToken() })
            .catch(err=>{throw err.response})

}
export async function SendFile(file, type, id) {
    let metaData;
    let fd = new FormData();
    fd.append("files", file);
    await  axios.post(api_url + "/files/upload?type=" + type + "&id=" + id, fd,
        {headers:getAccessToken()})
          .then((response) => metaData = response.data)
    return metaData;
}

export default GetHttp;
